import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import './addTeacher.css';
import { validationRegexAlphaNumeric, validationRegexOnlyPincode, validationRegexMobileNo, validationRegexOnlyAlpha, validationRegexOnlyTextarea, emailRegex, decimalRegex, textCommaRegex, commaAlpha, validationRegexAmount } from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import TextInputField from '../reUsableForm/textInputField';
import DateInputField from '../../ReusableFields/DateFields/DateField';
import SearchableDropDownField from '../../ReusableFields/DropDown/SearchableDropDownField';
import { putEmployeeData } from './teacherSlicers/allRequests';
import EmailInputField from '../../ReusableFields/EmailFields/EmailField';
import { changeLoadingStateToIdleEditEmployee } from './teacherSlicers/employeeViewSlicer';
import useCitiesState from './customHooks/useCitiesState';
import ReadOnlyTextField from '../../ReusableFields/TextFields/ReadOnlyTextField';
import { calculateAge, getOrPutStoreProcess, getOrPutViewProcess } from './teacherSlicers/newDataFilterFunctions';
import { useNavigate } from 'react-router-dom';
import { API_PATH } from '../../Api/api';
import { token } from '../../Api/token';
import DatePickerPastAndFuture from '../../ReusableFields/DateFields/DatePicketPastAndFuture';

const EmployeeViewEdit = () => {
  const defaultData = {
    dateOfJoining: new Date(),
    name: "",
    code: "",
    gender: "",
    profilePictureUrl: '',
    dateOfBirth: '',
    age: '',
    email: "",
    documentUrls: [],
    primaryContactNumber: '',
    secondaryContactNumber: '',
    qualificationId: "",
    designation: '',
    religion: '',
    languagesKnown: '',
    salary: '',
    remark: '',
    workExperience: '',
    previousEmployment: '',
    referenceContactName: '',
    referenceContactNumber: '',
    previousEmploymentJoiningDate: new Date(),
    previousEmploymentLeavingDate: new Date(),
    previousEmploymentLastDrawnSalary: '',
    Paddress: '',
    Parea: '',
    Pcity: '',
    Pstate: '',
    Ppin: '',
    Caddress: "",
    Carea: '',
    Ccity: '',
    Cstate: '',
    Cpin: '',

  }
  const getEmployeeList = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeViewList.data);
  const getAreas = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeAreas.data);
  const getQuals = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeQualifications.data);
  const { editId } = useParams();
  const findIdObj = () => {
    return getEmployeeList?.find((each) => +each.id === +editId);
  };
  const firstData = findIdObj();
  const [qualificationsOptions, statesOptions, citiesOptions, designationOptions, areasOptions] = useCitiesState();
  const idProcess = getOrPutStoreProcess(firstData);
  const editingObject = getOrPutViewProcess(idProcess, getAreas, getQuals);
  const age = calculateAge(editingObject?.dateOfBirth);
  const [formData, setFormData] = useState({ ...editingObject, age: age });

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const putEmployeeDataObject = useSelector((state) => state.employeeSlicer.teachers.putEmployeeData);
  const backendAreas = useSelector((state) => state.employeeSlicer.teachers.getEmployeeAreas.data);
  const [dropdownIds, setDropDownIds] = useState({})
  const [imageUploadError, setImageUploadError] = useState(false);
  const [docUploadError, setDocUploadError] = useState(false);
  const [imageErrorMsg, setImageErrorMsg] = useState('');
  const [docErrorMsg, setDocErrorMsg] = useState('');

  const findDropdownId = (arr, value) => {
    const obj = arr.find((each) => each.value === value);
    return obj?.id;
  }
  
  useEffect(() => {
    if (citiesOptions && areasOptions && statesOptions && qualificationsOptions && editingObject) {

      const qual = findDropdownId(qualificationsOptions, editingObject.qualificationId);
      const CstateValue = findDropdownId(statesOptions, editingObject.Cstate);
      const CcityValue = findDropdownId(citiesOptions, editingObject.Ccity);
      const PstateValue = findDropdownId(statesOptions, editingObject.Pstate);
      const PcityValue = findDropdownId(citiesOptions, editingObject.Pcity);
      const PareaValue = findDropdownId(areasOptions, editingObject.Parea);
      const CareaValue = findDropdownId(areasOptions, editingObject.Carea);
      setDropDownIds({
        ...dropdownIds, qualificationId: qual, Cstate: CstateValue, Ccity: CcityValue,
        Pstate: PstateValue, Pcity: PcityValue, Parea: PareaValue, Carea: CareaValue
      })
    }
  }, [])

  const storeResponse = () => {
    const loading = putEmployeeDataObject?.loading;
    if (loading === 'fulfilled') {
      setSuccessMsg('Form Submitted SuccessFully! ')
      setShowSuccessMsg(true);
    } else if (loading === 'rejected' || imageUploadError || docUploadError) {
      setShowErrorMsg(true);
      setErrorMsg(putEmployeeDataObject?.error)
    }
  }
  useEffect(() => {
    storeResponse()
  }, [putEmployeeDataObject?.loading, imageUploadError, docUploadError])


  // const handleClear = () => {
  //   setValidated(false);;
  //   setFormData(defaultData)
  // }


  const displayErrorMsg = () => {
    if (showErrorMsg && imageUploadError) {
      return 'image is required!'
    } else if (showErrorMsg && docUploadError) {
      return 'document is required!'
    } else if (showErrorMsg) {
      return Array.isArray(putEmployeeDataObject?.error) ?
        putEmployeeDataObject?.error?.map(e => { return <li>{e.message}</li> }) :
        'image or document is not uploaded!'
      //putEmployeeDataObject?.error
    }
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    const imageData = new FormData();
    imageData.append('file', file);

    try {
      const tokenId = token();
      const response = await fetch(API_PATH + '/uploadFile', {
        method: 'POST',
        body: imageData,
        headers: {
          'Authorization': `Bearer ${tokenId}`
        }
      });

      const data = await response.json();
      if (!response.ok) {
        setImageUploadError(true);
        setImageErrorMsg(data.error)
        return data.error
      } else {
        setFormData({ ...formData, profilePictureUrl: String(data.fileId) });
        setImageUploadError(false);
      }

      //return data.fileId;
    } catch (err) {
      setImageUploadError(true);
      setImageErrorMsg(err);
    }
  }


  const handleDocumentChange = async (e) => {
    const files = e.target.files;
    const docData = new FormData();
    const arr = [];
    for (let i = 0; i < files.length; i++) {
      arr.push(files[i]);
      docData.append('file', files[i])
    }
    const tokenId = token();
    try {
      const response = await fetch(API_PATH + '/uploadFiles', {
        method: 'POST',
        body: docData,
        headers: {
          'Authorization': `Bearer ${tokenId}`
        }
      });
      const data = await response.json();
      if (!response.ok) {
        setDocUploadError(true);
        setDocErrorMsg(data.error);
        setFormData({ ...formData, documentUrls: [] })
      } else if (response.ok) {
        const fileIds = data?.files?.map((e) => { return String(e.fileId) });
        setFormData({ ...formData, documentUrls: fileIds });
        setDocUploadError(false);
      }
    } catch (err) {
      setDocUploadError(true);
      setDocErrorMsg(err);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    if (!formData.profilePictureUrl || formData.documentUrls.length < 1) {
      setShowErrorMsg(true);
      return
    }
    setDocUploadError(false);
    setImageUploadError(false);
    setShowErrorMsg(false)

    const { Caddress, Cpin, Paddress, Ppin } = formData;
    const permanentAddress = {
      address: Paddress,
      stateId: dropdownIds?.Pstate,
      cityId: dropdownIds?.Pcity,
      pincode: Ppin,
      areaId: dropdownIds?.Parea,
      type: 'permanent'
    }
    const currentAddress = {
      address: Caddress,
      stateId: dropdownIds?.Cstate,
      cityId: dropdownIds?.Ccity,
      pincode: Cpin,
      areaId: dropdownIds?.Carea,
      type: "current"
    }
    const id = editId;
    const clone = (({ id, age, srNo, Parea, Carea, Paddress, Pcity, Pstate, Ppin, Caddress, Ccity, Cpin, Cstate, ...o }) => o)(formData) // remove b and c
    const requiredData = {
      ...clone,
      primaryContactNumber: +clone.primaryContactNumber,
      secondaryContactNumber: +clone.secondaryContactNumber,
      referenceContactNumber: +clone.referenceContactNumber,
      workExperience: +clone.workExperience,
      permanentAddress, currentAddress, qualificationId: dropdownIds.qualificationId,
    }
    dispatch(putEmployeeData({ payload: requiredData, id }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "dateOfBirth") {
      const age = calculateAge(value);
      setFormData({ ...formData, [name]: value, age: age })
    } else {
      setFormData({ ...formData, [name]: value })

    }
  }

  return (
    <div style={{ height: "100vh" }}>
      <h4 className="content-header ">Edit Employee</h4>
      <Row>
        <Col>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <DatePickerPastAndFuture
                  label="DOB*"
                  value={formData?.dateOfJoining || null}
                  onChange={(name, date, selectedDate) => {
                    setFormData({ ...formData, [name]: date, })
                  }}
                  required
                  validated={validated}
                  name="dateOfJoining"
                  placeholder="Joining Date*"
                  dateFormat="dd-MM-yyyy"
                  isValidDate={(date) => true}
                  feedbackMessage="Please Joining Date."
                />

              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                <TextInputField
                  name='name'
                  placeholder='Enter your Name'
                  textType='Alphabet'
                  validated={validated}
                  required='true'
                  maxLength={25}
                  feedbackMessage='Name is Required!'
                  validationErrorMessage='Give valid Name!'
                  value={formData?.name}
                  validationRegex={validationRegexAlphaNumeric}
                  onChange={handleChange}
                />

              </Col>

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="code"
                  placeholder="Employee Code"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  maxLength={25}
                  required={true}
                  value={formData?.code}
                  onChange={handleChange}
                  feedbackMessage="Please enter employee code."
                  validationRegex={validationRegexAlphaNumeric}
                  validationErrorMessage="Please enter a valid employee code."
                />
              </Col>

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <Form.Group>
                  <Form.Label className='lableName'>Gender</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Male"
                      type="radio"
                      name="gender"
                      value="male"
                      className='malebutton'
                      checked={formData?.gender === "male"}
                      onChange={(event) => setFormData({ ...formData, gender: event.target.value })}
                    />
                    <Form.Check
                      inline
                      label="Female"
                      type="radio"
                      name="gender"
                      value="female"
                      className='malebutton'
                      checked={formData?.gender === "female"}
                      onChange={(event) => setFormData({ ...formData, gender: event.target.value })}
                    />

                  </div>

                </Form.Group>

              </Col>

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                <Form.Group>
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      width: '248px',
                      height: '42px',
                      padding: '16px',
                      alignItems: 'center',
                      borderRadius: '4px',
                      border: '0.5px solid #A7A7A7',
                      background: '#FFF',
                    }}
                  >
                    <div className='layout1'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='25'
                        height='24'
                        viewBox='0 0 25 24'
                        fill='none'
                      >
                        <path
                          d='M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z'
                          fill='#636363'
                        />
                      </svg>
                      <div className='title1'>Upload Image</div>
                    </div>
                    <input
                      type="file"
                      name='profilePictureUrl'
                      accept='.jpg, .jpeg'
                      // multiple
                      onChange={handleImageChange}
                      style={{
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                        position: 'absolute',
                        zIndex: 1,
                        top: 0,
                        left: 0,
                      }}
                    />

                  </div>
                  <div>
                    {
                      formData?.profilePictureUrl && <p>(1) Profile image selected</p>
                    }
                  </div>
                </Form.Group>

              </Col>
            </Row>

            <Row>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                <DatePickerPastAndFuture
                  label="DOB*"
                  value={formData?.dateOfBirth || null}
                  onChange={(name, date, selectedDate) => {
                    const age = calculateAge(date);
                    setFormData({ ...formData, [name]: date, age: age })
                  }}
                  required
                  validated={validated}
                  name="dateOfBirth"
                  placeholder="Date of Birth*"
                  dateFormat="dd-MM-yyyy"
                  isValidDate={(date) => true}
                  feedbackMessage="Please enter Date of Birth."
                  maxDate={new Date()}
                />

              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <ReadOnlyTextField
                  name="age"
                  placeholder="age"
                  value={formData?.age}
                  required={true}
                  feedbackMessage='Date of birth is Required!'
                  validated={validated}
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <EmailInputField
                  name="email"
                  placeholder="Email *"
                  className="fcontrol"
                  required={true}
                  validated={validated}
                  value={formData?.email}
                  onChange={handleChange}
                  feedbackMessage="Please enter Email ."
                  validationRegex={emailRegex}
                  validationErrorMessage="Please enter valid Email ."
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <Form.Group >
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      width: '248px',
                      height: '42px',
                      padding: '16px',
                      alignItems: 'center',
                      borderRadius: '4px',
                      border: '0.5px solid #A7A7A7',
                      background: '#FFF',
                    }}
                  >
                    <div className='layout1'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='25'
                        height='24'
                        viewBox='0 0 25 24'
                        fill='none'
                      >
                        <path
                          d='M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z'
                          fill='#636363'
                        />
                      </svg>
                      <div className='title1'>Upload Documents</div>
                    </div>
                    <input
                      type="file"
                      accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                      multiple
                      name='documentUrls'
                      onChange={handleDocumentChange}
                      style={{
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                        position: 'absolute',
                        zIndex: 1,
                        top: 0,
                        left: 0,
                      }}
                    />

                  </div>

                  {
                    formData?.documentUrls?.length > 0 && <p>{formData?.documentUrls?.length} documents are selected</p>
                  }
                </Form.Group>

              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="primaryContactNumber"
                  placeholder="Mobile Number *"
                  className="fcontrol"
                  type='text'
                  required={true}
                  validated={validated}
                  value={formData?.primaryContactNumber}
                  onChange={handleChange}
                  feedbackMessage="Mobile number is Required!"
                  validationRegex={validationRegexMobileNo}
                  validationErrorMessage="Number should contain 10 digits!"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="secondaryContactNumber"
                  placeholder="Secondary Mobile Number"
                  className="fcontrol"
                  type='text'
                  required={false}
                  validated={validated}
                  value={formData?.secondaryContactNumber}
                  onChange={handleChange}
                  feedbackMessage="Secondary Mobile number is Required!"
                  validationRegex={validationRegexMobileNo}
                  validationErrorMessage="Number should contain 10 digits!"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <SearchableDropDownField
                  name='qualificationId'
                  value={formData?.qualificationId ? { label: formData?.qualificationId } : null}
                  placeholder='Select Qualification *'
                  options={qualificationsOptions}
                  onChange={(selectedOption) => {
                    setDropDownIds({ ...formData, qualificationId: selectedOption.id })
                    setFormData({ ...formData, qualificationId: selectedOption.value })
                  }}
                  required={true}
                  feedbackMessage='Select Qualification'
                  validated={validated}
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                <SearchableDropDownField
                  name='designation'
                  placeholder='Select Designation *'
                  value={formData?.designation ? { label: formData?.designation } : null}
                  options={designationOptions}
                  onChange={(selectedOption) => {
                    setFormData({ ...formData, designation: selectedOption.value })
                  }}
                  required={true}
                  feedbackMessage='Select Designation'
                  validated={validated}
                />
              </Col>
            </Row>
            <Row>

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="religion"
                  placeholder="Religion *"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.religion}
                  onChange={handleChange}
                  feedbackMessage="Religion is Required!"
                  validationRegex={validationRegexOnlyAlpha}
                  validationErrorMessage="Enter valid Religion"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="languagesKnown"
                  placeholder="Languages Known *"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.languagesKnown}
                  onChange={handleChange}
                  feedbackMessage="Known Languages are Required!"
                  validationRegex={commaAlpha}
                  validationErrorMessage="Enter valid Known Languages"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="salary"
                  placeholder="Present Organisation Salary *"
                  className="fcontrol"
                  validated={validated}
                  required={true}
                  value={formData?.salary}
                  onChange={handleChange}
                  feedbackMessage="Present Organisation salary is Required!"
                  validationRegex={validationRegexAmount}
                  validationErrorMessage="salary should consist digits and commas only"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="remark"
                  placeholder="Remarks *"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.remark}
                  onChange={handleChange}
                  feedbackMessage="Remarks are Required!"
                  validationRegex={validationRegexOnlyTextarea}
                  validationErrorMessage="Enter valid textarea"
                />
              </Col>
            </Row>
            <Row>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="workExperience"
                  placeholder="Work Experience *"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.workExperience}
                  onChange={handleChange}
                  feedbackMessage="Work experience is Required!"
                  validationRegex={decimalRegex}
                  validationErrorMessage="Work experience should be in decimals"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="previousEmployment"
                  placeholder="Previous Organisation Name"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={false}
                  value={formData?.previousEmployment}
                  onChange={handleChange}
                  feedbackMessage="Previous Employment Name is Required!"
                  validationRegex={validationRegexOnlyAlpha}
                  validationErrorMessage="Previous Employment Name should be in decimals"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="referenceContactName"
                  placeholder="Reference Name"
                  className="fcontrol"
                  type='text'
                  // validated={validated}
                  required={false}
                  value={formData?.referenceContactName}
                  onChange={handleChange}
                  feedbackMessage="Reference Name is Required!"
                  validationRegex={validationRegexOnlyAlpha}
                  validationErrorMessage="Reference Name should be in alphabets"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="referenceContactNumber"
                  placeholder="Reference Number"
                  className="fcontrol"
                  type='text'
                  // validated={validated}
                  required={false}
                  value={formData?.referenceContactNumber}
                  onChange={handleChange}
                  feedbackMessage="Reference Number is Required!"
                  validationRegex={validationRegexMobileNo}
                  validationErrorMessage="Reference Number should be 0-10 digits"
                />
              </Col>
            </Row>
            <Row>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <DatePickerPastAndFuture
                  label="DOB*"
                  value={formData?.previousEmploymentJoiningDate || null}
                  onChange={(name, date, selectedDate) => {
                    setFormData({ ...formData, [name]: date })
                  }}
                  required
                  validated={false}
                  name="previousEmploymentJoiningDate"
                  placeholder="Previous Org Joining Date"
                  dateFormat="dd-MM-yyyy"
                  isValidDate={(date) => true}
                  feedbackMessage="Previous Organization Joining Date is required"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3}>
              <DatePickerPastAndFuture
                  label="DOB*"
                  value={formData?.previousEmploymentLeavingDate || null}
                  onChange={(name, date, selectedDate) => {
                    setFormData({ ...formData, [name]: date })
                  }}
                  required
                  validated={false}
                  name="previousEmploymentLeavingDate"
                  placeholder="Previous Org Leaving Date"
                  dateFormat="dd-MM-yyyy"
                  isValidDate={(date) => true}
                  feedbackMessage="Previous Organization Leaving Date is required"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="previousEmploymentLastDrawnSalary"
                  placeholder="Previous Organisation Salary"
                  className="fcontrol"
                  // validated={validated}
                  required={false}
                  // label='    '
                  value={formData?.previousEmploymentLastDrawnSalary}
                  onChange={handleChange}
                  feedbackMessage="previous Organisation salary is Required!"
                  validationRegex={validationRegexAmount}
                  validationErrorMessage="salary should consist digits and commas only"
                />
              </Col>

            </Row>
            <Row>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="Paddress"
                  placeholder="Permanent Address"
                  className="fcontrol"
                  type='text'
                  // validated={validated}
                  required={false}
                  value={formData?.Paddress}
                  onChange={handleChange}
                  feedbackMessage="Permanent Address is Required!"
                  validationRegex={validationRegexAlphaNumeric}
                  validationErrorMessage="Enter valid Permanent address"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <SearchableDropDownField
                  name='Parea'
                  placeholder='Permanent Area'
                  value={formData?.Parea ? { label: formData?.Parea } : null}
                  isSearchable
                  options={areasOptions}
                  onChange={(selectedOption) => {
                    const requiredObj = backendAreas.find((each) => each.id === selectedOption.id);
                    const state = requiredObj.city.state.name;
                    const city = requiredObj.city.name;
                    setFormData({ ...formData, Parea: selectedOption.value, Pcity: city, Pstate: state })
                    setDropDownIds({ ...dropdownIds, Parea: selectedOption?.id, Pcity: requiredObj?.city?.id, Pstate: requiredObj?.city?.stateId })
                  }}
                  required={false}
                  feedbackMessage='Permanent Area is Required!'
                  // validated={validated}
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <ReadOnlyTextField
                  name='Pcity'
                  placeholder='Permanent city'
                  value={formData?.Pcity}
                  options={citiesOptions}
                  onChange={(selectedOption) => {
                    setDropDownIds({ ...dropdownIds, Pcity: selectedOption.id })
                    setFormData({ ...formData, Pcity: selectedOption.value })
                  }}
                  required={false}
                  feedbackMessage='Permanent city is Required!'
                  // validated={validated}
                />
              </Col>

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <ReadOnlyTextField
                  name='Pstate'
                  placeholder='Permanent state'
                  value={formData?.Pstate}
                  options={statesOptions}
                  onChange={(selectedOption) => {
                    setDropDownIds({ ...dropdownIds, Pstate: selectedOption.id })
                    setFormData({ ...formData, Pstate: selectedOption.value })
                  }}
                  required={false}
                  feedbackMessage='Permanent state is Required!'
                  // validated={validated}
                />

              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="Ppin"
                  placeholder="Permanent Pincode"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={false}
                  value={formData?.Ppin}
                  onChange={handleChange}
                  feedbackMessage="Permanent Pincode is Required!"
                  validationRegex={validationRegexOnlyPincode}
                  validationErrorMessage="Enter valid Pincode"
                />
              </Col>

            </Row>

            <Row>
            <Col sm={12} xs={12} md={6} lg={3} className="rowCss">

                <TextInputField
                  name="Caddress"
                  placeholder="Current Address*"
                  className="fcontrol"
                  type='text'
                  validated={validated}
                  required={true}
                  value={formData?.Caddress}
                  onChange={handleChange}
                  feedbackMessage="Current Address is Required!"
                  validationRegex={validationRegexAlphaNumeric}
                  validationErrorMessage="Enter valid Current address"
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <SearchableDropDownField
                  name='Carea'
                  placeholder='Current Area *'
                  value={formData?.Carea ? { label: formData?.Carea } : null}
                  options={areasOptions}
                  onChange={(selectedOption) => {
                    setDropDownIds({ ...dropdownIds, Carea: selectedOption.id })
                    const requiredObj = backendAreas.find((each) => each.id === selectedOption.id);
                    const state = requiredObj.city.state.name;
                    const city = requiredObj.city.name;
                    setFormData({ ...formData, Carea: selectedOption.value, Ccity: city, Cstate: state })
                    setDropDownIds({ ...dropdownIds, Carea: selectedOption?.id, Ccity: requiredObj?.city?.id, Cstate: requiredObj?.city?.stateId })
                  }}
                  required={true}
                  feedbackMessage='Current Area is Required!'
                  validated={validated}
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <ReadOnlyTextField
                  name='Ccity'
                  placeholder='Current City'
                  value={formData?.Ccity}
                  options={citiesOptions}
                  onChange={(selectedOption) => {
                    setDropDownIds({ ...dropdownIds, Ccity: selectedOption.id })
                    setFormData({ ...formData, Ccity: selectedOption.value })
                  }}
                  required={true}
                  feedbackMessage='Current City is Required!'
                  validated={validated}
                />
              </Col>

              <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                <ReadOnlyTextField
                  name='Cstate'
                  placeholder='Current State'
                  value={formData?.Cstate}
                  options={statesOptions}
                  onChange={(selectedOption) => {
                    setDropDownIds({ ...dropdownIds, Cstate: selectedOption.id })
                    setFormData({ ...formData, Cstate: selectedOption?.value })
                  }}
                  required={true}
                  feedbackMessage='Current City is Required!'
                  validated={validated}
                />
              </Col>
              <Col sm={12} xs={12} md={6} lg={2} className="rowCss">

                <TextInputField
                  name="Cpin"
                  placeholder="Current Pincode *"
                  className="fcontrol"
                  type='text'
                  required={true}
                  value={formData?.Cpin}
                  validated={validated}
                  onChange={handleChange}
                  feedbackMessage="Current Pincode is Required!"
                  validationRegex={validationRegexOnlyPincode}
                  validationErrorMessage="Enter valid Pincode"
                />
              </Col>
            </Row>
            {
              putEmployeeDataObject?.loading === 'fulfilled' &&
              showSuccessMsg &&
              <SuccessMessagePopup show={showSuccessMsg} onClose={() => { dispatch(changeLoadingStateToIdleEditEmployee()); navigate(-1); setShowSuccessMsg(false) }} successMessage="Form Edited successfully" />
            }
            {
              // putEmployeeDataObject?.loading === 'rejected' &&
              // showErrorMsg &&
              <ErrorMessagePopup show={showErrorMsg} onClose={() => { dispatch(changeLoadingStateToIdleEditEmployee()); setImageUploadError(false); setShowErrorMsg(false); setDocUploadError(false) }}
                errorMessage={imageUploadError ? imageErrorMsg : docUploadError ? docErrorMsg : displayErrorMsg()} />
            }
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1%",
                marginTop: "5%"
              }}
            >
              {/* <button
                className="clear-button-figma-div"
                type="button"
                onClick={handleClear}
              >
                Clear
              </button> */}
              <button className="save-button-figma-div" type="submit">
                Submit
              </button>
            </div>
          </Form>

        </Col>
      </Row>
    </div>
  );
};

export default EmployeeViewEdit;


