import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from "../../../Api/api";
import { token, franchiseeSchoolId } from "../../../Api/token";

const slice = createSlice(
  {
    name: 'viewEventStatus',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchViewEventStatusData: (state, action) => {
        state.isLoading = action.payload;
        state.hasError = false;
      },
      fetchViewEventStatusSuccess: (state, action) => {
        state.isLoading = true;
        state.data = state.data ? [...state.data, ...action.payload] : action.payload;
      },
      fetchViewEventStatusFailure: (state, action) => {
        state.isLoading = false;
        state.hasError = action.payload;
      }
    },
  },

);

export const { fetchViewEventStatusData, fetchViewEventStatusSuccess, fetchViewEventStatusFailure } = slice.actions;

export const fetchViewEventStatusDataOnce = () => async (dispatch, getState) => {

  const tokenId = token()
  const { data, isLoading } = getState().viewEventStatus;
  if (data === null && !isLoading) {
    dispatch(fetchViewEventStatusData());

    // Retrieve franchiseeSchoolId from sessionStorage
    //  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");

    try {
      const response = await fetch(API_PATH + `/franchisee-school/${+franchiseeSchoolId()}/event-status`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${tokenId}`
        }
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }
      const data1 = await response.json();
      dispatch(fetchViewEventStatusSuccess(data1));
    } catch (error) {
      dispatch(fetchViewEventStatusFailure());
    }
  }

}

export default slice.reducer;
