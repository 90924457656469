import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import "./DatePicker.css";
import formatDateToYMD from "./dateFormartYMD";

const DatePickerPastAndFuture = ({
    name,
    label,
    value,
    onChange,
    placeholder,
    dateFormat,
    feedbackMessage,
    isValidDate = () => true,
    validated,
    required,
    ...props
}) => {

    const handleChange = (date) => {
        if (!required || date) {
            onChange(name, formatDateToYMD(date)); // Pass valid date
        } else {
            onChange(name, null); // Trigger invalid state
        }
    };

    return (
        <Form.Group controlId={name}>
            <DatePicker
                selected={value ? new Date(value) : null}
                onChange={handleChange}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText={placeholder}
                dateFormat={dateFormat}
                className={`DatePicker_field ${validated && !value ? 'is-invalid' : ''}`}
                isValidDate={isValidDate}
                {...props}
            />
            {validated && !value && (
                <div className="invalid-feedback d-block">{feedbackMessage}</div>
            )}
        </Form.Group>
    );
};

export default DatePickerPastAndFuture;
