/*
    authors: rajeev
    date: 17-10-2023
    update:18-10-2023 
    version: 1.0
*/

import React, { useState, useEffect } from "react";
// import Table from "../reUsableForm/table/table";
import Table from "../parent/parentViewTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchParentDataOnce } from "./viewParentSlicer";

import { fetchAreaData } from "../EnquiryDetails/slice/areaSlicer";
import { fetchParentRelationDataOnce } from "./slice/viewParentRelationSlice";

export const ViewParent = () => {
  const columns = [
    { field: "srNo", header: "Sl.No" },
    // { field: "id", header: "ID" },
    { field: "name", header: "Name" },
    { field: "relations", header: "Family Members" },
    { field: "dateOfBirth", header: "Date Of Birth" },
    { field: "relation", header: "relation" },
    { field: "gender", header: "Gender" },
    { field: "email", header: "Email" },
    { field: "primaryContactNumber", header: "Primary Number" },
    { field: "secondaryContactNumber", header: "Secondary Number" },
    { field: "profilePictureUrl", header: "Profile Image" },
    { field: "documentUrls", header: "Documents" },
    { field: "address", header: "Address" },
    { field: "area", header: "Area" },
    { field: "city", header: "City" },
    { field: "state", header: "State" },
    { field: "pincode", header: "Pincode" },
    { field: "edit", header: "Edit" },

    // { field: "currentAddress", header: "Images" },
  ];


  const areasIds = useSelector((state) => state.areaDetails.data) || [];
  const postData = useSelector((state) => state.addParent.data) || [];
  const apiData = useSelector((state) => state.viewParent.data) || [];
  const getData = useSelector((state) => state.viewParentRelation.data) || [];
  const putData = useSelector((state) => state.updateParent.data) || [];

  const filteredData = getData.map(item => {
    if (item.father) {
      return {
        ...item.father,
        relations: [item.mother, item.guardian].filter(Boolean)
      };
    } else if (item.mother) {
      return {
        ...item.mother,
        relations: [item.father, item.guardian].filter(Boolean)
      };
    } else {
      return {
        ...item.guardian,
        relations: [item.father, item.mother].filter(Boolean)
      };
    }
  });


  useEffect(() => {
    if (areasIds.length === 0) {
      dispatch(fetchAreaData());
    }
  }, [areasIds]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchParentDataOnce());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchParentRelationDataOnce());
  }, [dispatch]);

  const putAreaID = putData?.guardian?.currentAddress?.areaId;
  const putAreaObject = areasIds && areasIds.find((item) => item.id === putAreaID);

  let putDataArray = [];
  let putCurrentAddress = null;
  let putPermanentAddress = null;


  if (putData !== null && putData?.guardian && typeof putData?.guardian === 'object') {
    putCurrentAddress = putData.guardian.currentAddress;
    putPermanentAddress = putData.guardian.permanentAddress;
    // eslint-disable-next-line no-undef
    const item = [putData?.guardian];
    // const itemArray = Array.isArray(item) ? item : [item];
    item.forEach((guardian) => {

      if (guardian) {
        const putAreaID = guardian?.currentAddress?.areaId;
        const putAreaObject = areasIds.find((item) => item.id === putAreaID);

        putDataArray.push({
          id: guardian?.id,
          name: guardian?.name,
          dateOfBirth: guardian?.dateOfBirth,
          email: guardian?.email,
          gender: guardian?.gender,
          primaryContactNumber: guardian?.primaryContactNumber,
          secondaryContactNumber: guardian?.secondaryContactNumber,
          relation: guardian?.relation,
          occupation: guardian?.occupation,
          remark: guardian?.remark,
          permanentAddressId: guardian?.permanentAddressId,
          currentAddressId: guardian?.currentAddressId,
          profilePictureUrl: guardian?.profilePictureUrl,
          documentUrls: guardian?.documentUrls,
          isActive: guardian.isActive,
          createdBy: guardian.createdBy,
          updatedBy: guardian.updatedBy,
          createdAt: guardian.createdAt,
          updatedAt: guardian.updatedAt,
          permanentAddress: {
            id: guardian?.id,
            address: putPermanentAddress?.address,
            areaId: putPermanentAddress?.areaId,
            cityId: putPermanentAddress?.cityId,
            stateId: putPermanentAddress?.stateId,
            pincode: putPermanentAddress?.pincode,
            type: putPermanentAddress?.type,
            createdBy: putPermanentAddress?.createdBy,
            updatedBy: putPermanentAddress?.updatedBy,
            createdAt: putPermanentAddress?.createdAt,
            updatedAt: putPermanentAddress?.updatedAt,
            area: {
              id: putPermanentAddress?.areaId,
              cityId: putPermanentAddress?.cityId,
              name: putAreaObject?.name,
            },
            city: {
              id: putPermanentAddress?.cityId,
              stateId: putPermanentAddress?.stateId,
              name: putAreaObject?.city?.name,
            },
            state: {
              id: putPermanentAddress?.stateId,
              name: putAreaObject?.city?.state?.name,
            },
          },
          currentAddress: {
            id: guardian.id,
            address: putCurrentAddress?.address,
            areaId: putCurrentAddress?.areaId,
            cityId: putCurrentAddress?.cityId,
            stateId: putCurrentAddress?.stateId,
            pincode: putCurrentAddress?.pincode,
            type: putCurrentAddress?.type,
            createdBy: putCurrentAddress?.createdBy,
            updatedBy: putCurrentAddress?.updatedBy,
            createdAt: putCurrentAddress?.createdAt,
            updatedAt: putCurrentAddress?.updatedAt,
            area: {
              id: putCurrentAddress?.areaId,
              cityId: putCurrentAddress?.cityId,
              name: putAreaObject?.name,
            },
            city: {
              id: putCurrentAddress?.cityId,
              stateId: putCurrentAddress?.stateId,
              name: putAreaObject?.city?.name,
            },
            state: {
              id: putCurrentAddress?.stateId,
              name: putAreaObject?.city?.state?.name,
            },
          },
        });
      }
    });
  }


  const areaID = postData[0]?.currentAddress_?.areaId;
  const areaObject = areasIds && areasIds.find((item) => item.id === areaID);

  let postDataArray = [];
  let currentAddress = null;
  let permanentAddress = null;

  if (postData.length > 0 && postData[0].guardiansData) {
    currentAddress = postData[0].currentAddress_;
    permanentAddress = postData[0].permanentAddress_;

    postData[0].guardiansData.forEach((guardian) => {
      if (guardian.profiles) {
        const areaID = guardian.profiles.currentAddressId?.areaId;
        const areaObject = areasIds.find((item) => item.id === areaID);

        postDataArray.push({
          id: guardian.profiles.id,
          name: guardian.profiles.name,
          dateOfBirth: guardian.profiles.dateOfBirth,
          email: guardian.profiles.email,
          gender: guardian.profiles.gender,
          primaryContactNumber: guardian.profiles.primaryContactNumber,
          secondaryContactNumber: guardian.profiles.secondaryContactNumber,
          relation: guardian.profiles.relation,
          occupation: guardian.profiles.occupation,
          remark: guardian.profiles.remark,
          permanentAddressId: guardian.profiles.permanentAddressId,
          currentAddressId: guardian.profiles.currentAddressId,
          profilePictureUrl: guardian.profiles.profilePictureUrl,
          documentUrls: guardian.profiles.documentUrls,
          isActive: guardian.profiles.isActive,
          createdBy: guardian.profiles.createdBy,
          updatedBy: guardian.profiles.updatedBy,
          createdAt: guardian.profiles.createdAt,
          updatedAt: guardian.profiles.updatedAt,
          permanentAddress: {
            id: guardian.profiles.permanentAddressId,
            address: permanentAddress?.address,
            areaId: permanentAddress?.areaId,
            cityId: permanentAddress?.cityId,
            stateId: permanentAddress?.stateId,
            pincode: permanentAddress?.pincode,
            type: permanentAddress?.type,
            createdBy: permanentAddress?.createdBy,
            updatedBy: permanentAddress?.updatedBy,
            createdAt: permanentAddress?.createdAt,
            updatedAt: permanentAddress?.updatedAt,
            area: {
              id: permanentAddress?.areaId,
              cityId: permanentAddress?.cityId,
              name: areaObject?.name,
            },
            city: {
              id: permanentAddress?.cityId,
              stateId: permanentAddress?.stateId,
              name: areaObject?.city?.name,
            },
            state: {
              id: permanentAddress?.stateId,
              name: areaObject?.city?.state?.name,
            },
          },
          currentAddress: {
            id: guardian.profiles.currentAddressId,
            address: currentAddress?.address,
            areaId: currentAddress?.areaId,
            cityId: currentAddress?.cityId,
            stateId: currentAddress?.stateId,
            pincode: currentAddress?.pincode,
            type: currentAddress?.type,
            createdBy: currentAddress?.createdBy,
            updatedBy: currentAddress?.updatedBy,
            createdAt: currentAddress?.createdAt,
            updatedAt: currentAddress?.updatedAt,
            area: {
              id: currentAddress?.areaId,
              cityId: currentAddress?.cityId,
              name: areaObject?.name,
            },
            city: {
              id: currentAddress?.cityId,
              stateId: currentAddress?.stateId,
              name: areaObject?.city?.name,
            },
            state: {
              id: currentAddress?.stateId,
              name: areaObject?.city?.state?.name,
            },
          },
        });
      }
    });
  }




  const combinedData = [...(filteredData || []), ...postDataArray];

  putDataArray.forEach(obj2Item => {

    // Find the corresponding object in obj1 based on id
    const indexToUpdate = combinedData.findIndex(obj1Item => obj1Item.id === obj2Item.id);

    // If object is found in combinedData, update its properties
    if (indexToUpdate !== -1) {
      const obj1ItemToUpdate = combinedData[indexToUpdate];
      if (obj2Item.relation === obj1ItemToUpdate.relation) {
        // Create a new object with updated properties from obj2Item
        const updatedObj1Item = { ...obj1ItemToUpdate, ...obj2Item };

        combinedData[indexToUpdate] = updatedObj1Item;
      }
    } else {
      let indexToUpdate;

      combinedData.forEach(obj => {
        const index = obj.relations.find(rel => rel.id === obj2Item.id);
        if (index) {
          indexToUpdate = index.id;
          return; // Exit loop if found
        }
      });

      let obj1ItemToUpdate;
      for (const person of combinedData) {
        obj1ItemToUpdate = person.relations.find(rel => rel.id === indexToUpdate);
        if (obj1ItemToUpdate) {
          break;
        }
      }

      const updatedObj1Item = { ...obj1ItemToUpdate, ...obj2Item };


      const updatedRelationIndex = combinedData.findIndex(item => item.relations.some(relation => relation.id === updatedObj1Item.id));

      if (updatedRelationIndex !== -1) {
        combinedData[updatedRelationIndex].relations = combinedData[updatedRelationIndex].relations.map(relation => {
          if (relation.id === updatedObj1Item.id) {
            return updatedObj1Item;
          }
          return relation;
        });
      }
    }
  });

  const modifiedData = combinedData.map((items) => {
    const rowData = {};
    columns.forEach((column) => {
      if (column.field === "area") {
        rowData[column.field] = items.currentAddress?.area.name;
      } else if (column.field === "address") {
        rowData[column.field] = items.currentAddress?.address;
      } else if (column.field === "city") {
        rowData[column.field] = items.currentAddress?.city.name;
      } else if (column.field === "state") {
        rowData[column.field] = items.currentAddress?.state.name;
      } else if (column.field === "pincode") {
        rowData[column.field] = items.currentAddress?.pincode;
      } else if (column.field === "id" && column.field === "id") {
        rowData[column.field] = items.id;
      } else if (column.field === "relations" && column.field === "relations") {
        rowData[column.field] = items.relations;
      } else {
        rowData[column.field] = items[column.field];
      }
    });

    let formattedDob;
    if (items.dateOfBirth) {
      const dob = new Date(items.dateOfBirth);
      formattedDob = `${dob.getDate().toString().padStart(2, '0')}-${(dob.getMonth() + 1).toString().padStart(2, '0')}-${dob.getFullYear()}`;

    }
    if (true) {
      rowData["id"] = items.id;
      rowData["name"] = items.name;
      rowData["dateOfBirth"] = formattedDob ? formattedDob : "NA";
      rowData["email"] = items.email;
      rowData["gender"] = items.gender;
      rowData["primaryContactNumber"] = items.primaryContactNumber;
      rowData["secondaryContactNumber"] = items.secondaryContactNumber;
      rowData["relation"] = items.relation;
      rowData["occupation"] = items.occupation;
      rowData["remark"] = items.remark;
    }
    return rowData;
  });


  const parentData = modifiedData.map((data, index) => ({
    id: data.id, // Include the id field here
    ...data,
    srNo: index + 1
  }));


  return (
    <>
      <div className="content-header ">View Parents</div>
      {parentData === null ? (
        <center>
          <p>Loading...</p>
        </center>
      ) : parentData.length === 0 ? (
        <center style={{ color: "blue", margin: "1rem" }}>
          <p>No records available</p>
        </center>
      ) : (
        <Table data={parentData} columns={columns} hover={true} />
      )}
    </>
  );
};
