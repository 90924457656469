import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { API_PATH } from "../../Api/api";
import { franchiseeSchoolId, token } from "../../Api/token";
import { responseFormat } from "../Auth/errorResponse";

const slice = createSlice({
  name: "updateStudent",
  initialState: {
    data: null,
    isLoading: false,
    hasError: false,
    successMessage: "",
    showSuccessMessage: false,
    errorMessage: "",
    showErrorMessage: false,
  },
  reducers: {
    fetchUpdateStudentDataStart: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    fetchUpdateStudentDataSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchUpdateStudentFailure: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setShowSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setShowErrorMessage: (state, action) => {
      state.showErrorMessage = action.payload;
    },
  },
});

export const {
  fetchUpdateStudentDataStart,
  fetchUpdateStudentDataSuccess,
  fetchUpdateStudentFailure,
  setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage
} = slice.actions;

export const fetchUpdateStudentDataOnce =
  (id, updatedData) => async (dispatch, getState) => {
    const { data, isLoading } = getState().updateStudent;

    const tokenId = token();

    dispatch(fetchUpdateStudentDataStart());

    try {
      // Make your API request here using the fetch function
      const response = await fetch(API_PATH + `/student/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenId}`,
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error("Request failed"); // You can customize error handling
      }

      const data1 = await response.json();
      const data = {...data1.data, "dateOfJoining": updatedData.dateOfJoining, "classesClassSectionId": updatedData.classesClassSectionId}
      
      const finalResponse = {"message": "updated", "data": data}
      dispatch(fetchUpdateStudentDataSuccess(finalResponse));
      dispatch(setSuccessMessage("Successful!"));
      dispatch(setShowSuccessMessage(true));
    } catch (error) {
      // Handle errors and dispatch the failure action
      dispatch(fetchUpdateStudentFailure());
      const errMessage = responseFormat({ error })
      dispatch(setErrorMessage(errMessage));
      dispatch(setShowErrorMessage(true));
    }
  }


export default slice.reducer;
