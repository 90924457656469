import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';


const slice = createSlice(
    {
        name: 'ViewComplaintsSuggestions',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchProgramsData: (state, action) => {
                state.isLoading = action.payload;
                state.hasError = false;
            },
            fetchProgramSuccess: (state, action) => {
                state.isLoading = false;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchProgramFailure: (state, action) => {
                state.isLoading = false;
                state.hasError = action.payload;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
        },
    },

);

export const { fetchProgramsData, fetchProgramSuccess, fetchProgramFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage } = slice.actions;

export const viewComplaintsSuggestionsGet = (isActive, franchiseeSchoolId) => async (dispatch, getState) => {

    const { data, isLoading } = getState().ViewComplaintsSuggestions;
    const tokenId = token();

    if (data === null && !isLoading) {
        dispatch(fetchProgramsData());

        try {
            const response = await fetch(API_PATH + `/guardian-complaint-suggestions?isActive=${isActive}&franchiseeSchoolId=${franchiseeSchoolId}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenId}`
                }
            })
            if (!response.ok) {
                throw new Error('Request failed');
            }

            const data1 = await response.json();
            dispatch(fetchProgramSuccess(data1));
        } catch (error) {
            dispatch(fetchProgramFailure());
        }
    }

}

export default slice.reducer;
