import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { fetchRevenueTypeView } from "./slice/revenueTypeViewSlicer";
import { RevenuesViewSlicer } from "./slice/revenueSheetViewSlicer";
import TableView from "../reUsableForm/table/table";
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import SelectDropdown from "../SelectDropdown";
import { updateRevenueView, setShowErrorMessageVehicle, setShowSuccessMessageVehicle, } from "./slice/revenueSheetViewPutSlicer";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import MyPDFDocument from "./PdfGenerator";
import { pdf } from "@react-pdf/renderer";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { DataArray } from "@mui/icons-material";

const RevenueSheetView = () => {
    const dispatch = useDispatch();
    const apiData = useSelector((state) => state.revenueSheetView.data) || [];
    const apiDataa = useSelector((state) => state.revenueTypeView.data) || [];
    const postData = useSelector((state) => state.revenueType.data) || [];

    const showSuccessMsg = useSelector((state) => state.revenueViewEdit.showSuccessMessage);
    const successMsg = useSelector((state) => state.revenueViewEdit.successMessage);
    const showErrorMsg = useSelector((state) => state.revenueViewEdit.showErrorMessage);
    const errorMsg = useSelector((state) => state.revenueViewEdit.errorMessage);

    // const [showEditModalRevenueSheet, setShowEditModalRevenueSheet] = useState(false);
    // const [editedDataRevenueSheet, setEditedDataRevenueSheet] = useState({});
    const [editIsUpdate, setEditIsUpdate] = useState(false);


    const [showEditModal, setShowEditModal] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [formData, setFormData] = useState({
        fromDate: '',
        toDate: '',
        revenueTypeId: null,
    });
    const [validated, setValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({
        fromDate: false,
        toDate: false,
        revenueTypeId: false,
    });

    const apiRevenueTypeData = useSelector((state) => state.revenueTypeView.data) || [];
    const postRevenueTypeData = useSelector((state) => state.revenueType.data) || [];

    const combinedRevenueTypeData = [...(apiRevenueTypeData || []), ...postRevenueTypeData]

    const convert_DD_MM_YYYY_to_YYYY_MM_DD = (dateString) => {
        if (typeof dateString == 'string') {
            const parts = dateString.split('/');
            if (parts.length === 3) {
                const [day, month, year] = parts;
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            }
        }
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleUpdate = (id, data) => {
        const updatedData = {
            "date": convert_DD_MM_YYYY_to_YYYY_MM_DD(data.date),
            "revenueTypeId": data.revenueTypeId,
            "franchiseeSchoolId": data.franchiseeSchoolId,
            "feeAmount": data.amountReceived,
            "modeOfPayment": data.modeOfPayment,
            "studentId": data.studentId,
        }
        dispatch(updateRevenueView(id, updatedData))
        setShowEditModal(true);
        setEditIsUpdate(true);

    }



    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessageVehicle(false));
        setShowEditModal(false);
        setEditIsUpdate(false);
        // handleCancel();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessageVehicle(false));
    };


    useEffect(() => {
        dispatch(fetchRevenueTypeView());
        if (editIsUpdate) {
            dispatch(RevenuesViewSlicer());
        } else {
            dispatch(RevenuesViewSlicer());
        }
    }, [dispatch, editIsUpdate]);

    // Combine data from different sources
    const combinedData = [...(apiDataa || []), ...postData];

    // Process API data
    const apiDataArray = Array.isArray(apiData) ? apiData : [];
    const processedData = apiDataArray.map((row, index) => ({
        ...row,
        index: index + 1,
        // id: row.id,
        date: row.date ? new Date(row.date).toLocaleDateString('en-GB') : 'N/A',
        revenueTypeName: row.revenueType ? row.revenueType.name : 'N/A',
        amountReceived: parseFloat(row.feeAmount) || 0,
        modeOfPayment: row.modeOfPayment || 'N/A',
        franchiseeSchoolName: row.franchiseeSchool ? row.franchiseeSchool.name : 'N/A',
        className: row.student?.studentEnrollments?.class?.name || 'N/A',
        classSection: row.student?.studentEnrollments?.section?.name || 'N/A',
        studentName: row.student ? row.student.name : 'N/A',
        school: row.franchiseeSchool.name
    }));

    const handleSubmit = (event) => {
        event.preventDefault();
        setValidated(true);
        if (formData.fromDate && formData.toDate && formData.revenueTypeId) {
            dispatch(RevenuesViewSlicer(formData.fromDate, formData.toDate, formData.revenueTypeId.id));
        } else {
            setFormErrors({
                fromDate: !formData.fromDate,
                toDate: !formData.toDate,
                revenueTypeId: !formData.revenueTypeId,
            });
        }
    };


    const handleClear = () => {
        setFormData({
            fromDate: '',
            toDate: '',
            revenueTypeId: null,
        });
        dispatch(RevenuesViewSlicer());
        setFormErrors({
            fromDate: false,
            toDate: false,
            revenueTypeId: false,
        });
        setValidated(false);
    };

    const columns = [
        { field: 'index', header: 'Sr.No' },
        { field: 'school', header: 'School' },
        { field: 'date', header: 'Date' },
        { field: 'className', header: 'Class' },
        { field: 'classSection', header: 'Section' },
        { field: 'studentName', header: 'Student' },
        { field: 'revenueTypeName', header: 'Revenue Type' },
        { field: 'amountReceived', header: 'Fee Amount' },
        { field: 'modeOfPayment', header: 'Mode Of Payment' },
        { field: 'edit', header: 'Edit' },
        { field: 'pdfGenerate', header: 'PDF' },
    ];

    const modeOfPaymentOptions = [
        { value: "UPI", label: "UPI" },
        { value: "Cheque", label: "Cheque" },
        { value: "Cash", label: "Cash" },
    ];

    const revenueTypeOptions = combinedRevenueTypeData.map((item) => ({
        id: item.id,
        value: item.name,
        label: item.name
    }));
    const parseDate = (dateString) => {
        if (typeof dateString == "string") {
            const parts = dateString.split('/');
            if (parts.length === 3) {
                // Assuming the format is dd-MM-yyyy
                const [day, month, year] = parts;
                return new Date(year, month - 1, day); // Month is 0-indexed
            }
            return null; // Return null for invalid dates
        } else {
            return dateString
        }
    };
    const sendColumns = [
        {
            field: 'date',
            header: 'Date',
            editor: (options) => {
                const parsedDate = editedData.date ? parseDate(editedData.date) : null;
                return (
                    <DatePickerPast
                        name="date"
                        label="Date"
                        value={parsedDate}
                        onChange={(name, date, selectedDate) => setEditedData({ ...editedData, "date": date })} // Adjust callback to save date
                        placeholder="Select Date"
                        dateFormat="dd-MM-yyyy"
                        feedbackMessage="Please select a valid date."
                        validated={false} // Set this based on your validation logic
                    />
                );
            },
            body: (rowData) => <span>{rowData.date}</span>,
        },
        {
            field: 'revenueTypeName', header: 'Revenue Type',
            editor: (options) => (
                <SearchableDropDownField
                    value={revenueTypeOptions.find(option => option.id === editedData.revenueTypeId ? option.value : null)}
                    options={revenueTypeOptions}
                    onChange={(selectedOption) => setEditedData({ ...editedData, revenueTypeId: selectedOption?.id })}
                />
            ),
            body: (rowData) => <span>{rowData.revenueTypeName}</span>,
        },
        { field: 'amountReceived', header: 'Fee Amount' },
        {
            field: 'modeOfPayment', header: 'Mode Of Payment',
            editor: (options) => (
                <SearchableDropDownField
                    value={modeOfPaymentOptions.find(option => option.value === editedData.modeOfPayment ? option.value : null)}
                    options={modeOfPaymentOptions}
                    onChange={(selectedOption) => setEditedData({ ...editedData, modeOfPayment: selectedOption?.value })}
                />
            ),
            body: (rowData) => <span>{rowData.modeOfPayment}</span>,
        },
    ]
    const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId');
    const FranchiseeSchoolId = Number(franchiseeSchoolId);
    const generatePdfBlob = async (data) => {
        
        try {
            const pdfContent = data ? <MyPDFDocument info={data || {}} /> : null;
            const pdfBlob = await pdf(pdfContent).toBlob();
            return pdfBlob;
        } catch (error) {
            console.error('Error generating PDF:', error);
            return null;
        }
    };
    
    const handlePDF = async (id) => {
        try {
            const response = await fetch(`${API_PATH}/revenues/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token()}`,
                },
            });
            
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
    
            const data = await response.json();
            const pdfBlob = await generatePdfBlob(data);
    
            if (pdfBlob) {
                const blobUrl = URL.createObjectURL(pdfBlob);
                window.open(blobUrl);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    


    return (
        <>
            <h4 className="content-header">Revenue View</h4>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12} md={6} lg={2} className="rowCss">
                        <DatePickerPast
                            label="From Date*"
                            value={formData.fromDate || ''}
                            onChange={(name, date, selectedDate) => {
                                setFormData({ ...formData, fromDate: date });
                                setFormErrors(prevErrors => ({ ...prevErrors, fromDate: !date }));
                            }}
                            required
                            name="From Date"
                            placeholder="From Date"
                            dateFormat="dd-MM-yyyy"
                            isValidDate={(date) => true}
                            feedbackMessage="Please select the from date."
                            validated={validated}
                        />
                        {formErrors.fromDate && (
                            <div className="error-message">Please select the from date.</div>
                        )}
                    </Col>
                    <Col xs={12} md={6} lg={2} className="rowCss">
                        <DatePickerPast
                            label="To Date*"
                            value={formData.toDate || ''}
                            onChange={(name, date, selectedDate) => {
                                setFormData({ ...formData, toDate: date });
                                setFormErrors(prevErrors => ({ ...prevErrors, toDate: !date }));
                            }}
                            required
                            name="To Date"
                            placeholder="To Date"
                            dateFormat="dd-MM-yyyy"
                            isValidDate={(date) => true}
                            feedbackMessage="Please select the to date."
                            validated={validated}
                        />
                        {formErrors.toDate && (
                            <div className="error-message">Please select the to date.</div>
                        )}
                    </Col>
                    <Col xs={12} md={6} lg={2} className="rowCss">
                        <SelectDropdown
                            isSearchable
                            value={formData.revenueTypeId ? { id: formData.revenueTypeId.id, label: formData.revenueTypeId.label } : null}
                            options={combinedData.map((option) => ({
                                id: option.id,
                                value: option.name,
                                label: option.name
                            }))}
                            onChange={(selectedOption) => {
                                setFormData(prevFormData => ({
                                    ...prevFormData,
                                    revenueTypeId: selectedOption
                                }));
                                setFormErrors(prevFormErrors => ({ ...prevFormErrors, revenueTypeId: !selectedOption }));
                            }}
                            placeholder="Select revenue"
                        />
                        {formErrors.revenueTypeId && (
                            <div className="error-message">
                                Please Select revenue.
                            </div>
                        )}
                    </Col>
                </Row>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-5%",
                        marginLeft: "35%",
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit">
                        Submit
                    </button>

                </div>
            </Form>

            <Row>
                {processedData.length > 0 ? (
                    <TableView
                        columns={columns}
                        data={processedData}
                        isExport={true}
                        isExcel={true}
                        // showEditModalRevenueSheet={showEditModalRevenueSheet}
                        // setShowEditModalRevenueSheet={setShowEditModalRevenueSheet}
                        // editedDataRevenueSheet={editedDataRevenueSheet}
                        // setEditedDataRevenueSheet={setEditedDataRevenueSheet}

                        showEditModal={showEditModal}
                        setShowEditModal={setShowEditModal}
                        editedData={editedData}
                        setEditedData={setEditedData}
                        handleUpdate={handleUpdate}
                        excelFileName={`revenues`}
                        colFields={sendColumns}
                        handlePDF={handlePDF}
                    />
                ) : (
                    <div>No data available</div>
                )}
            </Row>

            <SuccessMessagePopup
                show={showSuccessMsg}
                onClose={handleCloseSuccessPopup}
                successMessage={successMsg}
            />
            <ErrorMessagePopup
                show={showErrorMsg}
                onClose={handleCloseErrorPopup}
                errorMessage={errorMsg}
            />
        </>
    );
};


export default RevenueSheetView