import { createSlice } from "@reduxjs/toolkit";
import { franchiseeSchoolId, token } from "../../../Api/token";
import { fetchWithAuthorization } from "../../Auth/dependency-get";
import { responseFormat } from "../../Auth/errorResponse";
import { API_PATH } from "../../../Api/api";



const slice = createSlice({
    name: 'updatedParentTeacherMeeting',
    initialState: {
        data: null,
        isLoading: false,
        hasError: false,
        successMessage: "",
        showSuccessMessage: false,
        errorMessage: "",
        showErrorMessage: false,
    },
    reducers: {
        updatedMeeting: (state) => {
            state.isLoading = true;
            state.hasError = false;
        },
        updatedMeetingSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        updatedMeetingError: (state) => {
            state.isLoading = false;
            state.hasError = true;
        },
        setSuccessMessage: (state, action) => {
            state.successMessage = action.payload;
        },
        setShowSuccessMessage: (state, action) => {
            state.showSuccessMessage = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setShowErrorMessage: (state, action) => {
            state.showErrorMessage = action.payload;
        },
    },
});

export const { updatedMeeting, updatedMeetingSuccess, updatedMeetingError, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage } = slice.actions;

export const UpdatedParentTeacherMeeting = (id, data) => async (dispatch, getState) => {


    const tokenId = token();
    dispatch(updatedMeeting());


    try {
        const apiUrl = API_PATH + `/franchisee-school/${franchiseeSchoolId()}/parent-teacher-meets/${id}`;

        // Make your API request here using the fetch function
        const response = await fetch(apiUrl, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenId}`,
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error("Request failed"); // You can customize error handling
        }

        const data1 = await response.json();
        dispatch(updatedMeetingSuccess(data1));
        dispatch(setSuccessMessage("Successfully Updated!"));
        dispatch(setShowSuccessMessage(true));
    } catch (error) {
        // Handle errors and dispatch the failure action
        dispatch(updatedMeetingError());
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};

export default slice.reducer;
