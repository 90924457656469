import React, { useEffect } from 'react';
import "./enquiryView.css"
import Table from '../reUsableForm/table/table'
import { useDispatch, useSelector } from 'react-redux';
import { fetchEnquiryView } from './slice/enquiryViewSlicer';
import { fetchPlaySchool } from './slice/playSchoolSlicer';
import { fetchClasses } from './slice/classesSlicer';

const EnquiryView = () => {
    const apiData = useSelector((state) => state.enquiryView.data) || [];
    const postData = useSelector((state) => state.EnquiryEntry.data) || [];
    const playSchool = useSelector((state) => state.playSchool.data) || [];
    const classes = useSelector((state) => state.classes.data) || [];

    const putData = useSelector((state) => state.enquiryFollowUp.data) || [];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchEnquiryView());
        dispatch(fetchPlaySchool());
        dispatch(fetchClasses());
    }, [dispatch]);

    const getBackgroundColor = (mindSet) => {
        switch (mindSet) {
            case 'Positive':
                return 'green';
            case 'Negative':
                return 'red';
            case 'Follow-Up':
                return 'yellow';
            default:
                return 'transparent';
        }
    }

    const columns = [
        { field: "index", header: "Sr.No" },
        { field: "date", header: "Date" },
        { field: "dateOfBirth", header: "Date Of Birth" },
        { field: "studentName", header: "Student Name" },
        { field: "fatherName", header: "Father Name" },
        { field: "fatherContactNumber", header: "Father Contact No" },
        { field: "fatherOccupation", header: "Father Occupation" },
        { field: "motherName", header: "Mother Name" },
        { field: "motherContactNumber", header: "Mother Contact No" },
        { field: "motherOccupation", header: "Mother Occupation" },
        { field: "address", header: "Address" },
        { field: "expectedDateOfJoining", header: "Expected Date Of Joining" },
        { field: "referral", header: "Referral" },
        { field: "specialComments", header: "Special Comments" },
        { field: "program", header: "Play School" },
        { field: "className", header: "Classes" },
    ];
    const combinedData = [...(apiData || []), ...postData];

    const newModifiedData = combinedData.map((postItem, index) => {
        const findObjectProgram = playSchool.find((obj) => obj.id === postItem.programId);
        const findObjectClass = classes.find((obj) => obj.id === postItem.classId);

        const formattedDate = new Date(postItem.date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        const formattedDateOfBirth = new Date(postItem.dateOfBirth).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        const formattedExpectedDateOfJoining = new Date(postItem.expectedDateOfJoining).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        return {
            index: index + 1,
            id: postItem.id,
            date: formattedDate,
            dateOfBirth: formattedDateOfBirth,
            studentName: postItem.studentName ? postItem.studentName : "N/A",
            fatherName: postItem.fatherName ? postItem.fatherName : "N/A",
            fatherContactNumber: postItem.fatherContactNumber ? postItem.fatherContactNumber : "N/A",
            fatherOccupation: postItem.fatherOccupation ? postItem.fatherOccupation : "N/A",
            motherName: postItem.motherName ? postItem.motherName : "N/A",
            motherContactNumber: postItem.motherContactNumber ? postItem.motherContactNumber : "N/A",
            motherOccupation: postItem.motherOccupation ? postItem.motherOccupation : "N/A",
            address: postItem.address ? postItem.address : "N/A",
            expectedDateOfJoining: postItem.expectedDateOfJoining ? formattedExpectedDateOfJoining : 'N/A',
            referral: postItem.referral ? postItem.referral : "N/A",
            specialComments: postItem.specialComments ? postItem.specialComments : "N/A",
            program: findObjectProgram ? findObjectProgram.name : '',
            className: findObjectClass ? findObjectClass.name : '',
            backgroundColor: getBackgroundColor(postItem.mindSet),
        };
    });

    const newUpdatedData = newModifiedData.filter(obj => !putData.some(postObj => postObj.id === obj.id))


    return (
        <div>
            <h4 className='enquiryView'>Enquiry View</h4>
            {newUpdatedData === null ? (
                <center><p>Loading...</p></center>
            ) : newUpdatedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) : (
                <Table data={newUpdatedData} columns={columns} hover={true} />
            )}
        </div>
    );
};

export default EnquiryView;
