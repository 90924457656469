/*
    authors: Rajeev
    date: 02-11-2023
    update:02-11-2023 
    version: 1.0
*/

import React, { useEffect, useState } from "react";
import Table from "../reUsableForm/table/table4";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssessmentDataOnce } from "./slicer/viewAssessmentSlicer";
import { Form, Row, Col, Title } from "react-bootstrap";
import SelectDropdown from "../SelectDropdown";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import TextInputField from "../reUsableForm/textInputField";
import { validationRegexPinNo, validationRegexOnlyAlpha1 } from "../reUsableForm/regex";
import { fetchAssessmentReportDataOnce } from "./slicer/viewAssessmentReportSlicer";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { getParentLoginData } from "../parent/parentLoginSlicer/parentLoginRequests";
import { pdf } from "@react-pdf/renderer";
import AssessmentPdf from "./assessmentPdf";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
function transformApiResponse(apiResponse) {


  const subjectColumnsData = apiResponse.assessmentReport.FA1.map((item) => ({
    subject: item.subject.name,
    FA1: item.marks,
    FA2: (apiResponse.assessmentReport.FA2.find((x) => x.subject.id === item.subject.id) || {}).marks || '-',
    SA1: (apiResponse.assessmentReport.SA1.find((x) => x.subject.id === item.subject.id) || {}).marks || '-',
    FA3: (apiResponse.assessmentReport.FA3.find((x) => x.subject.id === item.subject.id) || {}).marks || '-',
    SA2: (apiResponse.assessmentReport.SA2.find((x) => x.subject.id === item.subject.id) || {}).marks || '-',
  }));

  const attendanceColumnsData = apiResponse.attendanceReport || {};

  return {
    subjectColumnsData,
    attendanceColumnsData,
  };
}

export const ViewTermAssessment = () => {

  const subjectColumns = [
    {
      group: "Term 1",
      columns: [
        { field: "term1_written", header: "Written" },
        { field: "term1_oral", header: "Oral" },
        { field: "term1_total", header: "Total" },
        { field: "term1_grade", header: "Grade" },
        { field: "term1_remarks", header: "Remarks" },
      ],
    },
    {
      group: "Term 2",
      columns: [
        { field: "term2_written", header: "Written" },
        { field: "term2_oral", header: "Oral" },
        { field: "term2_total", header: "Total" },
        { field: "term2_grade", header: "Grade" },
        { field: "term2_remarks", header: "Remarks" },
      ],
    },
  ];



  const attendanceColumns = [
    { field: "subject", header: "Subject" },
    { field: "march", header: "March" },
    { field: "april", header: "April" },
    { field: "may", header: "May" },
    { field: "june", header: "June" },
    { field: "july", header: "July" },
    { field: "august", header: "August" },
    { field: "september", header: "September" },
    { field: "october", header: "October" },
    { field: "november", header: "November" },
    { field: "december", header: "December" },
    { field: "january", header: "January" },
    { field: "february", header: "February" },
  ];

  const [assessmentReportData, setAssessmentReportData] = useState([]);
  const [assessmentReportColumns, setAssessmentReportColumns] = useState([]);
  const [activiteData, setActiviteData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [formData, setFormData] = useState({
    class: "",
    classId: "",
  });

  const [formErrors, setFormErrors] = useState({
    studentName: false,
    assessmentType: false,
    class: false,
  });
  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
  const FranchiseeSchoolId = Number(franchiseeSchoolId);
  const [dropDowns, setDropDowns] = useState({});
  const [dropDownss, setDropDownss] = useState({});
  const [editDropDownss, setEditDropDownss] = React.useState({
    assessmentType: "",
  });
  const [validated, setValidated] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const dispatch = useDispatch();
  const roleId = sessionStorage.getItem("roleId");
  const parentLoginObject = useSelector(
    (state) => state.parentLoginReducer.getParentLoginData
  );

  const data = parentLoginObject?.data;

  const viewStudentData = useSelector((state) => state.viewStudent.data) || [];

  useEffect(() => {
    if (roleId == 5) {
      if (parentLoginObject?.loading === "idle") {
        const parentId = sessionStorage.getItem("userId");
        dispatch(getParentLoginData(parentId));
      }
    } else {
      if (viewStudentData.length === 0) {
        dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
      }
    }
  }, [dispatch, parentLoginObject, viewStudentData]);


  const viewAssessmentData =
    useSelector((state) => state.viewAssessmentReport.data) || [];


  const studentId = formData.studentNameId;

  const transformData = (data, transformFunction, result) => {
    for (const assessmentType in data) {
      const assessments = data[assessmentType];
      transformFunction(assessmentType, assessments, result);
    }
  };


  const transformApiResponse = (apiResponse) => {
    const subjects = new Set();
    const columnGroups = [];

    // Gather all unique subjects and prepare column groups
    Object.keys(apiResponse).forEach(group => {
      const termGroupLower = group.toLowerCase();
      const groupLower = termGroupLower.replace(" ", "");
      // Add columns for each assessment period dynamically
      const columns = [
        { field: `${groupLower}_written`, header: "Written" },
        { field: `${groupLower}_oral`, header: "Oral" },
        { field: `${groupLower}_total`, header: "Total" },
        { field: `${groupLower}_grade`, header: "Grade" },
        { field: `${groupLower}_remarks`, header: "Remarks" }
      ];
      columnGroups.push({ group, columns });

      // Collect unique subjects
      apiResponse[group].forEach(entry => {
        subjects.add(entry.subject.name); // Adjusted to get the subject name
      });
    });

    // Create an initial structure for transformed data
    const transformedData = Array.from(subjects).map(subject => ({ subject }));

    // Populate the transformed data
    Object.keys(apiResponse).forEach(group => {
      apiResponse[group].forEach(entry => {
        const subjectEntry = transformedData.find(item => item.subject === entry.subject.name); // Adjusted to get the subject name
        const termGroupLower = group.toLowerCase();
        const groupLower = termGroupLower.replace(" ", "");
        // Populate the fields with marks data
        subjectEntry[`${groupLower}_written`] = `${entry.writtenScoreMarks}/${entry.writtenMaxMarks}`;
        subjectEntry[`${groupLower}_oral`] = `${entry.oralScoreMarks}/${entry.oralMaxMarks}`;
        subjectEntry[`${groupLower}_total`] = `${entry.totalScoreMarks}/${entry.totalMaxMarks}`;
        subjectEntry[`${groupLower}_grade`] = entry.grade;
        subjectEntry[`${groupLower}_remarks`] = entry.remarks;
      });
    });

    return { transformedData, columnGroups };
  };

  const transformAssessmentData = (assessmentType, assessments, result) => {
    for (const assessment of assessments) {
      const subjectName = assessment.subject.name;
      const grade = assessment.grade;

      const existingSubject = result.find((subject) => subject.subject === subjectName);

      if (existingSubject) {
        existingSubject[assessmentType] = grade;
      } else {
        const newSubject = {
          subject: subjectName,
          [assessmentType]: grade,
        };
        result.push(newSubject);
      }
    }
  };


  const handleSearchClick = async (e) => {
    e.preventDefault();
    const newFormErrors = {
      studentName: !dropDowns.studentName,
    };
    setFormErrors(newFormErrors);
    const hasErrors = Object.values(newFormErrors).some((error) => error);

    if (hasErrors) {
      setValidated(true);
      return;
    }
    try {
      const response = await fetch(`${API_PATH}/franchisee-school/${FranchiseeSchoolId}/pre-school-term-assessments/${studentId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data1 = await response.json();
      setAssessmentData(data1); // Store the fetched data in state

      const period = dropDowns.studentName;


      const assessmentReportData = [];
    //   const activiteData = [];

      // Transform and set data for the table
      const { transformedData, columnGroups } = transformApiResponse(data1.overallReport.assessmentReport);
      transformData(data1.overallReport.assessmentReport, transformAssessmentData, assessmentReportData);
      setAssessmentReportData(transformedData);
      setAssessmentReportColumns(columnGroups);
      setShowTable(true);
    //   setActiviteData(activiteData);
      setAttendanceData(data1.overallReport.attendanceReport);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const [assessmentData, setAssessmentData] = useState(null);

  const handleSubmission = async () => {
    try {
      if (!dropDownss.assessmentType) {
        console.error('No assessment period selected');
        return;
      }

      const period = dropDownss.assessmentType;

      if (!assessmentData || !assessmentData.overallReport.assessmentReport[period]) {
        console.error(`No data available for ${period}`);
        return;
      }

      const fa1AssessmentReport = assessmentData.overallReport.assessmentReport[dropDownss.assessmentType];

      const filtered_assessment_data = {
        assessmentType: JSON.stringify(dropDownss.assessmentType),
        assessmentReport: JSON.stringify(fa1AssessmentReport),
        attendanceReport: JSON.stringify(assessmentData.overallReport.attendanceReport),
        student: JSON.stringify(assessmentData.overallReport.studentDetails),
      };

      const pdfBlob = await generatePdfBlob(filtered_assessment_data);

      if (pdfBlob) {
        const blobUrl = URL.createObjectURL(pdfBlob);
        window.open(blobUrl);
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };


  const generatePdfBlob = async (data) => {
    try {

      const pdfContent = data ?
        <AssessmentPdf
          assessmentType={data.assessmentType}
          assessmentReport={data.assessmentReport}
          attendanceReport={data.attendanceReport}
          student={data.student}
        />
        : null;

      const pdfBlob = await pdf(pdfContent).toBlob();
      return pdfBlob;
    } catch (error) {
      console.error('Error generating PDF:', error);
      return null;
    }
  };


  const navigate = useNavigate();
  const handleEditClick = async () => {
    if (!editDropDownss.assessmentType) {
      setFormErrors({ ...formErrors, assessmentType: true });
      return;
    }

    try {

      const url = `${API_PATH}/franchisee-school/${FranchiseeSchoolId}/assessments/student/${studentId}/assessment-type?assessment=${editDropDownss.assessmentType}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token()}`, // Assuming token() gives you the token
          "Content-Type": "application/json"
        },
        params: {
          assessment: editDropDownss.assessmentType, // Use the selected value
        },
      });

      const data = await response.json();

      // Use navigate to pass the data to the next page
      navigate('/editAssessmentReport', { state: { assessmentData: data, assessmentType: editDropDownss.assessmentType } });
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }

  };

  return (
    <>
      <div className="content-header">View Term Assessment</div>
      <Row>
        {viewStudentData.length > 0 ? (
          <>
            <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
              <SelectDropdown
                value={
                  dropDowns.studentName
                    ? {
                      value: dropDowns.studentName,
                      label: dropDowns.studentName,
                    }
                    : null
                }
                className="input-style"
                options={viewStudentData.map((option) => ({

                  id: option.id,
                  value: option.name || option.studentEnrollments[0].studentRollNumber,
                  label: option.name,
                  class:
                    option.studentEnrollments[0]?.classesClassSection?.class
                      ?.name || "", // Access class information
                  section:
                    option.studentEnrollments[0]?.classesClassSection?.classSection
                      ?.name || null, // Access class information
                  classId:
                    option.studentEnrollments[0]?.classesClassSection?.class
                      ?.id || "",
                }))}
                onChange={(selectedOption) => {
                  setFormData({
                    ...formData,
                    studentNameId: selectedOption.id,
                    class: selectedOption.class,
                    section: selectedOption.section,
                    classId: selectedOption.classId, // Update the class in the form data
                  });
                  setDropDowns({
                    ...dropDowns,
                    studentName: selectedOption.value,
                  });
                  setFormErrors({
                    ...formErrors,
                    studentName: false,
                    class: false, // Update the class in the form errors
                  });
                }}
                placeholder="Student Name"
              />
              {formErrors.studentName && (
                <div className="error-message">
                  Please Select a studentName.
                </div>
              )}
            </Col>

            <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
              <TextInputField
                name="class"
                placeholder="Class*"
                textType="Alphabet"
                required
                value={formData.class}
                feedbackMessage="Please enter a class."
                validated={validated}
                validationRegex={validationRegexOnlyAlpha1}
                validationErrorMessage="Please enter a valid class."
              />
            </Col>
            {formData.section ? (
              <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                <TextInputField
                  name="section"
                  placeholder="Section*"
                  textType="Alphabet"
                  required
                  value={formData.section}
                  feedbackMessage="Please enter a class."
                  validated={validated}
                  validationRegex={validationRegexOnlyAlpha1}
                  validationErrorMessage="Please enter a valid section."
                />
              </Col>
            ) : null}
          </>
        ) : (
          <>
            <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
              <SelectDropdown
                value={
                  dropDowns.studentName
                    ? {
                      value: dropDowns.studentName,
                      label: dropDowns.studentName,
                    }
                    : null
                }
                className="input-style"
                options={data?.childrens?.map((option) => ({
                  id: option.id,
                  value: option.name,
                  label: option.name,
                  class:
                    option.studentEnrollments[0]?.classesClassSection?.class
                      ?.name || "", // Access class information
                  classId:
                    option.studentEnrollments[0]?.classesClassSection?.class
                      ?.id || "",
                }))}
                onChange={(selectedOption) => {
                  setFormData({
                    ...formData,
                    studentNameId: selectedOption.id,
                    class: selectedOption.class,
                    classId: selectedOption.classId, // Update the class in the form data
                  });
                  setDropDowns({
                    ...dropDowns,
                    studentName: selectedOption.value,
                  });
                  setFormErrors({
                    ...formErrors,
                    studentName: false,
                    class: false, // Update the class in the form errors
                  });
                }}
                placeholder="Student Name"
              />
              {formErrors.studentName && (
                <div className="error-message">Please Select a studentName.</div>
              )}
            </Col>


          </>
        )}

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <div style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
          }}>
            <button
              className="save-button-figma-div" // Apply appropriate styling
              onClick={handleSearchClick} // Attach the click event handler
            >
              Search
            </button>
          </div>
        </Col>

      </Row>




      {showTable && (
        <Row>
          {/* {viewStudentData.length > 0 ? (
            <>
              <Row>
                <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                  <SelectDropdown
                    value={
                      dropDownss.assessmentType
                        ? {
                          value: dropDownss.assessmentType,
                          label: dropDownss.assessmentType,
                        }
                        : null
                    }
                    className="input-style"
                    options={[
                      { value: 'Term 1', label: 'Term 1' },
                      { value: 'Term 2', label: 'Term 2' },
                    ]}
                    onChange={(selectedOption) => {

                      setDropDownss({
                        ...dropDownss,
                        assessmentType: selectedOption.value,
                      });
                      setFormErrors({
                        ...formErrors,
                        assessmentType: false,
                        // class: false, // Update the class in the form errors
                      });
                    }}
                    placeholder="Select Assessment Period"
                  />
                  {formErrors.assessmentType && (
                    <div className="error-message">
                      Please select an assessment period.
                    </div>
                  )}
                </Col>

                <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1%",
                  }}>
                    <button className="save-button-figma-div" onClick={handleSubmission}>PDF</button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                  <SelectDropdown
                    value={
                      editDropDownss.assessmentType
                        ? {
                          value: editDropDownss.assessmentType,
                          label: editDropDownss.assessmentType,
                        }
                        : null
                    }
                    className="input-style"
                    options={[
                        { value: 'Term 1', label: 'Term 1' },
                        { value: 'Term 2', label: 'Term 2' },
                    ]}
                    onChange={(selectedOption) => {

                      setEditDropDownss({
                        ...editDropDownss,
                        assessmentType: selectedOption.value,
                      });
                      setFormErrors({
                        ...formErrors,
                        assessmentType: false,
                        // class: false, // Update the class in the form errors
                      });
                    }}
                    placeholder="Select Assessment Period"
                  />
                  {formErrors.assessmentType && (
                    <div className="error-message">
                      Please select an assessment period.
                    </div>
                  )}
                </Col>

                <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1%",
                  }}>
                    <IconButton size="small" aria-label="edit" onClick={handleEditClick}>
                      <EditIcon />
                    </IconButton>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )} */}
          <Col lg="12" className="mt-4">
            <div className="content-header d-flex align-items-center">
              <span>Assessment Report</span>
              {/* <IconButton size="small" aria-label="edit" onClick={handleEditClick}>
                <EditIcon />
              </IconButton> */}
            </div>
            {assessmentReportData === null ? (
              <center>
                <p>Loading...</p>
              </center>
            ) : assessmentReportData.length === 0 ? (
              <center style={{ color: "blue", margin: "1rem" }}>
                <p>No records available</p>
              </center>
            ) : (
              <Table
                data={assessmentReportData}
                columns={subjectColumns}
                hover={true}
                columnType={"subject"}
              />
            )}
          </Col>
      
          <Col lg="12" className="mt-4">
            <div className="content-header mt-2">ATTENDANCE REPORT</div>
            {attendanceData === null ? (
              <center>
                <p>Loading...</p>
              </center>
            ) : attendanceData.length === 0 ? (
              <center style={{ color: "blue", margin: "1rem" }}>
                <p>No records available</p>
              </center>
            ) : (
              <Table
                data={attendanceData}
                columns={attendanceColumns}
                hover={true}
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
