/*
    authors: Revanth
    date: 18-10-2023
    update:18-10-2023 
    version: 1.0
*/

import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import "./enquiryEntry.css";
import { useDispatch, useSelector } from "react-redux";
import TextInputField from "../../ReusableFields/TextFields/TextInputField";
import {
  validationRegexAddress,
  validationRegexAmount,
  validationRegexMobileNo,
  validationRegexOnlyAlpha,
  validationRegexOnlyAlpha1,
} from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { enquiryEntryPost, setShowErrorMessage, setShowSuccessMessage } from "./slice/enquiryEntrySlicer";
import ReadOnlyTextField from "../../ReusableFields/TextFields/ReadOnlyTextField";
import AddressField from "../../ReusableFields/DescriptionFields/AddressField";
import { validationRegexAlphaNumeric } from "../../ReusableFields/Rejex";
import { fetchPlaySchool } from "./slice/playSchoolSlicer";
import { fetchClasses } from "./slice/classesSlicer";
import { fetchFrequenciesView } from "./slice/enquiryFrequencySlicer";
import { fetchTimingsView } from "./slice/enquiryTimingSlicer";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import Select from 'react-select';
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import DatePicker from "../../ReusableFields/DateFields/DatePickerFuture";
import { fetchDataGetProgramBySchool } from "../programs/slice/getProgramBySchoolSlice";
import { fetchFranchiseClasswiseSectionsDataOnce } from "../Assessment/slicer/viewfranchiseclasswisesectionsSlicer";
import dateFormat from "../reUsableForm/dateFormat";

const EnquiryEntry = () => {
  const [otherValueError, setOtherValueError] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [formErrors, setFormErrors] = useState({ programId: false, expectedDateOfJoining: false, subProgramId: false, timings: false, dayFrequencyId: false, mindSet: false, dateOfBirth: false });
  const [validated, setValidated] = useState(false);

  const showSuccessMsg = useSelector((state) => state.EnquiryEntry.showSuccessMessage);
  const successMsg = useSelector((state) => state.EnquiryEntry.successMessage);

  const showErrorMsg = useSelector((state) => state.EnquiryEntry.showErrorMessage);
  const errorMsg = useSelector((state) => state.EnquiryEntry.errorMessage);
  var userTime = new Date();

  const defaultMindSetOptions = [
    { id: 1, label: 'Positive', value: 'Positive', },
    { id: 2, label: 'Negative', value: 'Negative', },
    { id: 3, label: 'Follow-Up', value: 'Follow-Up', },
  ];
  const [mindSetOptions, setMindSetOptions] = useState(defaultMindSetOptions);
  const playSchool = useSelector((state) => state.getProgramBySchool.data) || [];
  const classes = useSelector((state) => state.classes.data) || [];
  const classesClassSectionData =
    useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];
  const [daycare, setDaycare] = useState(false);
  const [transportation, setTransportation] = useState(false);
  const [otherValue, setOtherValue] = useState('');
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const formatDate1 = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const selectDate = formatDate(selectedDate);

  const [dropDowns, setDropDowns] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropDown, setDropDown] = useState();
  const [dropDownss, setDropDownss] = useState({ way: 'Select' });
  const frequencyData = useSelector((state) => state.frequencies.data) || [];
  const timingsData = useSelector((state) => state.timings.data) || [];

  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
  const selectedFranchiseeSchoolId = Number(franchiseeSchoolId);

  const [classOptions, setClassOptions] = useState([]);

  const staticOptions = [
    { id: 0, name: "Select", label: 'Select' },
    { id: 1, name: "PickOrDrop", label: 'PickOrDrop' },
    { id: 2, name: "PickAndDrop", "label": 'PickAndDrop' },
  ];

  const options = [
    { value: 'Google', label: 'Google' },
    { value: 'Stay Here By', label: 'Stay Here By' },
    { value: 'Banner', label: 'Banner' },
    { value: 'Poster', label: 'Poster' },
    { value: 'Referral', label: 'Referral' },
    { value: 'Others', label: 'Others' },
  ];

  const handleInputChange = (e) => {
    const value = e.target.value;
    setOtherValue(value);

    if ((selectedOption && (selectedOption.value === 'Referral' || selectedOption.value === 'Others'))) {
      if (value.trim() === null) {
        setOtherValueError('Please enter how you heard about us');
      } else {
        setOtherValueError('');
      }
    }
  }

  const handleChange = (selectedOption) => {

    if (selectedOption && selectedOption.value === 'Referral' || selectedOption.value === 'Others') {
      if (otherValue === '') {
        setOtherValueError('Please enter how you heard about us');
      }
    }
    setSelectedOption(selectedOption);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSelectedOption({ value: otherValue, label: otherValue });
    }
  };

  const [selectTimings, setSelectTimings] = useState()

  const [formData, setFormData] = useState({
    date: selectDate,
    studentName: "",
    dateOfBirth: "",
    fatherName: "",
    fatherContactNumber: "",
    fatherOccupation: "",
    motherName: "",
    motherContactNumber: "",
    motherOccupation: "",
    daycare: daycare,
    transportation: transportation,
    address: "",
    programId: "",
    classId: "",
    way: "",
    distance: "",
    timingId: "",
    dayFrequencyId: "",
    mindSet: "",
    referral: selectedOption?.value,
    specialComments: "",
    expectedDateOfJoining: "",
  });



  const dispatch = useDispatch();

  const getAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const franchiseeSchoolId = parseInt(sessionStorage.getItem('franchiseeSchoolId'));

    let referralData = true;

    if (selectedOption) {
      referralData = selectedOption ? false : true
    } else if (selectedOption?.value === "Referral" || selectedOption?.value === "Others") {

      if (otherValue === '') {
        alert("enter value")
      } else {
        referralData = selectedOption ? false : true
      }
    }

    const newFormErrors = {
      date: !selectedDate,
      studentName: !formData.studentName,
      dateOfBirth: !formData.dateOfBirth,
      fatherName: !formData.fatherName,
      fatherContactNumber: !formData.fatherContactNumber,
      fatherOccupation: !formData.fatherOccupation,
      motherName: !formData.motherName,
      motherContactNumber: !formData.motherContactNumber,
      motherOccupation: !formData.motherOccupation,
      programId: false,
      classId: false,
      dayCare: daycare === "true" ? daycare : false,
      transportation: transportation === "true" ? transportation : false,
      dayFrequencyId: daycare ? !dropDowns.dayFrequencyId : false,
      timingId: daycare ? !dropDowns.timingId : false,
      distance: transportation ? !dropDownss.distance : false,
      way: transportation ? !dropDownss.way : false,
      mindSet: !formData.mindSet,
      othervalueError: (
        (selectedOption && (selectedOption.value === 'Referral' || selectedOption.value === 'Others')) &&
        otherValue.trim() === ''
      ),
      referral: referralData,
      otherValue: referralData ? !otherValue : false,
      // specialComments: !formData.specialComments,
      // expectedDateOfJoining: !formData.expectedDateOfJoining,
    };

    if (!daycare) {
      newFormErrors.programId = !formData.programId ? !formData.programId : null;
      newFormErrors.classId = !formData.classId ? !formData.classId : null;
    }


    const hasErrors = Object.values(newFormErrors).some(error => error);

    setFormErrors(newFormErrors);

    if (hasErrors) {
      setValidated(true);
      return;
    }
    setSelectedDate(new Date());



    const programId = formData.programId.id;
    formData.programId = programId ? programId : null;;

    const classId = formData.classId.id;
    formData.classId = classId ? classId : null;

    formData.dayFrequencyId = daycare ? formData.dayFrequencyId : null;
    formData.timingId = daycare ? formData.timingId : null;

    formData.way = transportation ? formData.way : null;
    formData.distance = transportation ? formData.distance : null;

    formData.referral = selectedOption?.value === "Referral" || selectedOption?.value === "Other" ? otherValue : selectedOption.value;

    // formData.dateOfBirth = dateFormat;
    // formData.expectedDateOfJoining = formData.expectedDateOfJoining
    //   ? formData.expectedDateOfJoining.toISOString().split('T')[0]
    //   : null;

    const mindSet = formData.mindSet.value;
    formData.mindSet = mindSet;

    const formDataWithFranchiseeId = {
      ...formData,
      dateOfBirth: dateFormat(formData.dateOfBirth),
      expectedDateOfJoining: formData.expectedDateOfJoining
        ? dateFormat(formData.expectedDateOfJoining)
        : null,
      franchiseeSchoolId: franchiseeSchoolId,
    };

    dispatch(enquiryEntryPost(formDataWithFranchiseeId));


    setFormData({
      date: selectDate,
      studentName: "",
      dateOfBirth: "",
      fatherName: "",
      fatherContactNumber: "",
      fatherOccupation: "",
      motherName: "",
      motherContactNumber: "",
      motherOccupation: "",
      daycare: daycare,
      transportation: transportation,
      address: "",
      programId: "",
      classId: "",
      way: "",
      distance: "",
      timingId: "",
      dayFrequencyId: "",
      mindSet: "",
      referral: null,
      specialComments: "",
      expectedDateOfJoining: "",
    });
    setSelectedOption(null);
    setOtherValue('');
    setMindSetOptions({})
  };


  useEffect(() => {
    if (playSchool.length === 0) {
      dispatch(fetchDataGetProgramBySchool(selectedFranchiseeSchoolId));
    }
  }, [dispatch]);

  useEffect(() => {
    const filteredClasses = classesClassSectionData.filter(
      (classItem) => classItem.programId === formData.programId.id
    );
    const newClassOptions = filteredClasses.map((classItem) => ({
      id: classItem.id,
      value: classItem.id,
      label: classItem.name,
    }));

    setClassOptions(newClassOptions);
  }, [formData.programId]);


  useEffect(() => {
    if (frequencyData.length === 0) {
      dispatch(fetchFrequenciesView());
    }
  }, [frequencyData, dispatch]);

  useEffect(() => {
    if (classesClassSectionData.length == 0) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }
  }, [classesClassSectionData, dispatch]);

  useEffect(() => {
    dispatch(fetchPlaySchool());
    dispatch(fetchClasses());
    dispatch(fetchFrequenciesView());
    dispatch(fetchTimingsView());
  }, [dispatch]);

  const handleCancel = () => {
    setFormData({
      date: selectDate,
      studentName: "",
      dateOfBirth: "",
      fatherName: "",
      fatherContactNumber: "",
      fatherOccupation: "",
      motherName: "",
      motherContactNumber: "",
      motherOccupation: "",
      daycare: daycare,
      transportation: transportation,
      address: "",
      programId: "",
      classId: "",
      way: "",
      distance: "",
      timingId: "",
      dayFrequencyId: "",
      mindSet: "",
      referral: null,
      specialComments: "",
      expectedDateOfJoining: "",
    });
    setSelectedOption(null);
    setOtherValue('');
    setFormErrors({})
    setValidated(false);
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleCancel();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
      <span className="EnquiryEntry">Admission Enquiry</span>
      <Row className='mt-4'>
        <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
          <Form.Control
            // className="enquiryEntryDate"
            className="textInput_Field"
            value={formatDate1(selectedDate)}
          ></Form.Control>
        </Col>

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
          <TextInputField
            name="student Name"
            textType="Alphabet"
            value={formData.studentName}
            onChange={(event) => {
              setFormData({ ...formData, studentName: event.target.value });
            }}
            required
            placeholder="Student Name*"
            validated={validated}
            feedbackMessage="Please enter a student name."
            validationRegex={validationRegexOnlyAlpha1}
            validationErrorMessage="Please enter a valid student name."
            maxLength={100}
          />
        </Col>

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
          <DatePickerPast
            // className="enquiryEntryStudent"
            label="DOB*"
            value={formData.dateOfBirth || ''}
            onChange={(name, date, selectedDate) => {
              setFormData({ ...formData, dateOfBirth: date });
              setFormErrors({ ...formErrors, dateOfBirth: !date });
            }}
            required
            name="DOB"
            placeholder="Select Date of Birth*"
            dateFormat="dd-MM-yyyy"
            isValidDate={(date) => true}
            feedbackMessage="Please select date of birth."
          />
          {formErrors.dateOfBirth && (
            <div className="error-message">
              Please select a date of birth.
            </div>
          )}
        </Col>
        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <ReadOnlyTextField
            name="Student Age"
            textType=""
            value={getAge(formData.dateOfBirth) || ""}
            required
            placeholder="Age"
            onChange={(e) => {
              setFormData({ ...formData, age: getAge(formData.dateOfBirth) });
            }}
            validated={validated}
            validationRegex={validationRegexMobileNo}
            maxLength={25}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
          <TextInputField
            name="father Name"
            textType="Alphabet"
            value={formData.fatherName || ""}
            onChange={(event) => {
              setFormData({ ...formData, fatherName: event.target.value });
            }}
            required
            placeholder="Father Name*"
            validated={validated}
            feedbackMessage="Please enter a father name."
            validationRegex={validationRegexOnlyAlpha1}
            validationErrorMessage="Please enter a valid father name."
            maxLength={100}
          />
        </Col>
        <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
          <TextInputField
            name="contact Number"
            textType="Alphabet"
            value={formData.fatherContactNumber || ""}
            onChange={(event) => {
              setFormData({
                ...formData,
                fatherContactNumber: Number(event.target.value),
              });
            }}
            required
            placeholder="Father Contact Number*"
            validated={validated}
            feedbackMessage="Please enter a father contact number."
            validationRegex={validationRegexMobileNo}
            validationErrorMessage="Please enter a 10 digits contact number."
            maxLength={25}
          />
        </Col>

        <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
          <TextInputField
            name="father Occupation"
            textType="Alphabet"
            value={formData.fatherOccupation || ""}
            onChange={(event) => {
              setFormData({ ...formData, fatherOccupation: event.target.value });
            }}
            required
            placeholder="Father Occupation*"
            validated={validated}
            feedbackMessage="Please enter a father occupation."
            validationRegex={validationRegexOnlyAlpha1}
            validationErrorMessage="Please enter a valid father occupation."
            maxLength={100}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
          <TextInputField
            name="mother Name"
            textType="Alphabet"
            value={formData.motherName || ""}
            onChange={(event) => {
              setFormData({ ...formData, motherName: event.target.value });
            }}
            required
            placeholder="Mother Name*"
            validated={validated}
            feedbackMessage="Please enter a mother name."
            validationRegex={validationRegexOnlyAlpha1}
            validationErrorMessage="Please enter a valid mother name."
            maxLength={100}
          />
        </Col>
        <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
          <TextInputField
            name="Mother contact Number"
            textType="Alphabet"
            value={formData.motherContactNumber || ""}
            onChange={(event) => {
              setFormData({
                ...formData,
                motherContactNumber: Number(event.target.value),
              });
            }}
            required
            placeholder="Mother Contact Number*"
            validated={validated}
            feedbackMessage="Please enter a mother contact number."
            validationRegex={validationRegexMobileNo}
            validationErrorMessage="Please enter a 10 digits contact number."
            maxLength={25}
          />
        </Col>

        <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
          <TextInputField
            name="mother Occupation"
            textType="Alphabet"
            value={formData.motherOccupation || ""}
            onChange={(event) => {
              setFormData({ ...formData, motherOccupation: event.target.value });
            }}
            required
            placeholder="Mother Occupation*"
            validated={validated}
            feedbackMessage="Please enter a Mother occupation."
            validationRegex={validationRegexOnlyAlpha1}
            validationErrorMessage="Please enter a valid mother occupation."
            maxLength={100}
          />
        </Col>

      </Row>

      <Row >
        <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
          <Form.Group>
            <Form.Label className="lableName">Day Care*</Form.Label>
            <div>
              <Form.Check
                inline
                label="Yes"
                type="radio"
                name="daycare"
                value="Yes"
                validated={validated}
                className="malebutton"
                onChange={(event) => {
                  setDaycare(true)
                  setFormData({ ...formData, daycare: true });
                }}

              />
              <Form.Check
                inline
                label="No"
                type="radio"
                name="daycare"
                validated={validated}
                value="No"
                className="malebutton"
                // checked={daycare === "false"}
                onChange={(event) => {
                  setDaycare(false)
                  setFormData({ ...formData, daycare: false });
                }}
                defaultChecked
              />
            </div>
          </Form.Group>
        </Col>
        {daycare === true ? (
          <>
            <Col xs={12} md={6} lg={2} className="rowCss">
              <SearchableDropDownField
                isSearchable

                value={
                  dropDowns.dayFrequencyId
                    ? {
                      value: dropDowns.dayFrequencyId,
                      label: dropDowns.dayFrequencyId,
                    }
                    : null
                }
                options={frequencyData.map((option, index) => ({
                  id: option.id,
                  value: option.name,
                  label: option.name,
                }))}
                onChange={(selectedOption) => {
                  setFormData({
                    ...formData,
                    dayFrequencyId: selectedOption.id,
                  });
                  setFormErrors({ ...formErrors, dayFrequencyId: false });
                  setDropDowns({
                    ...dropDowns,
                    dayFrequencyId: selectedOption.value,
                  });
                }}
                placeholder="Select Frequency"
              />

              {formErrors.dayFrequencyId && (
                <div className="error-message">Please Select dayFrequencyId.</div>
              )}
            </Col>
            <Col xs={12} md={6} lg={2} className="rowCss">
              <SearchableDropDownField
                isSearchable
                value={
                  dropDowns.timingId
                    ? { value: dropDowns.timingId, label: dropDowns.timingId }
                    : null
                }
                options={timingsData.map((option, index) => ({
                  id: option.id,
                  value: option.title,
                  label: option.title,
                }))}
                onChange={(selectedOption) => {
                  setSelectTimings(selectedOption.id)
                  setFormData({ ...formData, timingId: selectedOption.id });
                  setFormErrors({ ...formErrors, timingId: false });
                  setDropDowns({
                    ...dropDowns,
                    timingId: selectedOption.value,
                  });
                }}
                placeholder="Select Timings"
              />
              {formErrors.timingId && (
                <div className="error-message">Please Select timings .</div>
              )}
            </Col>
          </>
        ) : (
          null
        )}

        <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
          <Form.Group>
            <Form.Label className="lableName">Transporation*</Form.Label>
            <div>
              <Form.Check
                inline
                label="Yes"
                type="radio"
                validated={validated}
                name="transportation"
                value="yes"
                className="malebutton"
                // checked={transporation === "true"}
                onChange={(event) => {
                  setTransportation(true)
                  setFormData({ ...formData, transportation: true });

                }}
              />
              <Form.Check
                inline
                label="No"
                type="radio"
                validated={validated}
                name="transportation"
                value="No"
                className="malebutton"
                // checked={transportation === "false"}
                onChange={(event) => {
                  setTransportation(false)
                  setFormData({ ...formData, transportation: false });
                }}
                defaultChecked
              />
            </div>
          </Form.Group>
        </Col>
        {transportation === true ? (
          <>
            <Col xs={12} md={6} lg={2} className="rowCss">
              <select
                validated={validated}
                className="select-dropdown"
                value={dropDownss.way || ''}
                onChange={(selectedOption) => {
                  setFormData({
                    ...formData,
                    way: selectedOption.target.value,
                  });
                  setFormErrors({ ...formErrors, way: false });
                  setDropDownss({ ...dropDownss, way: selectedOption.target.value });
                }}
                placeholder="Select Pickup/Drop"
              >
                {staticOptions.map((option, index) => (
                  <option key={option.id} value={option.name} disabled={option.id === 0}>
                    {option.name}
                  </option>
                ))}
              </select>

              {formErrors.way && (
                <div className="error-message">Please Select Pickup/drop.</div>
              )}
            </Col>

            <Col xs={12} md={6} lg={2} className="rowCss" >
              <TextInputField
                name="Distance"
                textType="Alphabet"
                value={dropDownss.distance || ''}
                onChange={(event) => {
                  const { value } = event.target;
                  setFormData({ ...formData, distance: Number(value) });
                  setFormErrors({ ...formErrors, distance: false });
                  setDropDownss({ ...dropDownss, distance: value });
                }}
                required
                placeholder="Distance*"
                validated={validated}
                feedbackMessage="Please enter a distance."
                validationRegex={validationRegexAmount}
                validationErrorMessage="Please enter a valid distance."
                maxLength={100}
              />
            </Col>
          </>
        ) : (
          null
        )}
      </Row>

      <Row>
        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <SearchableDropDownField
            isSearchable
            value={formData.programId ? { id: formData.programId.id, label: formData.programId.label } : null}
            options={playSchool.map((program) => ({ id: program.id, label: program.name, value: program.id }))}
            onChange={(selectedOption) => {
              setFormData({ ...formData, programId: selectedOption });
              setFormErrors({ ...formErrors, programId: !selectedOption });
            }}
            placeholder="Select Program*"
          />
          {formErrors.programId && (
            <div className="error-message">
              Please select a program.
            </div>
          )}

        </Col>
        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <SearchableDropDownField
            isSearchable
            value={formData.classId ? { id: formData.classId.id, label: formData.classId.label } : null}
            // options={classes.map((program) => ({ id: program.id, label: program.name, value: program.id }))}
            options={classOptions}
            onChange={(selectedOption) => {
              setFormData({ ...formData, classId: selectedOption });
              setFormErrors({ ...formErrors, classId: !selectedOption });
            }}
            placeholder="Select Class*"
          />
          {formErrors.classId && (
            <div className="error-message">
              Please select a class.
            </div>
          )}
        </Col>

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <Select
            isSearchable
            validated={validated}
            value={formData.mindSet ? { label: formData.mindSet.label, value: formData.mindSet.value } : null}
            options={defaultMindSetOptions}
            onChange={(selectedOption) => {
              setFormData({ ...formData, mindSet: { label: selectedOption.label, value: selectedOption.value } });
              setFormErrors({ ...formErrors, mindSet: !selectedOption });
            }}
            placeholder="Select Mind Set Color*"
            styles={{
              singleValue: (provided, state) => {
                const color = state.data.label === 'Positive' ? 'green' : state.data.label === 'Negative' ? 'red' : state.data.label === "Follow-Up" ? 'orange' : 'black';
                return { ...provided, color };
              },
              option: (provided, state) => {
                const color = state.data.label === 'Positive' ? 'green' : state.data.label === 'Negative' ? 'red' : state.data.label === "Follow-Up" ? 'orange' : 'black';
                return { ...provided, color };
              },
            }}
          />
          {formErrors.mindSet && (
            <div className="error-message">
              Please select a mind set.
            </div>
          )}
        </Col>
      </Row>

      <Row>
        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          {/* <Col></Col> */}

          <AddressField
            // name="Address*"
            textarea
            rows={1}
            // label="address*"
            address={formData.address}
            onChange={(e) =>
              setFormData({ ...formData, address: e.target.value })
            }
            validated={validated}
            feedbackMessage="Please enter a address."
            validationRegex={validationRegexAlphaNumeric}
          />
        </Col>

        <Col xs={12} md={6} lg={3} className="rowCss">
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            placeholder="How did you hear about us"
          />
          {selectedOption && (selectedOption.value === 'Referral' || selectedOption.value === 'Others') && (
            <input
              className="select-dropdown"
              type="text"
              value={otherValue}
              validated={validated}
              onChange={handleInputChange}
              onKeyPress={handleInputKeyPress}
              placeholder={selectedOption.value === 'Others' ? 'Enter Other' : ''}
            />
          )}
          {otherValueError && <div className="error-message">{otherValueError}</div>}
          {formErrors.referral && !selectedOption && (
            <div className="error-message">Please select how you heard about us.</div>
          )}
        </Col>

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <TextInputField
            name="Special Comments"
            textType="Alphabet"
            value={formData.specialComments || ""}
            onChange={(event) => {
              setFormData({ ...formData, specialComments: event.target.value, description: event.target.value });
            }}
            required
            placeholder="Special Comments"
            // validated={validated}
            // feedbackMessage="Please enter a special comments."
            validationRegex={validationRegexAddress}
            // validationErrorMessage="Please enter a valid special comments."
            maxLength={100}
          />
        </Col>
        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <DatePicker
            name="Expected Date Of Joining"
            textType="date"
            value={formData.expectedDateOfJoining || null}
            onChange={(date) => {
              setFormData({ ...formData, expectedDateOfJoining: date });
            }}
            required
            placeholder="Expected Date Of Joining"
            validated={validated}
            // feedbackMessage="Please enter a expected date of joining."
            validationRegex={validationRegexOnlyAlpha1}
            // validationErrorMessage="Please enter a valid expected date of joining."
            maxLength={100}
          />
          {/* {formErrors.expectedDateOfJoining && (
            <div className="error-message">Please enter an expected date of joining.</div>
          )} */}
        </Col>

      </Row>

      <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
      <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
      <div
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <button
          className="clear-button-figma-div"
          type="button"
          onClick={handleCancel}
        >
          {" "}
          Clear{" "}
        </button>
        <button className="save-button-figma-div" type="submit">
          Submit
        </button>
      </div>
    </Form >
  );
};

export default EnquiryEntry;