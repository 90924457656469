// PDFGenerator.js
import React from 'react';
import { Font, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import logo1 from '../../images/logo1.jpeg';
import { ToWords } from 'to-words'

Font.register({
  family: 'Helvetica',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/helvetica/v11/KFOkCnqEu92Fr1Me5Q.ttf' }, // Regular
    { src: 'https://fonts.gstatic.com/s/helvetica/v11/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', fontWeight: 'bold' }, // Bold
  ],
});

// Define styles for your PDF document
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  leftText: {
    textAlign: 'left',
    fontWeight: 'extrabold', // Bolder style
    fontSize: 14, // Increase font size for emphasis
  },
  logo1: {
    width: 200,
    height: 'auto',
  },
  titleContainer: {
    alignItems: 'center',
    marginVertical: 10,
  },
  title: {
    // fontFamily: 'Helvetica',
    fontWeight: 'extrabold',
    // color: 'black',
    fontSize: 14,
  },
  titleBold: {
    fontWeight: '400',  // Apply bold font weight to the title
    fontSize: 14,        // You can adjust font size if needed
  },
  underline: {
    width: '20%',
    height: 1,
    backgroundColor: 'black',
    marginTop: 2,
  },

  feeSection: {
    marginVertical: 10,
  },
  feeRow: {
    flexDirection: 'row',
    borderBottom: '1px solid black',
  },
  feeHeader: {
    flexDirection: 'row',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
  },
  col: {
    width: '20%',
    textAlign: 'center',
    padding: 5,
    borderLeft: '1px solid black',
  },
  colWide: {
    width: '40%',
    textAlign: 'center',
    padding: 5,
    borderLeft: '1px solid black',
  },
  footerText: {
    fontSize: 8,
    textAlign: 'center',
    position: 'absolute',
    bottom: 20, // Distance from the bottom edge of the page
    left: 0,
    right: 0,
  },

  horizontalLine: {
    borderBottom: '1px solid black',
    marginVertical: 5,
  },
  // Modify the dottedLine style to control its width
  dottedLine: {
    borderBottom: '1px dashed black',
    width: '100%', // Full width under the receipt number
    marginTop: 2, // Small space above dotted line
  },

  textLabel: {
    fontWeight: 'extrabold',
    fontSize: 10,
    marginRight: 3, // Add slight spacing between labels and values
  },
  textValue: {
    marginRight: 5, // Spacing after each value
    marginLeft: 5,
    fontSize: 10,
    fontWeight: 'extrabold',
  },
  infoSection: {
    marginVertical: 14,
    textAlign: 'center', // Center-aligns any text within infoSection
  },
  infoSection1: {
    // marginVertical: 2,
    textAlign: 'center', // Center-aligns any text within infoSection
  },
  colLeft: {
    width: '40%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  colRight: {
    width: '60%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    marginBottom: '8px',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap', // Enables text to wrap when needed, helps keep alignment intact
  },
  stack: {
    flexDirection: 'column', // Stack items vertically
    alignItems: 'center', // Center align items
    marginRight: 10, // Add margin to separate from the next element in the row
    paddingVertical: 5,
  },
  lastCol: {
    width: '20%',
    textAlign: 'center',
    padding: 5,
    fontSize: 10,
    fontWeight: 'bold',
    borderLeft: '1px solid black',
    borderRight: '1px solid black', // Ensures right border for the last column
  },
  centeredText: {
    fontSize: 14, // Adjust font size if needed
    textAlign: 'center', // Center alignment
    fontWeight: 'bold', // Makes the text bold
    marginTop: 5,
  },
  centeredText1: {
    fontSize: 10, // Adjust font size if needed
    textAlign: 'center', // Center alignment
    fontWeight: 'bold', // Makes the text bold
    marginTop: 5,
  },
});

const toWords = new ToWords({
  localeCode: 'en-IN',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: { // can be used to override defaults for the selected locale
      name: 'Rupee',
      plural: 'Rupees',
      symbol: '₹',
      fractionalUnit: {
        name: 'Paisa',
        plural: 'Paise',
        symbol: '',
      },
    }
  }
});

// PDF Document Component with Receipt Layout
const MyPDFDocument = ({ info }) => (

  <Document>
    <Page size="A4" style={styles.page}>
      {/* Header Section */}
      <View style={styles.header}>
        <View>
          <Text style={styles.leftText}>{info?.franchiseeSchool?.name || ''}</Text>
          {/* <Text style={styles.leftText}>KLAY Divyashree Orion</Text> */}
          <Text style={styles.leftText}>{info?.revenueType?.name === 'Daycare Fees' ? 'GSTIN 36AADCV3421G2ZJ' : ''}</Text>
        </View>
        <View style={styles.header}>
          <Image style={styles.logo1} src={logo1} />
        </View>
      </View>

      {/* Title Section with Underline */}
      <View style={styles.titleContainer}>
        <Text style={styles.titleBold}>FEE RECEIPT</Text>
        <View style={styles.underline} />
      </View>

      <View style={styles.infoSection}>
    
        <View style={styles.row}>
          <Text style={styles.textLabel}>Receipt No.</Text>
          <View style={styles.stack}>
            <Text style={styles.textValue}>{info?.receiptNo || ''}</Text>
            <View style={styles.dottedLine} />
          </View>

          <Text style={styles.textLabel}> Date </Text>
          <View style={styles.stack}>
            <Text style={styles.textValue}>{info?.date || ''}</Text>
            <View style={styles.dottedLine} />
          </View>

          <Text style={styles.textLabel}> Enrollment ID </Text>
          <View style={styles.stack}>
            <Text style={styles.textValue}>{info?.student?.studentEnrollments?.enrollmentId || ''}</Text>
            <View style={styles.dottedLine} />
          </View>
          <Text style={styles.textLabel}>Received with thanks from </Text>
          <View style={styles.stack}>
            <Text style={styles.textValue}>
              {info?.student?.guardians?.[0]?.name || ''}
              {info?.student?.guardians?.length > 1 && info.student.guardians[1].name ? '/' + info.student.guardians[1].name : ''}
            </Text>
            <View style={styles.dottedLine} />
          </View>
          <Text style={styles.textLabel}> for </Text>
          <View style={styles.stack}>
            <Text style={styles.textValue}>{info?.student?.name || ''}</Text>
            <View style={styles.dottedLine} />
          </View>
          <Text style={styles.textLabel}>the sum of Rupees </Text>
          <View style={styles.stack}>
            <Text style={styles.textValue}>{info?.feeAmount ? toWords.convert(info.feeAmount) : ''}</Text>
            <View style={styles.dottedLine} />
          </View>
          <Text style={styles.textLabel}>by </Text>
          <View style={styles.stack}>
            <Text style={styles.textValue}>{(info?.modeOfPayment) || ''}</Text>
            <View style={styles.dottedLine} />
          </View>
          <Text style={styles.textLabel}>For Programme </Text>
          <View style={styles.stack}>
            <Text style={styles.textValue}>{info?.student?.studentEnrollments?.class?.name || ''}</Text>
            <View style={styles.dottedLine} />
          </View>
          <Text style={styles.textLabel}>Section </Text>
          <View style={styles.stack}>
            <Text style={styles.textValue}>{info?.student?.studentEnrollments?.section?.name || ''}</Text>
            <View style={styles.dottedLine} />
          </View>
          <Text style={styles.textLabel}>.</Text>
        </View>

      </View>



      {/* Fee Section with vertical lines for table */}
      <View style={styles.feeSection}>
        {/* Table Header Row */}
        <View style={styles.feeHeader}>
          <Text style={styles.colWide}>Fee Breakup</Text>
          <Text style={styles.col}>Duration (From - To)</Text>
          <Text style={styles.col}>Actual Amount</Text>
          <Text style={styles.col}>CGST</Text>
          <Text style={styles.col}>SGST</Text>
          <Text style={styles.lastCol}>Total Amount</Text>
        </View>

        {/* Table Data Row */}
        <View style={styles.feeRow}>
          <Text style={styles.colWide}>{info?.revenueType?.name || ''}</Text>
          <Text style={styles.col}>?</Text>
          <Text style={styles.col}>{info?.feeAmount ? (Number(info.feeAmount) - (Number(info.feeAmount) * 16 / 100)).toFixed(2) : 0}</Text>
          <Text style={styles.col}>{info?.feeAmount ? (Number(info.feeAmount) * 8 / 100).toFixed(2) : 0}</Text>
          <Text style={styles.col}>{info?.feeAmount ? (Number(info.feeAmount) * 8 / 100).toFixed(2) : 0}</Text>
          <Text style={styles.lastCol}>{info?.feeAmount}</Text>
        </View>

        {/* Total Row */}
        <View style={styles.feeRow}>
          <Text style={styles.colWide}>Total</Text>
          <Text style={styles.col}></Text>
          <Text style={styles.col}>{info?.feeAmount ? (Number(info.feeAmount) - (Number(info.feeAmount) * 16 / 100)).toFixed(2) : 0}</Text>
          <Text style={styles.col}>{info?.feeAmount ? (Number(info.feeAmount) * 8 / 100).toFixed(2) : 0}</Text>
          <Text style={styles.col}>{info?.feeAmount ? (Number(info.feeAmount) * 8 / 100).toFixed(2) : 0}</Text>
          <Text style={styles.lastCol}>{info?.feeAmount}</Text>
        </View>
      </View>


      <View style={styles.infoSection}>
        <Text style={styles.centeredText}>Rupees in words: {info?.feeAmount ? toWords.convert(info?.feeAmount) : ''}</Text>
      </View>
      <View style={styles.infoSection1}>
        <Text style={styles.centeredText1}>(Pay order/Cheque/DD are subject to realization, Amount once paid is not refundable)</Text>
      </View>
      <View style={styles.infoSection}>
        <Text style={styles.centeredText}>THIS IS SYSTEM GENERATED RECEIPT, NO SIGNATURE IS REQUIRED</Text>
      </View>


      {/* Footer Text */}
      <View style={styles.footerText}>
        <View style={styles.horizontalLine} />
        <View style={styles.infoSection}>
          <Text style={styles.centeredText}>Founding Years Learning Solutions Pvt. Ltd.</Text>
        </View>

        <Text>Registered Office Address: No. 38/A2, Doddanakundi Industrial Area, Whitefield Road, Mahadevapura, Krishnarajapuram Hobli, Bangalore – 560048.</Text>
        <Text>CIN number : U80301KA2010PTC053882</Text>
      </View>
    </Page>
  </Document>
);


export default MyPDFDocument;
