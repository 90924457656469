/*
    authors: Mamatha
    date: 01-11-2024
    update: 01-11-2024
    version: 1.0
*/


import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import SelectDropdown from "../SelectDropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataOnce } from "./slice/viewEventsAndHolidaysSlice";
import { postEventStatusRequest, setShowErrorMessage, setShowSuccessMessage } from "./slice/eventStatusSlice";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import Table from "../reUsableForm/table/table";
import { fetchViewEventStatusDataOnce } from "./slice/viewEventStatusSlice";
import { setShowErrorMessageUpdated, setShowSuccessMessageUpdated, updateEventStatusView } from "./slice/eventStatusPutSlice";

const EventStatus = () => {
  const [dropDowns, setDropDowns] = useState({});
  const [isConducted, setIsConducted] = useState(false);
  const apiData = useSelector((state) => state.viewEventsAndHolidays.data) || [];
  const postData = useSelector((state) => state.eventAndHolidays.data) || [];
  const combinedData = [...(apiData || []), ...postData];

  const [showEditModal, setShowEditModal] = useState(false);
  const [editedData, setEditedData] = useState({});

  const [editIsUpdate, setEditIsUpdate] = useState(false);

  const columns = [
    { field: "index", header: "Sr.No" },
    { field: "eventHolidayName", header: "Event Name" },
    { field: "isConductedValue", header: "Has Conducted" },
    { field: "conductedFromDate", header: "Conducted From Date" },
    { field: "conductedToDate", header: "Conducted To Date" },
    { field: 'edit', header: 'Edit' },
  ];

  const apiViewData = useSelector((state) => state.viewEventStatus?.data) || [];
  const postViewData = useSelector((state) => state.eventStatus?.data) || [];
  const putViewData = useSelector((state) => state.eventStatusViewEdit?.data) || [];


  const combinedViewData = [...apiViewData, ...postViewData];

  // Step 1: Remove duplicates in combinedViewData by id
  const uniqueCombinedData = Array.from(
    new Map(combinedViewData.map(item => [item.id, item])).values()
  );

  // Step 2: If putViewData is not empty, create a map to update items
  const putDataMap = new Map(putViewData.map(item => [item.id, item]));

  // Step 3: Merge uniqueCombinedData with putDataMap if there are updates
  const mergedData = uniqueCombinedData.map(item => {
    return putDataMap.has(item.id) ? { ...item, ...putDataMap.get(item.id) } : item;
  });

  // If putViewData has items not in uniqueCombinedData, add them
  const additionalItems = putViewData.filter(item =>
    !uniqueCombinedData.some(existingItem => existingItem.id === item.id)
  );

  const finalData = [...mergedData, ...additionalItems];




  const processedData = finalData.map((row, index) => ({
    ...row,
    index: index + 1,
    isConductedValue: row.isConducted === true ? 'Yes' : 'No',
    eventHolidayName: row.eventHoliday?.name || ''
  }));


  const [formData, setFormData] = useState({
    eventHolidayId: null,
    isConducted: false,
    conductedFromDate: "",
    conductedToDate: "",
  });

  const [formErrors, setFormErrors] = useState({
    event: false,
    conductedFromDate: false,
    conductedToDate: false,
  });

  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataOnce());
  }, [dispatch]);

  useEffect(() => {
    if (apiViewData.length == 0) {
      dispatch(fetchViewEventStatusDataOnce());
    }
  }, [dispatch, apiViewData]);

  const showSuccessMsg = useSelector((state) => state.eventStatus.showSuccessMessage);
  const successMsg = useSelector((state) => state.eventStatus.successMessage);

  const showErrorMsg = useSelector((state) => state.eventStatus.showErrorMessage);
  const errorMsg = useSelector((state) => state.eventStatus.errorMessage);

  const showSuccessMsg_put = useSelector((state) => state.eventStatusViewEdit.showSuccessMessage);
  const successMsg_put = useSelector((state) => state.eventStatusViewEdit.successMessage);

  const showErrorMsg_put = useSelector((state) => state.eventStatusViewEdit.showErrorMessage);
  const errorMsg_put = useSelector((state) => state.eventStatusViewEdit.errorMessage);

  const calculateDay = (date) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[new Date(date).getDay()];
  };

  const handleUpdate = (id, data) => {
    const updatedData = {
      // "date": convert_DD_MM_YYYY_to_YYYY_MM_DD(data.date),
      "eventHolidayId": data.eventHolidayId,
      "isConducted": data.isConducted,
      "conductedFromDate": data.conductedFromDate,
      "conductedToDate": data.conductedToDate,
    }
    dispatch(updateEventStatusView(id, updatedData))
    setShowEditModal(true);
    setEditIsUpdate(true);

  }

  const [selectEventName, setSelectEventName] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newFormData = {
      ...formData,
      conductedFromDate: formData.conductedFromDate || null,
      conductedToDate: formData.conductedToDate || null,
      eventHolidayName: selectEventName

    };
    const isEventValid = !!newFormData.eventHolidayId;
    const isFromDateValid = isConducted ? !!newFormData.conductedFromDate : true;
    const isToDateValid = isConducted ? !!newFormData.conductedToDate : true;

    // Set form errors based on validation
    setFormErrors({
      event: !isEventValid,
      conductedFromDate: !isFromDateValid,
      conductedToDate: !isToDateValid,
    });

    // If all fields pass validation, submit the form
    if (isEventValid && isFromDateValid && isToDateValid) {
      setValidated(true);
    } else {
      setValidated(false);
    }
    dispatch(postEventStatusRequest(newFormData));
  };


  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  const handleCloseUpdatedSuccessPopup = () => {
    dispatch(setShowSuccessMessageUpdated(false));
    setShowEditModal(false);
    setEditIsUpdate(false);
    // handleCancel();
  };

  const handleCloseUpdatedErrorPopup = () => {
    dispatch(setShowErrorMessageUpdated(false));
  };

  const handleClear = () => {
    setFormData({
      eventHolidayId: null,
      conductedFromDate: "",
      conductedToDate: "",
      isConducted: false
    });
    setDropDowns({
      eventHolidayId: null // Reset the dropdown state
    });
    setIsConducted(false);
    setFormErrors({ event: false, conductedFromDate: false, conductedToDate: false });
    setValidated(false);
  };

  const sendColumns = [
    // {
    //   field: 'eventHolidayName',
    //   header: 'Event Name',
    //   body: (rowData) => (
    //     <p>{rowData.eventHolidayName || "N/A"}</p> // Display as text, non-editable
    //   ),
    // },
    {
      field: 'isConducted',
      header: 'Has Conducted',
      editor: (options) => (
        <div>
          <label>
            <input
              type="radio"
              name={`isConducted-${options.rowIndex}`}
              value="true"
              checked={!!options.value} // This will check "Yes" when true
              onChange={() => options.editorCallback(true)}
            />
            Yes
          </label>
          <label style={{ marginLeft: '1rem' }}>
            <input
              type="radio"
              name={`isConducted-${options.rowIndex}`}
              value="false"
              checked={!options.value} // This will check "No" when false
              onChange={() => options.editorCallback(false)}
            />
            No
          </label>
        </div>

        // <>
        //   {console.log("jhjh", options)}
        // </>

      ),
      body: (rowData) => (
        <span>{rowData.isConducted ? 'Yes' : 'No'}</span>
      ),
    },
    {
      field: 'conductedFromDate',
      header: 'Conducted From Date',
      editor: (options) => (
        <Form.Control
          type="date"
          value={options.value || ""}
          // max={options.value || undefined}
          onChange={(event) => {
            const newDate = event.target.value;
            options.editorCallback(newDate);
          }}
        />
      ),
      body: (rowData) => (
        <span>{rowData.conductedFromDate ? rowData.conductedFromDate : "N/A"}</span>
      ),
    },
    {
      field: 'conductedToDate',
      header: 'Conducted To Date',
      editor: (options) => (
        <Form.Control
          type="date"
          value={options.value || ""}
          // min={options.value || undefined}
          onChange={(event) => {
            const newDate = event.target.value;
            options.editorCallback(newDate);
          }}
        />
      ),
      body: (rowData) => (
        <span>{rowData.conductedToDate ? rowData.conductedToDate : "N/A"}</span>
      ),
    },

  ]

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Container className="p-4" style={{ maxWidth: "800px", backgroundColor: "#f5f5f5", borderRadius: "8px" }}>
          <div className="content-header mb-4" style={{ fontSize: "1.5em", fontWeight: "bold" }}>Event Status</div>
          <Row className="align-items-center">
            {/* Event Selection Dropdown */}
            <Col xs={12} md={6} className="mb-3">
              <Form.Label>Event</Form.Label>
              <SelectDropdown
                value={dropDowns.eventHolidayId ? { value: dropDowns.eventHolidayId, label: apiData.find((event) => event.id === dropDowns.eventHolidayId)?.name || "Select an event" } : null}
                className="input-style"
                options={apiData.filter(item => item.type === "event").map((option) => ({
                  id: option.id,
                  value: option.name,
                  label: option.name,
                }))}
                onChange={(selectedOption) => {
                  setFormData({
                    ...formData,
                    eventHolidayId: selectedOption.id,
                  });
                  setDropDowns({
                    ...dropDowns,
                    eventHolidayId: selectedOption.id,
                  });
                  setFormErrors({
                    ...formErrors,
                    event: false,
                  });
                  setSelectEventName(selectedOption.value)
                }}
                placeholder="Select an event"
              />
              {formErrors.event && <div className="error-message">Please select an event.</div>}
            </Col>

            {/* Checkbox for "Has conducted" */}
            <Col xs={12} md={3} className="mb-3 d-flex align-items-center">
              <Form.Check
                type="checkbox"
                label="Has conducted"
                checked={isConducted}
                onChange={(e) => {
                  setIsConducted(e.target.checked);
                  setFormData((prevData) => ({
                    ...prevData,
                    isConducted: e.target.checked,
                  }));
                }}
                style={{ transform: "scale(1.2)", fontSize: "1em", marginLeft: "1rem" }}
              />
            </Col>

            {/* From Date Field */}
            <Col xs={12} md={6} className="mb-3">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="From Date"
                value={formData.conductedFromDate}
                onChange={(event) => {
                  const selectedDate = event.target.value;
                  setFormData((prevData) => ({
                    ...prevData,
                    conductedFromDate: selectedDate,
                    conductedToDate: prevData.conductedToDate && selectedDate > prevData.conductedToDate ? "" : prevData.conductedToDate,
                  }));
                }}
                isInvalid={formErrors.conductedFromDate}
                max={formData.conductedToDate || undefined} // Prevents From Date > To Date
              />
              <div>From Day: {formData.conductedFromDate && calculateDay(formData.conductedFromDate)}</div>
              <Form.Control.Feedback type="invalid">Please select a valid From Date.</Form.Control.Feedback>
            </Col>

            {/* To Date Field */}
            <Col xs={12} md={6} className="mb-3">
              <Form.Label>To Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="To Date"
                value={formData.conductedToDate}
                onChange={(event) => {
                  const selectedDate = event.target.value;
                  setFormData((prevData) => ({
                    ...prevData,
                    conductedToDate: selectedDate,
                  }));
                }}
                isInvalid={formErrors.conductedToDate}
                min={formData.conductedFromDate || undefined} // Sets min to From Date
              />
              <div>To Day: {formData.conductedToDate && calculateDay(formData.conductedToDate)}</div>
              <Form.Control.Feedback type="invalid">Please select a valid To Date.</Form.Control.Feedback>
            </Col>
          </Row>
          <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
          <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "2%",
              marginTop: "2%"
            }}
          >
            <button className="clear-button-figma-div" type="button" onClick={handleClear}>Clear</button>
            <button className="save-button-figma-div" type="submit">Submit</button>
          </div>
        </Container>
      </Form>
      <div className="App">
        {processedData.length > 0 ? (
          <Table
            data={processedData}
            columns={columns}
            // hover={true}
            // striped={true}
            colFields={sendColumns}
            showEditModal={showEditModal}
            setShowEditModal={setShowEditModal}
            editedData={editedData}
            setEditedData={setEditedData}
            handleUpdate={handleUpdate}
          />
        ) : (
          <div>No data available</div>
        )}
      </div>
      <SuccessMessagePopup show={showSuccessMsg_put} onClose={handleCloseUpdatedSuccessPopup} successMessage={successMsg_put} />
      <ErrorMessagePopup show={showErrorMsg_put} onClose={handleCloseUpdatedErrorPopup} errorMessage={errorMsg_put} />

    </>
  );
};

export default EventStatus;


