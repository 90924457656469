/*
    authors: Naveen
    date: 31-10-2023
    update:31-10-2023 
    version: 1.0
*/

import React, { useEffect } from "react";
import { fetchDataOnce } from "./slice/viewTransportationSlice";
import { useDispatch, useSelector } from "react-redux";
import Table from "../reUsableForm/table/table";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";

export const ViewTransportationFeeCollection = () => {
    const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId')
    const FranchiseeSchoolId = Number(franchiseeSchoolId);

    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(fetchDataOnce());
        dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
    }, [dispatch]);

    const studentData = useSelector((state) => state.viewStudent.data) || [];

    const apiData = useSelector((state) => state.viewTransportationFeeCollection.data) || [];
    const postData = useSelector((state) => state.transportationCollectFee.data) || [];


    const combinedData = [...(apiData || []), ...postData];

    let mergedData = []

    combinedData.map((item, index) => {
        const studentInfo = studentData ? studentData.find(student => student.id === item.studentId) : null;

        mergedData = [
            ...mergedData,
            {
                ...item,
                index: index + 1,
                name: studentInfo?.name,
                class: studentInfo?.studentEnrollments[0]?.classesClassSection?.class?.name,
                section: studentInfo?.studentEnrollments[0]?.classesClassSection?.classSection?.name || '',
                feeOption: item.feeOption === 'monthlyFee' ? "Monthly" : item.feeOption === 'bimonthlyFee' ? "Bi Monthly" : item.feeOption == 'dayFee' ? "Day" : "",
                pickupOption: item.pickupOption === 'PickAndDrop' ? "Pick & Drop" : "Pick/Drop",
            }
        ]

    });


    const columns = [
        { field: "index", header: 'Sr.No' },
        { field: "name", header: 'Student Name' },
        { field: "class", header: 'Class' },
        { field: "section", header: 'Section' },
        { field: "feeOption", header: 'Fee Option' },
        { field: "actualDistance", header: 'Distance' },
        { field: "pickupOption", header: 'Pick/Drop' },
        { field: "fee", header: 'Fee Due' },
        { field: "paidFee", header: 'Received' },
        { field: "dueFee", header: 'Balance' },
    ]

    return (
        <>
            {mergedData === null ? (
                <center><p>Loading...</p></center>
            ) : mergedData.length === 0 ? (
                <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
            ) : (
                <Table data={mergedData} columns={columns} hover={true} />
            )}

        </>
    )
}