/*
    authors: Rajeev
    date: 20-12-2023
    update:20-12-2023 
    version: 1.0
*/

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from "../../../Api/api";
import { token } from '../../../Api/token';



const slice = createSlice(
  {
    name: 'viewAssessmentParameter',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchAssessmentParameterStart: (state) => {
        state.isLoading = true;
        state.hasError = false;
      },
      fetchAssessmentParameterSuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchAssessmentParameterFailure: (state) => {
        state.isLoading = false;
        state.hasError = true;
      }
    },
  },

);

export const { fetchAssessmentParameterStart, fetchAssessmentParameterSuccess, fetchAssessmentParameterFailure } = slice.actions;

export const fetchAssessmentParameterOnce = () => async (dispatch, getState) => {

  const { data, isLoading } = getState().viewAssessmentParameter;

  const tokenId = token();

  if (data === null && !isLoading) {
    // If data is null and the API is not loading, trigger the API request
    dispatch(fetchAssessmentParameterStart());

    try {
      // Make your API request here using the fetch function
      const response = await fetch(API_PATH + "/assessment-parameters", {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${tokenId}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch assessment parameters');
      }

      const data1 = await response.json();

      // Check if data1 has a valid URL
      if (!data1?.url) {
        throw new Error('No valid URL found in the response');
      }

      // Make a second request using the URL from the first response
      const resp = await fetch(data1.url);

      if (!resp.ok) {
        throw new Error('Failed to fetch data from the provided URL');
      }

      const data = await resp.json();
      dispatch(fetchAssessmentParameterSuccess(data));

    } catch (error) {
      console.error('Error fetching assessment parameters:', error);
      // Handle errors and dispatch the failure action
      dispatch(fetchAssessmentParameterFailure());
    }

  }

}

export default slice.reducer;
