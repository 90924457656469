import React, { useEffect, useState } from 'react';
import { getParentLoginData } from './parentLoginSlicer/parentLoginRequests';
import { useDispatch, useSelector } from 'react-redux';
import ParentProfileData from './ParentProfileData';
import "./parent.css";
import ChildrenProfileData from './ChildrenProfileData';
import { Avatar, Card, Col, Row, Typography } from 'antd';
import ProgressComponent from './ProgressComponent';

const { Title, Text } = Typography;
const ParentLogin = () => {

    const dispatch = useDispatch();
    const [navigateState, setNavigateState] = useState(true);
    const parentLoginObject = useSelector((state) => state.parentLoginReducer.getParentLoginData);


    const data = parentLoginObject?.data;

    const currentAddress = data?.parent?.currentAddress?.address + ', ' + data?.parent?.currentAddress?.area + ', ' + data?.parent?.currentAddress?.city + ', ' + data?.parent?.currentAddress?.state + ', ' + data?.parent?.currentAddress?.pincode;

    const image = data?.parent?.profilePictureUrl || 'https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI='

    const data1 = typeof parentLoginObject?.data === 'object' ? parentLoginObject?.data : {};
    const students = Object.keys(data1)?.length > 0 ? data?.childrens : []


    useEffect(() => {
        if (parentLoginObject?.loading === "idle") {
            const parentId = sessionStorage.getItem('userId');
            dispatch(getParentLoginData(parentId))
        }
    }, [dispatch, parentLoginObject?.loading]);

    const uniqueClasses = [
        ...new Set(students?.flatMap(student => student.studentEnrollments.map(enrollment => enrollment.class)))
    ];
    
    sessionStorage.setItem("studentsClasses", JSON.stringify(uniqueClasses));

    let renderingData = null;
    if (navigateState) { renderingData = (<ParentProfileData />) } else { renderingData = (<ChildrenProfileData />) }
    // try {

    //     return (
    //         <div className='profile-wrapper'>
    //             <div className='profile-buttons-wrapper'>
    //                 <span onClick={() => setNavigateState(true)}
    //                     className={`${navigateState ? 'parent-btn' : ''}`} >Parent</span>
    //                 <span onClick={() => setNavigateState(false)}
    //                     className={`${!navigateState ? 'child-btn' : ''}`}
    //                 >Children</span>
    //             </div>
    //             <div className='profile-data-wrapper'>
    //                 {renderingData}
    //             </div>

    //         </div>
    //     )
    // } catch (err) {
    //     return (
    //         <>
    //             <div>Something Error Occured!</div>
    //         </>
    //     )
    // }


    function calculateAge(dateOfBirth) {
        const dob = new Date(dateOfBirth);
        const currentDate = new Date();

        let age = currentDate.getFullYear() - dob.getFullYear();
        const dobMonth = dob.getMonth();
        const currentMonth = currentDate.getMonth();

        if (currentMonth < dobMonth || (currentMonth === dobMonth && currentDate.getDate() < dob.getDate())) {
            age--;
        }

        return age;
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }
    return (
        <>
            <Card style={{ width: '100%', backgroundColor: 'transparent', border: 'none' }}>
                <Row align="middle" gutter={[16, 16]}>
                    <Col xs={24} sm={8} md={6} lg={4}>
                        {image ? <Avatar size={140} src={image} /> : null}
                    </Col>
                    <Col xs={24} sm={16} md={10} lg={10}>
                        <Title level={4} style={{ margin: 0 }}>{data.parent?.name || ''}</Title>
                        <Text><strong>Email:</strong> {data.parent?.email || ''}</Text><br />
                        <Text><strong>Contact Number:</strong> {`${data.parent?.primaryContactNumber ? data.parent?.primaryContactNumber : ''} ${data.parent?.secondaryContactNumber ? `,${data.parent?.secondaryContactNumber}` : ''}`}</Text><br />
                        <Text><strong>Address:</strong> {currentAddress ? currentAddress : ''}</Text>
                    </Col>
                    <Col xs={24} sm={16} md={8} lg={10}>
                        <Title level={4} style={{ margin: 0 }}>{data.relation?.name || ''}</Title>
                        <Text><strong>Email:</strong> {data.relation?.email || ''}</Text><br />
                        <Text><strong>Contact Number:</strong> {`${data.relation?.primaryContactNumber ? data.relation?.primaryContactNumber : ''} `}</Text><br />
                    </Col>
                </Row>
            </Card>

            {students?.length > 0 ? students?.map((eachChild, i) => (
                <Card key={i} style={{ width: '100%', backgroundColor: 'transparent', border: 'none' }}>
                    <Row align="middle" gutter={[16, 16]}>
                        <Col xs={24} sm={8} md={6} lg={4}>
                            {eachChild?.profilePictureUrl ? (
                                <Avatar size={120} src={eachChild.profilePictureUrl} />
                            ) : (
                                <Avatar size={120} src='https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=' />
                            )}
                        </Col>
                        <Col xs={24} sm={16} md={10} lg={4}>
                            <Title level={4} style={{ margin: 0 }}>{eachChild.name || ''}</Title>
                            <Text><strong>DOB:</strong> {formatDate(eachChild.dateOfBirth)}</Text><br />
                            <Text><strong>Gender:</strong> {eachChild.gender}</Text>
                        </Col>
                        <Col flex='1' xs={24} sm={16} md={8} lg={5}>
                            {eachChild.studentEnrollments?.length > 0 ? (
                                <>
                                    <Text><strong>Class:</strong> {eachChild.studentEnrollments[0]?.class || ''}</Text><br />
                                    <Text><strong>Section:</strong> {eachChild.studentEnrollments[0]?.section || ''}</Text><br />
                                </>
                            ) : (
                                <Text>No Enrollment Data</Text>
                            )}
                        </Col>
                        <Col xs={24} sm={8} md={6} lg={4} style={{ textAlign: 'center' }}>
                            <ProgressComponent eachChildData={eachChild} />
                        </Col>
                    </Row>
                </Card>
            )) : (
                <p style={{ textAlign: 'center', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</p>
            )}
        </>
    )
}

export default ParentLogin;
