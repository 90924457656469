/*
    authors: Naveen
    date: 27-11-2023
    update:27-11-2023 
    version: 1.0
*/

import React, { useEffect, useState } from "react";
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { TimePicker } from "antd";
import { PostAllocationRequest, setShowErrorMessage, setShowSuccessMessage } from "./slice/allocationBusRouteSlice";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeList } from "../Teachers/teacherSlicers/allRequests";
import { fetchAddVehicleView } from "../Administration/Transportation/addVehicleViewSlicer";
import { fetchCreateLaneView } from "./createLineViewSlicer";


export const AllocationBusRoutes = () => {
    const [formData, setFormData] = useState('');
    const [validated, setValidated] = useState(false);
    const [formTime, setFormTime] = useState('');
    const [toTime, setToTime] = useState('');
    const [submit, setSubmit] = useState(false);

    const [formErrors, setFormErrors] = useState({
        formTime: false, toTime: false, laneId: false, vehicleId: false, driverId: false
    })

    const [selected, setSelected] = useState('')

    const dispatch = useDispatch();
    const employeesListStatus = useSelector((state) => state.employeeSlicer?.teachers?.getEmployeeViewList?.loading);

    useEffect(() => {
        if (employeesListStatus === 'idle') {
            dispatch(getEmployeeList())
        }
    }, [dispatch, employeesListStatus])

    const employeesListObj = useSelector((state) => state.employeeSlicer.teachers.getEmployeeViewList);
    const employeesListLoading = useSelector((state) => state.employeeSlicer.teachers.getEmployeeViewList.loading);
    const employeesList = useSelector((state) => state.employeeSlicer.teachers.getEmployeeViewList.data);

    const vehiclesList = useSelector((state) => state.addVehicleView.data) || [];

    const lanesList = useSelector((state) => state.createLane.data) || [];

    useEffect(() => {
        if (employeesListLoading === 'idle') {
            dispatch(getEmployeeList())
        }

    }, [employeesListLoading]);

    useEffect(() => {
        if (vehiclesList.length === 0) {
            dispatch(fetchAddVehicleView())
        }
        if (lanesList.length === 0) {
            dispatch(fetchCreateLaneView())
        }
    }, [dispatch])

    const driversList = employeesList && employeesList?.filter((item) => item.designation === 'driver')



    const showSuccessMsg = useSelector((state) => state.allocationBusRoute.showSuccessMessage);
    const successMsg = useSelector((state) => state.allocationBusRoute.successMessage);

    const showErrorMsg = useSelector((state) => state.allocationBusRoute.showErrorMessage);
    const errorMsg = useSelector((state) => state.allocationBusRoute.errorMessage);

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmit(true);

        const newFormErrors = {
            formTime: !formTime,
            toTime: !toTime,
            laneId: !formData.laneId,
            vehicleId: !formData.vehicleNo,
            driverId: !formData.driverId
        }
        const hasErrors = Object.values(newFormErrors).some(error => error);
        setFormErrors(newFormErrors);
        if (hasErrors) {
            setValidated(true);
            setSubmit(false);
            return;
        }

        const sendData = {
            ...formData,
            pickupTime: formTime,
            dropTime: toTime
        }
        dispatch(PostAllocationRequest(sendData));
        setSubmit(false);
    }

    const onChangeFromTime = (timeString) => {
        setFormTime(timeString)
        setFormErrors({ ...formErrors, formTime: false })
    };
    const onChangeToTime = (timeString) => {
        setToTime(timeString)
        setFormErrors({ ...formErrors, toTime: false });
    };

    const handleCancel = () => {
        setFormData({});
        setFormTime('');
        setToTime('');
        setSelected({})
        setSubmit(false);
    }

    const handleCloseSuccessPopup = () => {
        setSubmit(false);
        dispatch(setShowSuccessMessage(false));
        handleCancel();
    };

    const handleCloseErrorPopup = () => {
        setSubmit(false);
        dispatch(setShowErrorMessage(false));
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="content-header" >Allocation Bus Route</div>
                <Row style={{ margin: '5px' }}>
                    <Col xs={12} md={6} lg={3} className="rowCss">
                        <SearchableDropDownField
                            isSearchable
                            value={selected.driverName ? { label: selected.driverName } : null}
                            options={driversList.map((driver) => ({
                                id: driver.id,
                                label: driver.name,
                                value: driver.id
                            }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, driverId: selectedOption.value });
                                setSelected({ ...selected, driverName: selectedOption.label })
                                setFormErrors({ ...formErrors, driverId: false });
                            }}
                            placeholder="Select Driver Name"
                        />
                        {formErrors.driverId && (
                            <div className="error-message">
                                Please Select one Transportation Way .
                            </div>
                        )}
                    </Col>
                    <Col xs={12} md={6} lg={3} className="rowCss">
                        <SearchableDropDownField
                            isSearchable
                            value={selected.vehicleNo ? { label: selected.vehicleNo } : null}
                            options={vehiclesList.map((vehicle) => ({
                                id: vehicle.id,
                                label: vehicle.vehicleNumber,
                                value: vehicle.vehicleNumber
                            }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, vehicleNo: selectedOption.value });
                                setSelected({ ...selected, vehicleNo: selectedOption.label })
                                setFormErrors({ ...formErrors, vehicleId: !selectedOption });
                            }}
                            placeholder="Select VehicleNo"
                        />
                        {formErrors.vehicleId && (
                            <div className="error-message">
                                Please Select Vehicle  .
                            </div>
                        )}
                    </Col>
                    <Col xs={12} md={6} lg={3} className="rowCss">
                        <SearchableDropDownField
                            isSearchable
                            value={selected.laneName ? { label: selected.laneName } : null}
                            options={lanesList.map((lane) => ({
                                id: lane.id,
                                label: lane.title,
                                value: lane.id
                            }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, laneId: selectedOption.value });
                                setSelected({ ...selected, laneName: selectedOption.label });
                                setFormErrors({ ...formErrors, laneId: !selectedOption });
                            }}
                            placeholder="Select Lane"
                        />
                        {formErrors.laneId && (
                            <div className="error-message">
                                Please Select Lane Route.
                            </div>
                        )}
                    </Col>
                    <Col></Col>
                </Row>
                <Row style={{ margin: '5px' }}>
                    <Col xs={12} md={6} lg={3} className="rowCss">
                        {/* <TimePicker use12Hours value={formTime} placeholder="Pickup Time" format="h:mm a" onChange={onChangeFromTime} style={{ width: '268px', height: '42px' }} /> */}
                        {!formTime && (
                            <div className="placeholder-text">
                                Select Pickup Time
                            </div>
                        )}
                        <Form.Control
                            type="time"
                            placeholder="Select Pickup Time"
                            value={formTime}
                            onChange={(e) => onChangeFromTime(e.target.value)}
                            isInvalid={formErrors.formTime}
                            style={formTime === '' ? { color: 'gray' } : {}}
                        />

                        {formErrors.formTime && (
                            <div className="error-message">
                                Please Select Pick up time .
                            </div>
                        )}
                    </Col>
                    <Col xs={12} md={6} lg={3} className="rowCss">
                        {/* <TimePicker use12Hours value={toTime} placeholder="Drop Time" format="h:mm a" onChange={onChangeToTime} style={{ width: '268px', height: '42px' }} /> */}
                        {!toTime && (
                            <div className="placeholder-text">
                                Select Drop Time
                            </div>
                        )}
                        <Form.Control
                            type="time"
                            placeholder="Select Drop Time"
                            value={toTime}
                            onChange={(e) => onChangeToTime(e.target.value)}
                            isInvalid={formErrors.toTime}
                            style={toTime === '' ? { color: 'gray' } : {}}

                        />

                        {formErrors.toTime && (
                            <div className="error-message">
                                Please Select Drop up time .
                            </div>
                        )}
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Row>


                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

                <div style={{ display: "flex", justifyContent: "center", marginBottom: "1%", marginTop: "5%" }}>
                    <button className="clear-button-figma-div" type="button" onClick={handleCancel}>Clear</button>
                    <button
                        type="submit"
                        className="save-button-figma-div"
                        style={{ marginBottom: "2rem" }}
                        disabled={submit}
                    >
                        {submit ? <Spinner animation="border" /> : "Submit"}
                    </button>
                </div>
            </Form>
        </>
    )
}