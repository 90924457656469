
import React from 'react'
import useEmployeeForm from './customHooks/useEmployeeForm';

const AddTeacher = () => {
  const defaultData = {
    dateOfJoining: null,
    name: "",
    code: "",
    gender: "",
    profilePictureUrl: '',
    dateOfBirth: '',
    age: '',
    email: "",
    documentUrls: [],
    primaryContactNumber: '',
    secondaryContactNumber: '',
    qualificationId: "",
    designation: '',
    religion: '',
    languagesKnown: '',
    salary: '',
    remark: '',
    workExperience: '',
    previousEmployment: '',
    referenceContactName: '',
    referenceContactNumber: '',
    previousEmploymentJoiningDate: null,
    previousEmploymentLeavingDate: null,
    previousEmploymentLastDrawnSalary: '',
    Paddress: '',
    Parea: '',
    Pcity: '',
    Pstate: '',
    Ppin: '',
    Caddress: "",
    Carea: '',
    Ccity: '',
    Cstate: '',
    Cpin: '',

  }
  const addTeacher = useEmployeeForm(defaultData);
  return (
    <>
      {addTeacher}
    </>
  )
}

export default AddTeacher;