// author:Rajeev;
// date:10/10/2023;
// version:1.0

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
//import './Student.css'; // Import your custom CSS file
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import SelectDropdown from "../SelectDropdown";
import TextInputField from "../reUsableForm/textInputField";
import {
  validationRegexOnlyPincode,
  validationRegexMobileNo,
  validationRegexOnlyAlpha,
  validationRegexOnlyAlpha1,
  validationRegexAddress,
} from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { fetchAreaData } from "../EnquiryDetails/slice/areaSlicer";
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import ReadOnlyTextField from "../../ReusableFields/TextFields/ReadOnlyTextField";
import "../reUsableForm/textInputField.css";
import { useNavigate, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  fetchUpdateStudentDataOnce,
  setShowSuccessMessage,
  setShowErrorMessage,
} from "./updateStudentSlice";
import { uploadFiles } from "../reUsableForm/uploadImage-docs";

const EditStudent = () => {
  const getStudentData = useSelector((state) => state.viewStudent.data) || [];

  const dispatch = useDispatch();
  const areasIds = useSelector((state) => state.areaDetails.data) || [];
  const putData = useSelector((state) => state.updateStudent.data) || [];
  const putAreaID = putData?.data?.currentAddress?.areaId;
  const putAreaObject = areasIds && areasIds.find((item) => item.id === putAreaID);
  let putDataArray = [];


  if (putData !== null && putData.data && typeof putData.data === 'object') {

    const item = putData?.data;

    putDataArray.push({
      createdAt: item?.createdAt,
      createdBy: item?.createdBy,
      currentAddressId: item?.currentAddressId,
      dateOfBirth: item?.dateOfBirth,
      dateOfJoining: item?.dateOfJoining,
      classesClassSectionId: item.classesClassSectionId,
      documentUrls: item?.documentUrls,
      gender: item?.gender,
      id: item?.id,
      isActive: item?.isActive,
      languagesKnown: item?.languagesKnown,
      religion: item?.religion,
      name: item?.name,
      permanentAddressId: item?.permanentAddressId,
      profilePictureUrl: item?.profilePictureUrl,
      currentAddressId: item?.currentAddressId,
      documentUrls: item?.documentUrls,
      isActive: item?.isActive,
      createdBy: item?.createdBy,
      updatedBy: item?.updatedBy,
      createdAt: item?.createdAt,
      updatedAt: item?.updatedAt,

      permanentAddress: {
        id: item?.permanentAddressId,
        address: putData?.data?.permanentAddress?.address,
        areaId: putData?.data?.permanentAddress.areaId,
        cityId: putData?.data?.permanentAddress.cityId,
        stateId: putData?.data?.permanentAddress.stateId,
        pincode: putData?.data?.permanentAddress.pincode,
        type: putData?.data?.permanentAddress.type,
        createdBy: putData?.data?.permanentAddress.createdBy,
        updatedBy: putData?.data?.permanentAddress.updatedBy,
        createdAt: putData?.data?.permanentAddress.createdAt,
        updatedAt: putData?.data?.permanentAddress.updatedAt,
        area: {
          id: putData?.data?.permanentAddress.areaId,
          cityId: putData?.data?.permanentAddress.cityId,
          name: putAreaObject?.name,
        },
        city: {
          id: putData?.data?.permanentAddress.cityId,
          stateId: putData?.data?.permanentAddress.stateId,
          name: putAreaObject?.city?.name,
        },
        state: {
          id: putData?.data?.permanentAddress.stateId,
          name: putAreaObject?.city?.state?.name,
        },
      },
      currentAddress: {
        id: item?.currentAddressId,
        address: putData?.data?.currentAddress.address,
        areaId: putData?.data?.currentAddress.areaId,
        cityId: putData?.data?.currentAddress.cityId,
        stateId: putData?.data?.currentAddress.stateId,
        pincode: putData?.data?.currentAddress.pincode,
        type: putData?.data?.currentAddress.type,
        createdBy: putData?.data?.currentAddress.createdBy,
        updatedBy: putData?.data?.currentAddress.updatedBy,
        createdAt: putData?.data?.currentAddress.createdAt,
        updatedAt: putData?.data?.currentAddress.updatedAt,
        area: {
          id: putData?.data?.currentAddress.areaId,
          cityId: putData?.data?.currentAddress.cityId,
          name: putAreaObject?.name,
        },
        city: {
          id: putData?.data?.currentAddress.cityId,
          stateId: putData?.data?.currentAddress.stateId,
          name: putAreaObject?.city?.name,
        },
        state: {
          id: putData?.data?.currentAddress.stateId,
          name: putAreaObject?.city?.state?.name,
        },
      },
    });
  }



  const { editId } = useParams();

  const findIdObj = () =>
    putDataArray.length > 0 ? (putDataArray.find((student) => student.id === parseInt(editId))) : (getStudentData.find((student) => student.id === parseInt(editId)));
  const firstData = findIdObj();








  const [validated, setValidated] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    religion: false,
    area: false,
    state: false,
    city: false,
    area1: false,
    language: false,
    address: false,
    pincode: false,
    address1: false,
    pincode1: false,
    selectedImage: false,
    selectedDocuments: false,
    dateOfBirth: false,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const defaultName = queryParams.get("studentName") || "";
  const defaultAddress = queryParams.get("address") || "";
  const formattedDateOfBirth = firstData?.dateOfBirth
    ? new Date(firstData?.dateOfBirth)
    : null;

  const [formData, setFormData] = useState({
    studentName: firstData?.name || "",
    dateOfBirth: formattedDateOfBirth || "",
    age: firstData?.age || "",
    gender: firstData?.gender || "",
    languagesKnown: firstData?.languagesKnown || "",
    religion: firstData?.religion || "",
    dateOfJoining: firstData?.studentEnrollments ? firstData?.studentEnrollments?.[0]?.date : firstData.dateOfJoining,
    classesClassSectionId: firstData?.studentEnrollments ? firstData?.studentEnrollments?.[0]?.classesClassSectionId : firstData.classesClassSectionId,


  });

  const [currentAreaId, setCurrentAreaId] = useState({
    city: firstData?.currentAddress?.cityId || "",
    state: firstData?.currentAddress?.stateId || "",
  });
  const [permanentAreaId, setPermanentAreaId] = useState({
    city: firstData?.permanentAddress?.cityId || "",
    state: firstData?.permanentAddress?.stateId || "",

  });
  const [permanentArea, setPermanentArea] = useState({});
  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
  const selectedFranchiseeSchoolId = Number(franchiseeSchoolId);
  const [currentArea, setCurrentArea] = useState({

  });


  const [dropDowns, setDropDowns] = useState({
    area: firstData?.permanentAddress?.area?.name || "",
    area1: firstData?.currentAddress?.area?.name || "",
  });


  const [currentAddressErrors, setCurrentAddressErrors] = useState({
    area: false,
    state: false,
    city: false,
    pincode: false,
    address: false,
  });

  const [permanentAddress, setPermanentAddress] = useState({
    address: defaultAddress,
    type: "permanent",
    address: firstData?.permanentAddress?.address || "",
    pincode: firstData?.permanentAddress?.pincode || "",
    areaId: firstData?.permanentAddress?.areaId || "",

  });
  const [currentAddress, setCurrentAddress] = useState({
    address: defaultAddress,
    type: "current",
    address: firstData?.currentAddress?.address || "",
    pincode: firstData?.currentAddress?.pincode || "",
    areaId: firstData?.currentAddress?.areaId || "",
  });



  const [selectedImage, setSelectedImage] = useState({
    profilePictureUrl: firstData?.profilePictureUrl || null
  });


  if (typeof selectedImage?.profilePictureUrl === 'string' && selectedImage?.profilePictureUrl) {
    const lastPart = selectedImage?.profilePictureUrl.substring(selectedImage?.profilePictureUrl.lastIndexOf('/') + 1);
    const regex = /\d+_(.+)/;
    const match = lastPart.match(regex);
    var desiredFileName = "";
    if (match && match[1]) {
      desiredFileName = match[1];
    }
  }


  const [selectedDocuments, setSelectedDocuments] = useState({
    documentUrls: firstData?.documentUrls || []
  }
  );

  const [selectedDocuments1, setSelectedDocuments1] = useState({
    documentUrls: firstData?.documentUrls || []
  }
  );


  const [desiredFileName6, setDesiredFileName6] = useState('');

  useEffect(() => {
    const extractFileName = (url) => {
      const lastPart = url.substring(url.lastIndexOf('/') + 1);
      const regex = /\d+_(.+)/;
      const match = lastPart.match(regex);
      return match && match[1] ? match[1] : '';
    };

    if (typeof selectedDocuments1.documentUrls === 'string' && selectedDocuments1.documentUrls) {
      const filename = extractFileName(selectedDocuments1.documentUrls);
      setDesiredFileName6(filename);
    } else if (Array.isArray(selectedDocuments1.documentUrls)) {
      const filenames = selectedDocuments1.documentUrls.map(extractFileName);
      setSelectedDocuments1(filenames);

      // Combine all filenames into a single string separated by commas
      const combinedFilenames = filenames.filter(Boolean).join(', ');
      setDesiredFileName6(combinedFilenames);
    }
  }, []);


  const [father, setFather] = useState([]);
  const [mother, setMother] = useState([]);
  const [image, setImage] = useState(null);
  const [defaultImage, setDefaultImage] = useState(null);
  const [defaultDocuments, setDefaultDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);

  const fileNamesArray = Array.from(
    { length: documents.length },
    (_, index) => documents[index].name
  );

  const handleDocumentChange = (event) => {
    const files = event.target.files;
    setDocuments(files);

  };



  const getAge = (dob) => {
    const today = new Date();

    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };


  useEffect(() => {
    if (currentAreaId) {
      setCurrentAddress({
        ...currentAddress,
        cityId: currentAreaId.city,
        stateId: currentAreaId.state,
      });
    }
  }, [currentAreaId]);
  useEffect(() => {
    if (permanentAreaId) {
      setPermanentAddress({
        ...permanentAddress,
        cityId: permanentAreaId.city,
        stateId: permanentAreaId.state,
      });
    }
  }, [permanentAreaId]);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Note: Months are zero-based, so add 1
  const day = currentDate.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const apiData = useSelector((state) => state.areaDetails.data) || [];


  useEffect(() => {
    if (apiData.length === 0) {
      dispatch(fetchAreaData());
    }
  }, [apiData, dispatch]);

  const showSuccessMsg = useSelector(
    (state) => state.updateStudent.showSuccessMessage
  );
  const successMsg = useSelector((state) => state.updateStudent.successMessage);

  const showErrorMsg = useSelector(
    (state) => state.updateStudent.showErrorMessage
  );
  const errorMsg = useSelector((state) => state.updateStudent.errorMessage);



  const handleUploadImage = (event) => {
    const file = event.target.files;
    setImage(file);
    setFormErrors({ ...formErrors, selectedImage: false });
  };
  ;

  const getBorderColor = () => {
    if (documents && documents.length > 0) {
      return "#198754"; // Green when there are selected documents
    } else if (validated) {
      return "red"; // Red when validated but no documents selected
    } else {
      return "#ccc"; // Default color
    }
  };

  const getBorderColorImages = () => {
    if (image && image.length > 0) {
      return "#198754"; // Green when there are selected documents
    } else if (validated) {
      return "red"; // Red when validated but no documents selected
    } else {
      return "#ccc"; // Default color
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSubmit(true);
    const newFormErrors = {
      religion: !formData.religion,
      area: !dropDowns.area,
      area1: !dropDowns.area1,
      studentName: !formData.studentName,
      language: !formData.language,
      address: !permanentAddress.address,
      pincode: !permanentAddress.pincode,
      selectedImage: image?.length === 0,
      // selectedDocuments: documents?.length === 0,
      dateOfBirth: !formData.dateOfBirth,
    };

    setFormErrors(newFormErrors);
    const updateAddress = {
      address: !currentAddress.address,
      pincode: !currentAddress.pincode,
    };
    setCurrentAddressErrors(updateAddress);

    const formErrorsValues = Object.values(newFormErrors);
    const addressErrorsValues = Object.values(updateAddress);

    const hasErrors = formErrorsValues
      .concat(addressErrorsValues)
      .some((error) => error);
    setFormErrors(newFormErrors);
    setCurrentAddressErrors(updateAddress);

    // if (hasErrors) {
    //   setValidated(true);
    //   setSubmit(false);
    //   return;
    // }

    if (image) {
      // var file_id = await handleUpload();
      const uploadResponses1 = await uploadFiles((image), "student/profile-images");
      var file_id = uploadResponses1.map((response) => response.Location) || null;

    }

    if (documents.length > 0) {
      // var file_ids = await handleUpload1();
      const uploadResponses1 = await uploadFiles((documents), "student/documents");
      var file_ids = uploadResponses1.map((response) => response.Location) || null;

    }


    const sendData = {
      // franchiseeSchoolId: selectedFranchiseeSchoolId,
      name: (formData.studentName).trim(),
      gender: formData.gender,
      profilePictureUrl: file_id ? String(file_id) : selectedImage?.profilePictureUrl,
      documentUrls: file_ids ? file_ids : selectedDocuments.documentUrls,
      dateOfBirth: formData.dateOfBirth,
      // studentEnquiryId: formData.studentEnquiryId,
      languagesKnown: (formData.languagesKnown).trim(),
      religion: (formData.religion).trim(),
      age: getAge(formData.dateOfBirth),
      dateOfJoining: selectedDate,
      classesClassSectionId: formData.classesClassSectionId,
      currentAddress: currentAddress,
      permanentAddress: permanentAddress,
    };

    dispatch(fetchUpdateStudentDataOnce(firstData.id, sendData));
    setSubmit(false);


  };

  const handleCancel = (e) => {
    setCurrentAddress({});
    setSelectedImage(null);
    setPermanentAddress({});
    setFormErrors("");
    setCurrentAddressErrors("");
    setDropDowns({ class: "" });
    setMother({});
    setDocuments([]);
    setFather({});
    setFormData({
      dateOfBirth: "",
    });
    setValidated(false);
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    navigate("/viewStudent");
    handleCancel();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  const navigateToAnotherScreen = () => {
    navigate("/AddArea");
  };


  const [selectedDate, setSelectedDate] = useState(new Date(firstData?.studentEnrollments ? firstData?.studentEnrollments?.[0]?.date : firstData.dateOfJoining));
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  return (
    <div style={{ height: "100vh" }}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header">Edit Student</div>
        <Row>
          <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
            <Form.Control
              type="date"
              className="textInput_Field"
              value={formatDate(selectedDate)}
              onChange={(e) => setSelectedDate(new Date(e.target.value))}
            ></Form.Control>
          </Col>
        </Row>
        <Row>
          {/* ------------------------------firstName--------------------------------------------------- */}
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="First Name"
              placeholder="Name"
              textType="Alphabet"
              value={formData.studentName}
              required
              onChange={(event) => {
                setFormData({ ...formData, studentName: event.target.value });
                setFormErrors({ ...formErrors, studentName: false });
              }}
              validated={validated}
              feedbackMessage="Please enter first name."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter valid first name."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <Form.Label className="lableName">Gender</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Male"
                  type="radio"
                  name="gender"
                  value="Male"
                  className="malebutton"
                  checked={formData.gender === "Male"}
                  onChange={(event) =>
                    setFormData({ ...formData, gender: event.target.value })
                  }
                />
                <Form.Check
                  inline
                  label="Female"
                  type="radio"
                  name="gender"
                  value="Female"
                  className="malebutton"
                  checked={formData.gender === "Female"}
                  onChange={(event) =>
                    setFormData({ ...formData, gender: event.target.value })
                  }
                />
              </div>
            </Form.Group>
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <div
                className="uploadDoc"
                style={{
                  border: `1px solid ${getBorderColorImages()}`, // Dynamic border color
                }}
              >
                <div className="layout1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                      fill="#636363"
                    />
                  </svg>
                  <div className="title1">Upload&nbsp;Images</div>
                </div>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png'"
                  onChange={handleUploadImage}
                  style={{
                    width: "100%",
                    // height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
              <div>
                <p>{image?.[0].name || desiredFileName}</p>
              </div>
            </Form.Group>

            {formErrors.selectedImage && (
              <div className="error-message">Please Select Image .</div>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <DatePickerPast
              label="DOB*"
              value={formData.dateOfBirth || ""}
              onChange={(name, date, selectedDate) => {
                setFormData({ ...formData, dateOfBirth: date });
                setFormErrors({ ...formErrors, dateOfBirth: !date });
              }}
              required
              name="DOB"
              placeholder="Select Date of Birth*"
              dateFormat="dd-MM-yyyy"
              isValidDate={(date) => true}
              feedbackMessage="Please select date of birth."
            />
            {formErrors.dateOfBirth && (
              <div className="error-message">
                Please select a date of birth.
              </div>
            )}
          </Col>
          <Col xs={12} md={6} lg={3} className="rowCss">
            <ReadOnlyTextField
              name="Student Age"
              textType=""
              value={getAge(formData.dateOfBirth) || ""}
              required
              placeholder="Age"
              onChange={(e) => {
                setFormData({ ...formData, age: getAge(formData.dateOfBirth) });
              }}
              validated={validated}
              validationRegex={validationRegexMobileNo}
              maxLength={25}
            />
          </Col>

          <Col xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <div
                className="uploadDoc"
                style={{
                  border: `1px solid ${getBorderColor()}`, // Dynamic border color
                }}
              >
                <div className="layout1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                      fill="#636363"
                    />
                  </svg>
                  <div className="title1">Upload&nbsp;Doc</div>
                </div>
                <input
                  type="file"
                  accept=".pdf, .doc"
                  multiple
                  onChange={handleDocumentChange}
                  style={{
                    width: "100%",
                    // height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
              <div>


                {fileNamesArray[0] !== undefined
                  ? fileNamesArray?.map((name, index) => {
                    return <p key={index}>{name}</p>;
                  })
                  : (desiredFileName6 && (
                    <div>
                      {typeof desiredFileName6 === "string" && <p>{desiredFileName6}</p>}
                    </div>
                  )
                  )}
              </div>
            </Form.Group>
            {formErrors.selectedDocuments && (
              <div className="error-message">
                Please Select selectedDocuments .
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Language"
              placeholder="Language*"
              textType="Alphabet"
              required
              value={formData.languagesKnown}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  languagesKnown: event.target.value,
                });
              }}
              validated={validated}
              feedbackMessage="Please enter language."
              validationRegex={validationRegexOnlyAlpha1}
              validationErrorMessage="Please enter a valid language."
            />
          </Col>

          <Col xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Religion"
              placeholder="Religion*"
              textType="Alphabet"
              required
              value={formData.religion}
              onChange={(event) => {
                setFormData({ ...formData, religion: event.target.value });
              }}
              validated={validated}
              feedbackMessage="Please enter religion."
              validationRegex={validationRegexOnlyAlpha1}
              validationErrorMessage="Please enter a valid religion."
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Permanent Address"
              placeholder="Permanent Address"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={permanentAddress.address}
              onChange={(event) => {
                setPermanentAddress({
                  ...permanentAddress,
                  address: event.target.value,
                });
              }}
              feedbackMessage="Please enter permanent address."
              validationRegex={validationRegexAddress}
              validationErrorMessage="Please enter valid permanent address."
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.area
                  ? { value: dropDowns.area, label: dropDowns.area }
                  : null
              }
              options={apiData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
                city: { id: option.cityId, name: option.city.name },
                state: {
                  id: option.city.stateId,
                  name: option.city.state.name,
                },
              }))}
              onChange={(selectedOption) => {
                setPermanentAddress({
                  ...permanentAddress,
                  areaId: selectedOption.id,
                });
                setPermanentArea({
                  ...permanentArea,
                  city: selectedOption.city,
                  state: selectedOption.state,
                });
                setPermanentAreaId({
                  ...permanentAreaId,
                  city: selectedOption.city.id,
                  state: selectedOption.state.id,
                });

                setDropDowns({ ...dropDowns, area: selectedOption.value });
                setFormErrors({ ...formErrors, area: false });
              }}
              placeholder="Select Area"
            />
            {formErrors.area && (
              <div className="error-message">Please Select area .</div>
            )}
          </Col>
          <Col sm={12} xs={12} md={6} lg={1}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-add-icon">Add Area</Tooltip>}
            >
              <AddIcon
                onClick={navigateToAnotherScreen}
                style={{ cursor: "pointer" }}
              />
            </OverlayTrigger>
          </Col>
          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="City"
              placeholder="City"
              className="fcontrol"
              textType="Alphabet"
              value={
                permanentArea?.city?.name || firstData?.permanentAddress?.city?.name
              }
              disabled
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="State"
              placeholder="State"
              className="fcontrol"
              textType="Alphabet"
              value={
                permanentArea?.state?.name || firstData?.permanentAddress?.state?.name
              }
              disabled
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="Pincode"
              placeholder="Pincode"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={permanentAddress.pincode || ""}
              onChange={(event) => {
                setPermanentAddress({
                  ...permanentAddress,
                  pincode: Number(event.target.value),
                });
              }}
              feedbackMessage="Please enter pincode."
              validationRegex={validationRegexOnlyPincode}
              validationErrorMessage="Please enter valid pincode."
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Current Address"
              placeholder="Current Address"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={currentAddress.address}
              onChange={(event) => {
                setCurrentAddress({
                  ...currentAddress,
                  address: event.target.value,
                });
              }}
              feedbackMessage="Please enter current address."
              validationRegex={validationRegexAddress}
              validationErrorMessage="Please enter valid current address."
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.area1
                  ? { value: dropDowns.area1, label: dropDowns.area1 }
                  : null
              }
              options={apiData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
                city: { id: option.cityId, name: option.city.name },
                state: {
                  id: option.city.stateId,
                  name: option.city.state.name,
                },
              }))}
              onChange={(selectedOption) => {
                setCurrentAddress({
                  ...currentAddress,
                  areaId: selectedOption.id,
                });
                setCurrentArea({
                  ...currentArea,
                  city: selectedOption.city,
                  state: selectedOption.state,
                });
                setCurrentAreaId({
                  ...currentAreaId,
                  city: selectedOption.city.id || firstData?.currentAddress?.cityId,
                  state: selectedOption.state.id || firstData?.currentAddress?.stateId,
                });
                setDropDowns({ ...dropDowns, area1: selectedOption.value });
                setFormErrors({ ...formErrors, area1: false });
              }}
              placeholder="Select Area"
            />
            {formErrors.area1 && (
              <div className="error-message">Please Select area .</div>
            )}
          </Col>
          <Col sm={12} xs={12} md={6} lg={1}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-add-icon">Add Area</Tooltip>}
            >
              <AddIcon
                onClick={navigateToAnotherScreen}
                style={{ cursor: "pointer" }}
              />
            </OverlayTrigger>
          </Col>
          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="City"
              placeholder="City"
              className="fcontrol"
              textType="Alphabet"
              value={
                currentArea?.city?.name || firstData?.currentAddress?.city?.name
              }
              disabled
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="State"
              placeholder="State"
              className="fcontrol"
              textType="Alphabet"
              value={
                currentArea?.state?.name ||
                firstData?.currentAddress?.state?.name
              }
              disabled
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="Pincode"
              placeholder="Pincode"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={currentAddress.pincode || ""}
              onChange={(event) => {
                setCurrentAddress({
                  ...currentAddress,
                  pincode: Number(event.target.value),
                });
              }}
              feedbackMessage="Please enter pincode."
              validationRegex={validationRegexOnlyPincode}
              validationErrorMessage="Please enter valid pincode."
            />
          </Col>
        </Row>

        <SuccessMessagePopup
          show={showSuccessMsg}
          onClose={handleCloseSuccessPopup}
          successMessage={successMsg}
        />
        <ErrorMessagePopup
          show={showErrorMsg}
          onClose={handleCloseErrorPopup}
          errorMessage={errorMsg}
        />


        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleCancel}
          >
            Clear
          </button>
          <button
            className="save-button-figma-div"
            type="submit"
            disabled={submit}
          >
            {submit ? "Submitting..." : "Submit"}
          </button>
        </div>
      </Form>
      {/* ... Displaying school kids in cards */}

      {/* </Container> */}
    </div>
  );
};

export default EditStudent;
