import { createSlice } from '@reduxjs/toolkit';
import { token } from '../../../Api/token';
import { responseFormat } from '../../Auth/errorResponse';
import { fetchWithAuthPost } from '../../Auth/dependency_post';
import { API_PATH } from '../../../Api/api';
// import { fetchEnquiryView, fetchAddVehicleView } from './addVehicleViewSlicer';
import { useEffect } from 'react';

const slice = createSlice(
    {
        name: 'revenueViewEdit',
        initialState: {
            data: [],
            isLoading: false,
            hasError: false,
            successMessage: '',
            showSuccessMessage: false,
            errorMessage: '',
            showErrorMessage: false,
        },
        reducers: {
            fetchDataStart: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchDataSuccess: (state, action) => {
                state.isLoading = false;
                const newData = action.payload;
    
                // Create a map of new data with IDs as keys
                const newDataMap = new Map(newData.map(item => [item.id, item]));
    
                // Merge existing data with new data based on ID
                const updatedData = state.data.map(item =>
                    newDataMap.has(item.id) ? newDataMap.get(item.id) : item
                );
    
                // Add new items that are not already in state.data
                const newItems = newData.filter(item => !state.data.some(existingItem => existingItem.id === item.id));
    
                // Update state.data with the merged and new items
                state.data = [...updatedData, ...newItems];
            },
    
            fetchDataFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessageVehicle: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessageVehicle: (state, action) => {
                state.showErrorMessage = action.payload;
            },
        },
    },

);



export const { fetchDataStart, fetchDataSuccess, fetchDataFailure, setSuccessMessage, setShowSuccessMessageVehicle, setErrorMessage, setShowErrorMessageVehicle } = slice.actions;

export const updateRevenueView = (revenueId, updatedVehicleData) => async (dispatch) => {
    dispatch(fetchDataStart());
    const tokenId = token();

    try {
        const response = await fetchWithAuthPost(API_PATH + `/revenues/${revenueId}`, "PUT", tokenId, updatedVehicleData);
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const data = await response;
            dispatch(fetchDataSuccess([data.revenue]));
            dispatch(setSuccessMessage("Successfull!"));
            dispatch(setShowSuccessMessageVehicle(true));
        }
    } catch (error) {
        console.error("Error", error);
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessageVehicle(true));
    }
};
export default slice.reducer;
