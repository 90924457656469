import React, { useEffect, useState } from 'react'
import TextInputField from '../../reUsableForm/textInputField';
import { validationRegexAlphaNumeric } from '../../reUsableForm/regex';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMessagePopup from '../../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../../reUsableForm/pop-card/errorMessage';
import { Col, Form, Row } from 'react-bootstrap';
import { addVehiclePost, setShowErrorMessage, setShowSuccessMessage } from './addVehicleSlicer';
import { API_PATH } from '../../../Api/api';
import { token } from "../../../Api/token";
import { folderPath } from '../../../Api/folderPath';
import './addVehicle.css'

const AddVehicle = () => {

  const defaultData = {
    vehicleNumber: '',
    model: '',
    brand: '',
    chasisNumber: '',
    uploadDocuments: []
  }

  const showSuccessMsg = useSelector((state) => state.addVehicle.showSuccessMessage);
  const successMsg = useSelector((state) => state.addVehicle.successMessage);

  const showErrorMsg = useSelector((state) => state.addVehicle.showErrorMessage);
  const errorMsg = useSelector((state) => state.addVehicle.errorMessage);

  const [formData, setFormData] = useState(defaultData);
  const [validated, setValidated] = useState(false);

  const [documentFiles, setDocumentFiles] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [formErrors,setFormErrors] = useState({
    selectedDocuments:false
  })

  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value })
  }
 

  const handleUpload = async () => {

    const formData = new FormData();

    for (let i = 0; i < documentFiles.length; i++) {
      formData.append('files', documentFiles[i]);
    }

    const apiUrl = API_PATH + `/uploadFiles?folderPath=${folderPath}`

    fetch(apiUrl, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        setFormData((prevData) => ({ ...prevData, uploadDocuments: data.filePaths }));
      })
      .catch(error => {
        console.error('Error uploading files:', error);
      });

  };

  const fileNamesArray = Array.from(
    { length: documents.length },
    (_, index) => documents[index].name
  );

  const handleDocumentChange = (event) => {
    const files = event.target.files;
    setDocuments(files);
    setFormErrors({ ...formErrors, selectedDocuments: false });
  };

  // useEffect(() => {
  //   if (documentFiles.length > 0) {
  //     handleUpload();
  //   }
  // }, [documentFiles]);

  const handleUpload1 = async () => {
    try {
      if (!documents) {
        console.error("No images to upload");
        return;
      }
  
      const formData = new FormData();
  
      for (let i = 0; i < documents.length; i++) {
        formData.append("files", documents[i]);
      }
  
      const response = await fetch(`${API_PATH}/uploadFiles`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token()}`, // Assuming token() retrieves the token
        },
      });
  
      if (response.ok) {
        const result = await response.json();
        const fileIds = result.files.map((id) => String(id.fileId));
        return fileIds;
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  
  const  getBorderColor = () => {
    if (documents && documents.length > 0) {
      return "#198754"; // Green when there are selected documents
    } else if (validated) {
      return "red"; // Red when validated but no documents selected
    } else {
      return "#ccc"; // Default color
    }
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    e.stopPropagation();
    // const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    //   e.stopPropagation();
    //   setValidated(true);
    //   return;

    // }
   const newFormErrors = {
    selectedDocuments: documents?.length === 0,
   }
   const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);

   if (hasErrors) {
    setValidated(true);
    return;
  }

    if (documents.length > 0) {
     
      var file_ids = await handleUpload1();
      console.error(file_ids);
    }

    const sendData={
      ...formData,
      uploadDocuments:file_ids
    }
    dispatch(addVehiclePost(sendData));
    handleClear()
  }
  const handleClear = () => {
    setFormData(defaultData);
    setDocuments([])
    setValidated(false);
    setFormErrors({selectedDocuments:false})
    
  }

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    // handleCancel();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));

  };

  return (
    <>
      <h4 className="content-header ">Add Vehicle</h4>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name='vehicleNumber'
              placeholder='Enter your vehicle number'
              textType='Alphabet'
              validated={validated}
              required
              maxLength={25}
              feedbackMessage='Vehicle number is required!'
              validationErrorMessage='Give valid vehicle number!'
              value={formData.vehicleNumber}
              validationRegex={validationRegexAlphaNumeric}
              onChange={handleChange}
            />
          </Col>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name='model'
              placeholder='Enter your model name'
              textType='Alphabet'
              validated={validated}
              required='true'
              maxLength={25}
              feedbackMessage='Model name is required!'
              validationErrorMessage='Give valid model name!'
              value={formData.model}
              validationRegex={validationRegexAlphaNumeric}
              onChange={handleChange}
            />
          </Col>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name='brand'
              placeholder='Enter your brand'
              textType='Alphabet'
              validated={validated}
              required='true'
              maxLength={25}
              feedbackMessage='Brand name is required!'
              validationErrorMessage='Give valid brand name!'
              value={formData.brand}
              validationRegex={validationRegexAlphaNumeric}
              onChange={handleChange}
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name='chasisNumber'
              placeholder='Enter your chasis number'
              textType='Alphabet'
              validated={validated}
              required='true'
              maxLength={25}
              feedbackMessage='Chasis number is required!'
              validationErrorMessage='Give valid chasis number!'
              value={formData.chasisNumber}
              validationRegex={validationRegexAlphaNumeric}
              onChange={handleChange}
            />
          </Col>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
            <div
                className="uploadDoc"
                style={{
                  border: `1px solid ${getBorderColor()}`, // Dynamic border color
                }}
              >
                <div className='layout1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='25'
                    height='24'
                    viewBox='0 0 25 24'
                    fill='none'
                  >
                    <path
                      d='M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z'
                      fill='#636363'
                    />
                  </svg>
                  <div className='title1'>Upload&nbsp;Doc</div>
                </div>
                <input
                  type="file"
                  accept=".pdf, .doc, .docx, "
                  required
                  multiple
                  
                  name='uploadDocuments'
                  onChange={ handleDocumentChange}
                  style={{
                    width: '100%',
                    // height: '100%',
                    opacity: 0,
                    cursor: 'pointer',
                    position: 'absolute',
                    zIndex: 1,
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
              <div style={{ fontSize: "0.7rem" }}>Please ensure each file is less than 5MB in size.</div>
              <div>
                {fileNamesArray?.map((name, index) => {
                  
                  return <p key={index}>{name}</p>;
                })}
              </div>
            </Form.Group>
            {formErrors.selectedDocuments && (
              <div className="error-message">
                Please Select selectedDocuments .
              </div>
            )}
          </Col>
        </Row>
        <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
        <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%"
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
    </>
  )
}

export default AddVehicle;