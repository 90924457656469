import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import FutureDateField from "../../ReusableFields/DateFields/FutureDateField";
import { validationRegexOnlyAlpha } from "../reUsableForm/regex";
import { postEventAndHolidaysRequest, setShowErrorMessage, setShowSuccessMessage, } from "./slice/eventAndHolidaysSlice";
import { useDispatch, useSelector } from "react-redux";
import SelectDropdown from "../SelectDropdown";
import SearchableDropDown from "../../ReusableFields/DropDown/searchableDroDown";
import { FetchFranchiseSchools } from "../Franchise/slice/schoolDataGet";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { Space } from "antd";
import Select from 'react-select';
import { SelectingValuesDropDownField } from "../../ReusableFields/DropDown/multipleSelectDropDown";
import DateInputField from "../../ReusableFields/DateFields/DateField";

const EventAndHolidays = () => {
    const [formData, setFormData] = useState({
        type: "",
        franchiseeSchoolId: [],
    });
    const [validated, setValidated] = useState(false)
    const [dropDownIds, setDropDownIds] = useState({})
    const [rowsData, setRowsData] = useState([
        {
            name: "",
            type: "",
            fromDate: '',
            toDate: '',
            days: ''
        },
    ]);
    const [formErrors, setFormErrors] = useState({ franchiseeSchoolId: false, type: false })
    const data1 = [{ "id": 1, "name": "event", "label": "Event" }, { "id": 2, "name": "holiday", "label": "Holiday" }]

    const Branch = useSelector((state) => state.franchiseSchools.data) || [];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(FetchFranchiseSchools());
    }, [dispatch]);

    const showSuccessMsg = useSelector((state) => state.eventAndHolidays.showSuccessMessage);
    const successMsg = useSelector((state) => state.eventAndHolidays.successMessage);

    const showErrorMsg = useSelector((state) => state.eventAndHolidays.showErrorMessage);
    const errorMsg = useSelector((state) => state.eventAndHolidays.errorMessage);

    const updateRow = (index, field, value) => {
        const updatedRowsData = [...rowsData];
        updatedRowsData[index][field] = value;
        setRowsData(updatedRowsData);
    };
    const addRow = () => {
        setRowsData([...rowsData, {
            name: "",
            type: "",
            fromDate: '',
            toDate: '',
            days: ''
        }]);
    };
    const removeRow = (index) => {
        if (rowsData.length > 1) {
            const updatedRowsData = [...rowsData];
            updatedRowsData.splice(index, 1);
            setRowsData(updatedRowsData);
        }

    };

    // const calculateDay = (dateString) => {
    //     const date = new Date(dateString);
    //     const options = { weekday: 'long' };
    //     return date.toLocaleDateString(undefined, options);
    // };

    const calculateDay = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date)) return '';
        const options = { weekday: 'long' };
        return date.toLocaleDateString(undefined, options);
    };

    const calculateDaysInRange = (fromDate, toDate) => {
        if (!fromDate || !toDate) return '';
        const startDay = calculateDay(fromDate);
        const endDay = calculateDay(toDate);
        return `${startDay}-${endDay}`;
    };


    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date)) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Changed to yyyy-mm-dd
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newFormErrors = {
            franchiseeSchoolId: formData.franchiseeSchoolId.length === 0,
            type: !formData.type,
        };

        rowsData.forEach((row, index) => {
            if (!row.fromDate || !row.toDate) {
                newFormErrors[`date_${index}`] = true;
            }
        });

        const hasErrors = Object.values(newFormErrors).some((error) => error);

        setFormErrors(newFormErrors);

        const form = event.currentTarget;
        if (hasErrors) {
            setValidated(true);
            return;
        }

        // const formatDate = (dateString) => {
        //     const date = new Date(dateString);
        //     const year = date.getFullYear();
        //     const month = String(date.getMonth() + 1).padStart(2, '0');
        //     const day = String(date.getDate()).padStart(2, '0');
        //     return `${year}-${month}-${day}`;
        // };

        const events = rowsData.map((rowData) => ({
            name: rowData.name,
            fromDate: formatDate(rowData.fromDate),
            toDate: formatDate(rowData.toDate),
            days: calculateDaysInRange(rowData.fromDate, rowData.toDate),
        }));

        const data = {
            ...formData,
            events,
        };
        dispatch(postEventAndHolidaysRequest(data));
    };

    // const calculateDaysInRange = (fromDate, toDate) => {
    //     const start = formatDate(fromDate);
    //     const end = formatDate(toDate);

    //     const startDay = calculateDay(start);
    //     const endDay = calculateDay(end);

    //     return `${startDay}-${endDay}`;
    // };

    const handleClear = () => {
        setFormData({
            franchiseeSchoolId: [],
            type: ""
        });
        setRowsData([
            {
                name: "",
                type: "",
                fromDate: '',
                toDate: '',
                days: ''
            },
        ])
        setValidated(false);
        setFormErrors({
            franchiseeSchoolId: false,
            type: false,
        });
        setDropDownIds({ typeName: "" })
    }

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleClear();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="content-header">Event And Holidays</div>
            <Row>
                <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                    <SelectingValuesDropDownField
                        isMulti={true}
                        className="input-style"
                        value={Branch.filter(branch => formData.franchiseeSchoolId.includes(branch.id))}
                        options={Branch.map(option => ({
                            id: option.id,
                            value: option.name,
                            label: option.name,
                        }))}
                        onChange={(selectedOptions) => {
                            setFormData({ ...formData, franchiseeSchoolId: selectedOptions.map(option => option.id) });
                            setFormErrors({ ...formErrors, franchiseeSchoolId: selectedOptions.length === 0 });
                        }}
                        placeholder="Branch"
                    />
                    {formErrors.franchiseeSchoolId && (
                        <div className="error-message">
                            Please Select Branch .
                        </div>
                    )}
                </Col>

                <Col sm={6} xs={12} md={6} lg={4} className="rowCss" >
                    <SelectDropdown
                        isSearchable
                        value={dropDownIds.typeName}
                        options={data1.map((option) => ({
                            id: option.id,
                            value: option.name,
                            label: option.label,
                        }))}
                        onChange={(selectedOption) => {
                            setDropDownIds({ ...dropDownIds, typeName: selectedOption });
                            setFormData({ ...formData, "type": (selectedOption.value) });
                            setFormErrors({ ...formErrors, type: !selectedOption });
                        }}
                        placeholder="Type"
                    />
                    {formErrors.type && (
                        <div className="error-message">
                            Please Select type.
                        </div>
                    )}
                </Col>
            </Row>
            {rowsData.map((rowData, index) => (
                <Row key={index}>
                    <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                        <Form.Label>Name</Form.Label>
                        <Form.Group>
                            <TextInputField
                                type="text"
                                className="input-style"
                                required
                                placeholder="Name"
                                value={rowData.name}
                                onChange={(event) => {
                                    updateRow(index, "name", (event.target.value));
                                }}
                                validated={validated}
                                feedbackMessage="please enter Name"
                                validationRegex={validationRegexOnlyAlpha}
                                validationErrorMessage="please enter valid name"
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                        <Form.Label>From Date</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder="From Date"
                            value={rowData.fromDate}
                            onChange={(event) => {
                                const selectedDate = event.target.value;
                                updateRow(index, "fromDate", selectedDate);

                                // Optional: Clear To Date if it's before the new From Date
                                if (rowData.toDate && selectedDate > rowData.toDate) {
                                    updateRow(index, "toDate", '');
                                }
                            }}
                            isInvalid={formErrors[`date_${index}`]}
                            max={rowData.toDate || undefined} // Optional: Prevent From Date > To Date
                            // min={new Date().toISOString().split('T')[0]} // Optional: Prevent selecting past dates
                        />
                        <Form.Control.Feedback type="invalid">
                            Please select a valid From Date.
                        </Form.Control.Feedback>
                        <div>From Day: {rowData.fromDate && calculateDay(rowData.fromDate)}</div>
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                        <Form.Label>To Date</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder="To Date"
                            value={rowData.toDate}
                            onChange={(event) => {
                                const selectedDate = event.target.value;
                                updateRow(index, "toDate", selectedDate);
                            }}
                            isInvalid={formErrors[`date_${index}`]}
                            min={rowData.fromDate || undefined} // Sets min to From Date
                        />
                        <Form.Control.Feedback type="invalid">
                            Please select a valid To Date.
                        </Form.Control.Feedback>
                        <div>To Day: {rowData.toDate && calculateDay(rowData.toDate)}</div>
                    </Col>
                    <Col sm={6} xs={12} md={6} lg={4} className="rowCss">
                        <Space sm={6} xs={12} md={6} lg={4} className="rowCss">
                            <button
                                className="save-button-figma-div"
                                type="button"
                                onClick={addRow}
                                style={{
                                    width: '30px',
                                    height: '15px',
                                    marginRight: '0.5rem',
                                    marginTop: "72%"
                                }}
                            >
                                +
                            </button>
                            <button
                                className="save-button-figma-div"
                                type="button"
                                onClick={() => removeRow(index)}
                                style={{
                                    width: '30px',
                                    height: '15px',
                                    display: 'flex',
                                    marginRight: '3rem',
                                    display: rowsData.length === 1 ? 'none' : '',
                                }}
                            >
                                -
                            </button>
                        </Space>
                    </Col>
                </Row>
            ))}
            <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
            <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                    marginTop: "2%"
                }}
            >
                <button className="clear-button-figma-div" type="button" onClick={handleClear}>Clear</button>
                <button className="save-button-figma-div" type="submit">Submit</button>
            </div>
        </Form>
    )
}
export default EventAndHolidays;
