function formatDateToYMD(inputDate) {
    // Try converting the input into a Date object if it's a string
    const date = (typeof inputDate === "string" || typeof inputDate === "number") ? new Date(inputDate) : inputDate;

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        throw new RangeError("Invalid time value: Unable to convert to a valid date.");
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it's 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export default formatDateToYMD