import React from 'react';
import { Col, Form } from 'react-bootstrap';
// import './DateField.css'
import "./ReadOnlyDateField.css"

const DateInputField = ({
  name,
  value,
  onChange,
  feedbackMessage,
  validated,
  placeholder,
  minDate,
  maxDate,
  required = false,
  colSize,
}) => {
  return (
    
      <Form.Group>

        <Form.Control
          type="date"
          className="readOnlyDate_field"
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          validated={validated}
          data-placeholder={placeholder}
          min={minDate} // Minimum date (optional)
          max={maxDate} // Maximum date (optional)
        />
        {validated && feedbackMessage && (
          <Form.Control.Feedback type="invalid">
            {feedbackMessage}
          </Form.Control.Feedback>
        )}
      </Form.Group>
  );
};

export default DateInputField;
