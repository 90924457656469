/*
    authors: Rajeev
    date: 03-11-2023
    update:03-11-2023 
    version: 1.0
*/

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttendanceDataOnce } from "./viewAttendanceSlicer";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import SelectDropdown from "../SelectDropdown";
import { fetchFranchiseClasswiseSectionsDataOnce } from "../Assessment/slicer/viewfranchiseclasswisesectionsSlicer";
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import { token } from "../../Api/token";
import { API_PATH } from "../../Api/api";
import FormatDate from "../reUsableForm/dateFormat";
export const ViewAttendance = () => {
  const columns = [
    { field: "srNo", header: "Sr.No" },
    { field: "rollNumber", header: "Roll Number" },
    { field: "studentName", header: "Student Name" },
    { field: "attendanceStatus", header: "Attendance Status" },
    { field: "date", header: "Date" },
    { field: "class", header: "Class" },
    { field: "section", header: "Section" },
  ];
  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
  const FranchiseeSchoolId = Number(franchiseeSchoolId);
  const [dropDowns, setDropDowns] = useState({});
  const [formData, setFormData] = useState({});
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClassData, setSelectedClassData] = useState([]);
  const [processData, setProcessData] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [date, setDate] = useState("");
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({
    class: false,
    selectDate: false,
    name: false,
    classesSectionsSubject: false,
  });
  const prePostOptions = [
    { id: "true", name: "PreLunch", value: true },
    { id: "false", name: "PostLunch", value: false },
  ];

  const [showTable, setShowTable] = useState(false);
  const postData = useSelector((state) => state.addAttendance.data) || [];
  // const apiData = useSelector((state) => state.viewAttendance.data) || [];
  // const studentData = useSelector((state) => state.viewStudent.data) || [];

  // useEffect(() => {
  //   if (apiData.length === 0) {
  //     dispatch(fetchAttendanceDataOnce(FranchiseeSchoolId));
  //   }
  // }, [dispatch, apiData]);


  // useEffect(() => {
  //   if (studentData.length === 0) {
  //     dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
  //   }
  // }, [studentData, dispatch]);
  
  const classesClassSectionData =
    useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];

  useEffect(() => {
    if (classesClassSectionData.length == 0) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }

  }, [classesClassSectionData, dispatch]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });
  };


  const handleClassChange = () => {
    setSelectedSection("");
    setSelectedClassData([]);

    setShowTable(false);

    const classSectionData = classesClassSectionData.find(
      (c) => c.id == selectedClass
    );
    setSelectedClassData(classSectionData);

    if (classSectionData && classSectionData.classSections.length === 0) {
      setSelectedSection(classSectionData.classesclassSection);
      // setDropDowns({ ...dropDowns, section: "" });
    }
  };

  useEffect(() => {
    handleClassChange(selectedClass);
    setDropDowns({ ...dropDowns, sections: "" });
  }, [selectedClass]);

  const originalDate = formData.selectDate;

  if (originalDate) {
    // Convert originalDate to a Date object
    var dateObject = new Date(originalDate);

    // Add one day to the date
    dateObject.setDate(dateObject.getDate() + 1);

    // Format the new date as a string
    var formattedDate = dateObject.toISOString().split("T")[0];

  }

  const handleSearchClick = async (e) => {
    e.preventDefault();

    // Check if class, section, Pre/Post Attendance, and fromDate are selected
    if (
      !selectedClass ||
      !selectedSection ||
      !dropDowns.name ||
      !formData.selectDate
    ) {
      setFormErrors({
        ...formErrors,
        class: !selectedClass,
        sections: !selectedSection,
        name: !dropDowns.name,
        selectDate: !formData.selectDate,
      });
      return;
    }

    try {

      const url = API_PATH + `/franchisee-school/${FranchiseeSchoolId}/studentAttendances?date=${FormatDate(formData.selectDate)}&classesClassSectionId=${selectedSection}&preLunch=${formData.prePostLunchStatus}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token()}`
        }
      })
      const responseData = await response.json();
      const filteredData = responseData
        .filter((item) => {
          return (
            item.classesClassSectionId == selectedSection &&
            (item.preLunch === formData.prePostLunchStatus || item.postLunch === formData.prePostLunchStatus) &&
            item.date === formattedDate
          );
        })
        .map((item, index) => {
          // const matchingData = studentData.find(
          //   (data) => data.id === item.student.id
          // );

          // if (matchingData) {

          // }

          const attendanceStatusText = item.attendanceStatus
            ? "present"
            : "absent";
          const formattedDate = new Date(item.date).toLocaleDateString(
            "en-IN",
            {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }
          );

          return {
            rollNumber: item.student.studentEnrollments[0].studentRollNumber,
            studentName: item.student.name,
            attendanceStatus: attendanceStatusText,
            date: formattedDate,
            class:
              item.student.studentEnrollments[0].classesClassSection.class.name,
            section:
              item.student.studentEnrollments[0].classesClassSection
                .classSection.name,
            srNo: index + 1
          };
        })
        .filter(Boolean); // Filter out any null values

      // Update the table data with filtered data
      setProcessData(filteredData);
      setShowTable(true);
    } catch (error) {
      console.log(error)
    }

    // const filteredData = apiData
    //   .filter((item) => {
    //     return (
    //       item.classesClassSectionId == selectedSection &&
    //       (item.preLunch === formData.prePostLunchStatus || item.postLunch === formData.prePostLunchStatus) &&
    //       item.date === formattedDate
    //     );
    //   })
    //   .map((item) => {
    //     const matchingData = studentData.find(
    //       (data) => data.id === item.studentId
    //     );

    //     if (matchingData) {
    //       const attendanceStatusText = item.attendanceStatus
    //         ? "present"
    //         : "absent";
    //       const formattedDate = new Date(item.date).toLocaleDateString(
    //         "en-IN",
    //         {
    //           day: "2-digit",
    //           month: "2-digit",
    //           year: "numeric",
    //         }
    //       );

    //       return {
    //         rollNumber: matchingData.studentEnrollments[0].studentRollNumber,
    //         studentName: matchingData.name,
    //         attendanceStatus: attendanceStatusText,
    //         date: formattedDate,
    //         class:
    //           matchingData.studentEnrollments[0].classesClassSection.class.name,
    //         section:
    //           matchingData.studentEnrollments[0].classesClassSection
    //             .classSection.name,
    //       };
    //     }

    //     return null; // Handle the case where matchingData is not found
    //   })
    //   .filter(Boolean); // Filter out any null values

    // // Update the table data with filtered data
    // setProcessData(filteredData);

    // console.log(filteredData, ";;;");
    // setShowTable(true);

    // Reset form errors
    setFormErrors({
      ...formErrors,
      class: false,
      sections: false,
      name: false,
      selectDate: false,
    });
  };

  return (
    <>
      <div className="content-header">View Attendance</div>

      <>
        <Row>
          <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
            <DatePickerPast
              label="DOB*"
              value={formData.selectDate || ""}
              onChange={(name, date, selectedDate) => {
                setFormData({ ...formData, selectDate: date });
                setFormErrors({ ...formErrors, selectDate: false });
              }}
              required
              name="DOB"
              placeholder="Select selectDate"
              dateFormat="dd-MM-yyyy"
              isValidDate={(date) => true}
              feedbackMessage="Please select selectDate."
            />
            {formErrors.selectDate && (
              <div className="error-message">Please select a selectDate.</div>
            )}
          </Col>
          <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
            <SelectDropdown
              label="Class"
              // value={formData.classId || ""}
              value={
                dropDowns.class
                  ? { value: dropDowns.class, label: dropDowns.class }
                  : dropDowns.class
              }
              placeholder="select class"
              onChange={(selectedOption) => {
                handleChange("classId", selectedOption);
                setSelectedClass(selectedOption.value);
                setDropDowns({ ...dropDowns, class: selectedOption.label });
                setFormErrors({ ...formErrors, class: false });
                setProcessData([])
              }}
              options={classesClassSectionData.map((classItem) => ({
                value: classItem.id,
                label: classItem.name,
                // classesClassSectionDataId:classItem.classSections
              }))}
              required
              feedbackMessage="Please select a valid class"
            // placeholder="Select class"
            />
            {formErrors.class && (
              <div className="error-message">Please Select class.</div>
            )}
          </Col>

          {formData.classId &&
            selectedClassData &&
            selectedClassData.classSections.length > 0 && (
              <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                <SelectDropdown
                  label="Section"
                  // value={formData.sectionId || ""}
                  value={
                    dropDowns.sections
                      ? {
                        value: dropDowns.sections,
                        label: dropDowns.sections,
                      }
                      : dropDowns.sections
                  }
                  placeholder="Select section"
                  onChange={(selectedOption) => {
                    handleChange("sectionId", selectedOption);
                    setSelectedSection(selectedOption.value);
                    setDropDowns({
                      ...dropDowns,
                      sections: selectedOption.label,
                    });
                    setFormErrors({ ...formErrors, sections: false });
                    setProcessData([])
                  }}
                  options={selectedClassData.classSections.map((section) => ({
                    key: section.id,
                    value: section.classesclassSection,
                    label: section.name,
                  }))}
                  required
                  feedbackMessage="Please select a valid section"
                />
                {formErrors.sections && (
                  <div className="error-message">Please Select Section.</div>
                )}
              </Col>
            )}

          <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
            <SelectDropdown
              value={
                dropDowns.name
                  ? { value: dropDowns.name, label: dropDowns.name }
                  : null
              }
              // value={selectedPostId}
              className="input-style"
              options={prePostOptions.map((option) => ({
                id: option.id,
                value: option.value,
                label: option.name,
              }))}
              onChange={(selectedOption) => {
                setFormData({
                  ...formData,
                  prePostLunchStatus: selectedOption.value,
                });
                setProcessData([])
                // setSelectedPostId(selectedOption.value);
                setDropDowns({ ...dropDowns, name: selectedOption.label });
                setFormErrors({ ...formErrors, name: false });
              }}
              placeholder="Select Pre/Post Attendance"
              feedbackMessage="Please select a Pre/Post Attendance."
            />
            {formErrors.name && (
              <div className="error-message">
                Please Select Pre/Post Attendance.
              </div>
            )}
          </Col>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1%",
            }}
          >
            <button
              className="class_search" // Apply appropriate styling
              onClick={handleSearchClick} // Attach the click event handler
            >
              Search
            </button>
          </div>
        </Row>
      </>
      {processData === null ? (
        <center>
          <p>Loading...</p>
        </center>
      ) : processData.length === 0 ? (
        <center style={{ color: "blue", margin: "1rem" }}>
          <p>No records available</p>
        </center>
      ) : (
        <Table data={processData} columns={columns} hover={true} />
      )}
    </>
  );
};
