import AWS from 'aws-sdk';
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";

// Initialize the S3 client
const s3 = new AWS.S3({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const bucketName = process.env.REACT_APP_BUCKET_NAME

// Function to upload a file to S3
export const uploadFile = (file, options) => {
    return new Promise((resolve, reject) => {
        s3.upload({
            Bucket: options.bucketName,
            Key: options.fileName,
            Body: file,
        }, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
};

// Function to upload multiple files
export const uploadFiles = async (files, folderName) => {

    const fileArray = Array.from(files);
    const bucketName = process.env.REACT_APP_BUCKET_NAME;

    // Prepare the files metadata to request pre-signed URLs from the backend
    const filesMetadata = fileArray.map(file => ({
        name: file.name,
        type: file.type
    }));

    const successfullyUploadedFiles = [];
    // Request pre-signed URLs from your backend
    try {
        const response = await fetch(`${API_PATH}/upload`, {
            method: "POST",
            body: JSON.stringify({
                files: filesMetadata,
                folder: `kidhood/${folderName}`,
                bucketName: bucketName
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token()}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to get pre-signed URLs: ${response.statusText}`);
        }

        const responseData = await response.json();
        const preSignedUrls = responseData.files;

        // Upload each file directly to S3 using the pre-signed URLs
        await Promise.all(
            fileArray.map(async (file, index) => {
                const { url, fileName } = preSignedUrls[index];

                const uploadResponse = await fetch(url, {
                    method: "PUT",
                    headers: {
                        "Content-Type": file.type, // Ensure the file type is set properly
                    },
                    body: file,
                });

                if (!uploadResponse.ok) {
                    throw new Error(`File upload failed: ${uploadResponse.statusText}`);
                }

                // If successful, track the file
                successfullyUploadedFiles.push(fileName);

            })
        );

        const urls = preSignedUrls.map((fileData) => ({ "Location": `https://${bucketName}.s3.ap-south-1.amazonaws.com/${fileData.fileName}` }));

        // Optionally return some success data
        return urls
    } catch (error) {
        console.error('Error uploading files:', error);
        if (successfullyUploadedFiles.length > 0) {
            try {
                await fetch(`${API_PATH}/upload`, {
                    method: "DELETE",
                    body: JSON.stringify({
                        files: successfullyUploadedFiles, // Send the list of successfully uploaded files
                        bucketName: bucketName
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token()}`,
                    },
                });
            } catch (rollbackError) {
                console.error("Error during rollback:", rollbackError);
            }
        }
        return { success: false, error: error.message };
    }
};




// Function to delete a file from S3
export const deleteFile = (fileUrl) => {
    return new Promise((resolve, reject) => {
        const fileName = fileUrl.split('/').slice(-3).join('/');
        s3.deleteObject({
            Bucket: process.env.REACT_APP_BUCKET_NAME,
            Key: fileName,
        }, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
};

// Function to delete multiple files
export const deleteFiles = async (fileUrls) => {
    const deletePromises = fileUrls.map((fileUrl) => {
        if (typeof fileUrl === 'string') {
            return deleteFile(fileUrl);
        }
        console.error("Invalid file URL:", fileUrl);
        return Promise.reject(new Error("Invalid file URL"));
    });
    return await Promise.all(deletePromises);
};