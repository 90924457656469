import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudentDataOnce } from '../StudentDetails/viewStudentSlicer';
import { SelectingValuesDropDownField } from '../../ReusableFields/DropDown/multipleSelectDropDown';
import { pictureUpload } from '../../Api/folderPath';
import { token } from '../../Api/token';
import { API_PATH } from '../../Api/api';
import { PostPictureUpload, setShowErrorMessage, setShowSuccessMessage } from './slice/picturesUploadSlice';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import SearchableDropDown from '../../ReusableFields/DropDown/searchableDroDown';
import { uploadFiles } from '../reUsableForm/uploadImage-docs';
import SelectDropdown from '../SelectDropdown';
import { fetchFranchiseClasswiseSectionsDataOnce } from "../Assessment/slicer/viewfranchiseclasswisesectionsSlicer";

const PicturesUploading = () => {
  const [showAllFields, setShowAllFields] = useState(false);

  const [dropDowns, setDropDowns] = useState({});
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClassData, setSelectedClassData] = useState([]);
  const [formData, setFormData] = useState({ studentIds: [], pictureType: '' });
  const [validated, setValidated] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selected, setSelected] = useState({ students: [] });
  const [selectAll, setSelectAll] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [formErrors, setFormErrors] = useState({ students: false, selectedDocuments: false });
  const [documents, setDocuments] = useState([]);
  const [dropdownDisabled, setDropdownDisabled] = useState(false);
  const [submit, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const franchiseeSchoolId = Number(sessionStorage.getItem('franchiseeSchoolId'));
  const FranchiseeSchoolId = Number(franchiseeSchoolId);
  const studentList = useSelector((state) => state.viewStudent.data) || [];
  const fileNamesArray = Array.from(
    { length: documents.length },
    (_, index) => documents[index].name
  );

  const handleClassChange = () => {
    setSelectedSection("");
    setSelectedClassData([]);
    setFormData({ ...formData, studentNameId: "" });
    setDropDowns({ ...dropDowns, studentName: "" });
    setFormErrors({ ...formErrors, studentName: false });
    setShowAllFields(true);

    const classSectionData = classesClassSectionData.find(
      (c) => c.id == selectedClass
    );
    setSelectedClassData(classSectionData);

    if (classSectionData && classSectionData.classSections.length === 0) {
      setSelectedSection(classSectionData.classesclassSection);
    }
  };

  useEffect(() => {
    handleClassChange(selectedClass);
  }, [selectedClass]);

  const getBorderColorImages = () => {
    if (documents && documents.length > 0) {
      return "#198754";
    } else if (validated) {
      return "red";
    } else {
      return "#ccc";
    }
  };

  const handleUploadImage = (event) => {
    const file = event.target.files;
    setDocuments(file);
    setFormErrors({ ...formErrors, selectedDocuments: false });
  };

  const showErrorMsg = useSelector((state) => state.pictureUpload.showErrorMessage);
  const errorMsg = useSelector((state) => state.pictureUpload.errorMessage);

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      setSubmit(true)
      const newFormErrors = {
        // students: selected.students.length === 0,
        selectedDocuments: documents?.length === 0,
      };

      const hasErrors = Object.values(newFormErrors).some(error => error);
      setFormErrors(newFormErrors);
      if (hasErrors) {
        setValidated(true);
        setSubmit(false)
        return;
      }

      let fileIds = [];

      if (documents.length > 0) {
        var uploadResponses = await uploadFiles(documents, "gallery");
      }

      const uploadLocations = uploadResponses.map((response) => response.Location);

      const data = {
        classId: selectedClass,
        sectionId: selectedSection,
        studentIds: formData.studentIds,
        pictureType: formData.pictureType,
        pictures: uploadLocations,
        franchiseeSchoolId: franchiseeSchoolId,
      };


      dispatch(PostPictureUpload(data));

      setFormData({ studentIds: [], pictureType: '' });
      setSelected({ students: [] });
      setDocuments([]);

      setShowSuccessMsg(true);
      setSuccessMsg('Pictures uploaded successfully.');

      setTimeout(() => {
        setShowSuccessMsg(false);
        setSuccessMsg('');
      }, 5000);
      setSubmit(false)

    } catch (error) {
      console.error(error)
      setSubmit(false)
    }
  };


  const handleCancel = () => {
    setSelectedImages([]);
    setSubmit(false)

    setFormData({
      studentIds: [],
      pictureType: ''
    });
    setSelected({
      students: []
    });
    setDocuments([]);
    setFormErrors({
      students: false,
      selectedDocuments: false
    });
    setValidated(false);
    setDropdownDisabled(false);
  };



  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });

    if (name === "studentNameId") {
      const selectedStudent = apiData.find(student => student[0].id === value);
      setFormData({ ...formData, studentId: selectedStudent ? selectedStudent.id : "" });
    } else if (name === "month") {
      setFormData({ ...formData, month: value });
    }

  };

  const classesClassSectionData = useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];

  useEffect(() => {
    if (classesClassSectionData.length == 0) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }
  }, [dispatch, classesClassSectionData]);

  const apiData = useSelector((state) => state.viewStudent.data) || [];

  useEffect(() => {
    if (apiData.length == 0) {
      dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
    }
  }, [dispatch, apiData]);

  const transformedData2 = apiData
    .filter(
      (student) =>
        student.studentEnrollments &&
        student.studentEnrollments.length > 0 &&
        student.studentEnrollments.some(
          (enrollment) => enrollment.classesClassSectionId === selectedSection
        )
    )
    .map((student) => ({
      value: student.id,
      label: student.name,
    }));

  const handleSelectAllChange = () => {
    const allSelected = !selectAll;
    setSelectAll(allSelected);
    if (allSelected) {
      const allStudentIds = transformedData2.map(student => student.value);
      setFormData({ ...formData, studentIds: allStudentIds });
      setSelected({ students: studentList });
      setDropdownDisabled(true);
    } else {
      setFormData({ ...formData, studentIds: [] });
      setSelected({ students: [] });
      setDropdownDisabled(false);
    }
    setFormErrors({ ...formErrors, students: false });
  };

  return (
    <Container>
      <Row>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Col>
            <span className="content-header">Pictures uploading</span>
            <Row>
              <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                <SelectDropdown
                  label="Class"
                  // value={formData.classId || ""}
                  value={
                    dropDowns.class
                      ? { value: dropDowns.class, label: dropDowns.class }
                      : dropDowns.class
                  }
                  placeholder="Select class"
                  onChange={(selectedOption) => {
                    handleChange("classId", selectedOption);
                    setSelectedClass(selectedOption.value);
                    setDropDowns({ ...dropDowns, class: selectedOption.label });
                    setFormErrors({ ...formErrors, class: false });
                  }}
                  options={classesClassSectionData.map((classItem) => ({
                    value: classItem.id,
                    label: classItem.name,
                    // classesClassSectionDataId:classItem.classSections
                  }))}
                  required
                  feedbackMessage="Please select a valid class"
                // placeholder="Select class"
                />
                {formErrors.class && (
                  <div className="error-message">Please Select class.</div>
                )}
              </Col>

              {formData.classId && selectedClassData && selectedClassData.classSections.length > 0 && (
                <>
                  <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                    <SelectDropdown
                      label="Section"
                      // value={formData.sectionId || ""}
                      value={
                        dropDowns.sections
                          ? { value: dropDowns.sections, label: dropDowns.sections }
                          : dropDowns.sections
                      }
                      placeholder="Select section"
                      onChange={(selectedOption) => {
                        handleChange("sectionId", selectedOption);
                        setSelectedSection(selectedOption.value);
                        setDropDowns({ ...dropDowns, sections: selectedOption.label });
                        setFormErrors({ ...formErrors, sections: false });
                      }}
                      options={selectedClassData.classSections.map((section) => ({
                        key: section.id,
                        value: section.classesclassSection,
                        label: section.name,
                      }))}
                      required
                      feedbackMessage="Please select a valid section"
                    />
                    {formErrors.sections && (
                      <div className="error-message">Please Select Section.</div>
                    )}
                  </Col>
                  {!selectAll && (
                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                      <SelectingValuesDropDownField
                        isMulti={true}
                        value={
                          dropDowns.studentName
                            ? {
                              value: dropDowns.studentName,
                              label: dropDowns.studentName,
                            }
                            : null
                        }
                        className="input-style"
                        options={transformedData2}
                        onChange={(selectedOptions) => {

                          const selectedArray = Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
                          const selectedIds = selectedArray.map((selectedOption) => selectedOption.value);

                          setFormData({ ...formData, "studentIds": [...selectedIds] });
                          setDropDowns({
                            ...dropDowns,
                            studentName: selectedOptions.label,
                          });
                          setFormErrors({
                            ...formErrors,
                            studentName: false,
                            class: false,
                          });
                        }}
                        placeholder="Select a student name"
                      />
                      {formErrors.studentName && (
                        <div className="error-message">Please Select a studentName.</div>
                      )}
                    </Col>
                  )}
                  <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Select All Students"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}


            </Row>

            <Row>
              <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                <SearchableDropDown
                  value={formData.pictureType || ''}
                  className="input-style"
                  onChange={(selectedOption) => {
                    setFormData({ ...formData, "pictureType": selectedOption.value });
                  }}
                  placeholder="Select view type"
                  options={[
                    { id: "individual", value: "Individual", label: "Individual" },
                    { id: "group", value: "Group", label: "Group" },
                  ]}
                  feedbackMessage="Please select View Type."
                  validated={validated}
                />
              </Col>

              <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                <Form.Group>
                  <div
                    className="uploadDoc"
                    style={{
                      border: `1px solid ${getBorderColorImages()}`,
                    }}
                  >
                    <div className="layout3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                          fill="#636363"
                        />
                      </svg>
                      <div className="title1">Upload&nbsp;Images&nbsp;&&nbsp;video's</div>
                    </div>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .mp4"
                      multiple
                      onChange={handleUploadImage}
                      style={{
                        width: "100%",
                        opacity: 0,
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: 1,
                        top: 0,
                        left: 0,
                      }}
                    />
                  </div>
                  <div>
                    {fileNamesArray?.map((name, index) => {
                      return <p key={index}>{name}</p>;
                    })}
                  </div>
                  <div style={{ fontSize: "0.7rem" }}>Please ensure each file is less than 50MB in size.</div>
                </Form.Group>

                {formErrors.selectedDocuments && (
                  <div className="error-message">Please Select Image .</div>
                )}
              </Col>
            </Row>

            <Col>
              <SuccessMessagePopup show={showSuccessMsg} onClose={() => setShowSuccessMsg(false)} successMessage={successMsg} />
              <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />

              <div style={{ display: "flex", justifyContent: "center", marginBottom: "1%" }}>
                <button className="clear-button-figma-div" type="reset" onClick={handleCancel}>
                  Clear
                </button>
                <button className="save-button-figma-div" type="submit" disabled={submit}>
                  {submit ? "Submitting..." : "Submit"}
                </button>
              </div>
            </Col>
          </Col>
        </Form>
      </Row>
    </Container>
  );
};

export default PicturesUploading;
