import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from "../../Api/api";
import { franchiseeSchoolId, token } from '../../Api/token';



const slice = createSlice(
  {
    name: 'viewStudent',
    initialState: {
      data: null,
      isLoading: false,
      hasError: false,
    },
    reducers: {
      fetchStudentDataStart: (state) => {
        state.isLoading = true;
        state.hasError = false;
      },
      fetchStudentDataSuccess: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      },
      fetchStudentDataFailure: (state) => {
        state.isLoading = false;
        state.hasError = true;
      }
    },
  },

);


export const { fetchStudentDataStart, fetchStudentDataSuccess, fetchStudentDataFailure } = slice.actions;


export const fetchStudentDataOnce = (id) => async (dispatch, getState) => {

  const classSectionIds = sessionStorage.getItem("classSectionIds")

  let ids = "";
  if (classSectionIds !== undefined && classSectionIds !== null) {
    const queryString = JSON.parse(classSectionIds).map(id => 'classSectionId=' + id).join('&');
    ids += queryString
  }

  const { data, isLoading } = getState().viewStudent;

  const tokenId = token();
  if (!isLoading) {
    // If data is null and the API is not loading, trigger the API request
    dispatch(fetchStudentDataStart());

    try {
      const franchisee_SchoolId = id ? id : franchiseeSchoolId()
      // Make your API request here using the fetch function
      const response = await fetch(API_PATH + `/franchiseeSchool/${franchisee_SchoolId}/students?isActive=true&${ids}`, {
        method: 'GET',
        headers: {
          "Authorization": `Bearer ${tokenId}`
        }
      });

      if (!response.ok) {
        throw new Error('Request failed'); // You can customize error handling
      }

      const data1 = await response.json();
      dispatch(fetchStudentDataSuccess(data1));
    } catch (error) {
      // Handle errors and dispatch the failure action
      dispatch(fetchStudentDataFailure());
    }
  }

}

export default slice.reducer;
