import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../observation/ObservationReport.css";
import { fetchMonthlyAssessmentDataOnce } from "../Assessment/slicer/viewMonthlyAssessmentSlicer";
import { getParentLoginData } from "../parent/parentLoginSlicer/parentLoginRequests";
import { Form, Space } from "antd";
import SearchableDropDown from "../../ReusableFields/DropDown/searchableDroDown";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
export const ObservationReports = () => {
  const [formData, setFormData] = useState({
    studentId: "",
    studentName: "",
  });

  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const schoolChildrenData = useSelector((state) => state.viewStudent.data) || [];
  useEffect(() => {
    if (schoolChildrenData.length === 0) {

      dispatch(fetchStudentDataOnce());
    }
  }, [dispatch, schoolChildrenData]);
  const totalData =
    useSelector((state) => state.viewMonthlyAssessment.data) || [];

  useEffect(() => {
    if (formData.studentId) {
      dispatch(fetchMonthlyAssessmentDataOnce(formData.studentId));
    }
  }, [dispatch, formData]);

  const transformedData = () => {
    if (formData.studentId) {
      const assessmentsForStudent = totalData.find(
        (item) => item.studentId === formData.studentId
      );
      if (assessmentsForStudent) {
        return assessmentsForStudent.preSchoolMonthlyAssessments;
      }
    }
  };

  const data = transformedData() || {}
  const months = ["June", "July", "August", "September", "October", "November", "December", "January", "February", "March"];
  const getCategoryClass = (category, item) => {
    if (category === "Social & Emotional Development") {
      return "highlight-yellow";
    } else if (category === "Language Development") {
      return "highlight-blue";
    } else if (category === "Numeracy Skill") {
      return "highlight-blue";
    } else if (category === "Physical Development") {
      return "highlight-yellow";
    } else if (category === "Creative Development") {
      return "highlight-blue";
    } else if (category === "Science & G.K") {
      return "highlight-yellow";
    } else if (category === "Rhymes & Songs") {
      return "highlight-yellow";
    } else {
      return "";
    }
  };

  const getCategoryHeaderClass = (category) => {
    if (category === "Social & Emotional Development") {
      return "highlight-yellow";
    } else if (category === "Language Development") {
      return "highlight-blue";
    } else if (category === "Numeracy Skill") {
      return "highlight-blue";
    } else if (category === "Physical Development") {
      return "highlight-yellow";
    } else if (category === "Creative Development") {
      return "highlight-blue";
    } else if (category === "Science & G.K") {
      return "highlight-yellow";
    } else if (category === "Rhymes & Songs") {
      return "highlight-yellow";
    } else {
      return "";
    }
  };

  const formatCellContent = (content) => {
    if (!content) return null;
    const [mainText, subText] = content.split(/\s*\(\s*(.*?)\s*\)\s*/);
    return (
      <div>
        {mainText}
        {subText && <div className="sub-text">({subText})</div>}
      </div>
    );
  };

  return (
    <>
      <div className="content-header ">Observation Report</div>
      {schoolChildrenData?.length > 0 ? (
        <Form noValidate validated={validated}>
          <Row style={{ justifyContent: "center" }}>
            <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
              <SearchableDropDown
                value={formData.studentName || ""}
                className="input-style"
                onChange={(selectedOption) => {
                  setFormData({
                    ...formData,
                    studentId: Number(selectedOption.id),
                    studentName: selectedOption.value,
                  });
                }}
                placeholder="Student Name"
                options={schoolChildrenData?.map((option, index) => ({
                  id: option.id,
                  value: option.name,
                  label: option.name,
                }))}
                feedbackMessage="Please select Fee Head."
                validated={validated}
              />
            </Col>
          </Row>
        </Form>
      ) : null}
      <table>
        <thead>
          <tr>
            <th></th>
            {months.map((month) => (
              <th key={month}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((category) => (
            <React.Fragment key={category}>
              <tr>
                <td colSpan={1} className={getCategoryHeaderClass(category)}><strong>{category}</strong></td>
              </tr>
              {Object.keys(data[category]).map((item) => (
                <tr key={item}>
                  <td className={getCategoryClass(category, item)}>{item}</td>
                  {months.map((month) => (
                    <td key={month}>{formatCellContent(data[category][item][month])}</td>
                  ))}
                </tr>
              ))}
              <tr>
                <td colSpan={months.length + 1} style={{ height: '5px', backgroundColor: '#F2F2F2' }}></td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
};
