/*
    authors: Rajeev
    date: 23-10-2023
    update: 23-10-2023 
    version: 1.0
*/

import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import SelectDropdown from "../SelectDropdown";
import { validationRegexPinNo, validationRegexOnlyAlpha1, validationRegexAddress } from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import Table from "../reUsableForm/table/table3";
import { TableCell } from "@mui/material";
import SkillTable from "../reUsableForm/table/table1";
import "../../components/reUsableForm/table/table.css";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { fetchAssessmentDataOnce } from "./slicer/viewAssessmentSlicer";
import { PostAssessmentReportRequest, setShowSuccessMessage, setShowErrorMessage } from "./slicer/addAssessmentReportSlicer";

const AssessmentReports = () => {
  const [validated, setValidated] = useState(false);
  const [dropDowns, setDropDowns] = useState({});
  const [subjects, setSubjects] = useState([]);

  const [formData, setFormData] = useState({
    studentNameId: null,
    assessmentId: null,
    class: "",
    marks: "",
    classId: "",
  });



  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
  const FranchiseeSchoolId = Number(franchiseeSchoolId);
  const [formErrors, setFormErrors] = useState({
    studentName: false,
    assessment: false,
    class: false,
  });

  const [showTable, setShowTable] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const showSuccessMsg = useSelector(
    (state) => state.addAssessmentReport.showSuccessMessage
  );
  const successMsg = useSelector(
    (state) => state.addAssessmentReport.successMessage
  );

  const showErrorMsg = useSelector(
    (state) => state.addAssessmentReport.showErrorMessage
  );

  const errorMsg = useSelector(
    (state) => state.addAssessmentReport.errorMessage
  );

  const assessmentOptions = [
    { id: 1, assessment: "FA1" },
    { id: 2, assessment: "FA2" },
    { id: 3, assessment: "FA3" },
    { id: 4, assessment: "SA1" },
    { id: 5, assessment: "SA2" },
  ];

  const columns = [
    { field: "subject", header: "Subject" },
    {
      field: "writtenMaxMarks",
      header: (
        <span>
          Written<br />
          Max Marks<span style={{ paddingLeft: '50px' }}></span>Scored
        </span>
      ),
      inputType: "number",
      handleMarksChange: (e, row) => handleMarksChange(e, row, "writtenMaxMarks")
    },
    {
      field: "oralsMaxMarks",
      header: (
        <span>
          Orals<br />
          Max Marks<span style={{ paddingLeft: '50px' }}></span>Scored
        </span>
      ),
      inputType: "number",
      handleMarksChange: (e, row) => handleMarksChange(e, row, "oralsMaxMarks")
    },
    {
      field: "totalMaxMarks",
      header: (
        <span>
          Total <span style={{ paddingRight: '10px' }}></span><br />
          Max Marks
        </span>
      ),
      inputType: "number"
    },
    {
      field: "totalScoreMarks",
      header: (
        <span>
          Total<br />
          Scored
        </span>
      ),
      inputType: "number"
    },
    { field: "grade", header: "Grade" },
    { field: "remarks", header: "Remarks" },
  ];

  const columns1 = [
    { field: "value", header: "Skill" },
    { field: "grade", header: "Grade" },
    { field: "", header: "" },
  ];

  const assessmentSkillData =
    useSelector((state) => state.viewAssessment.data) || [];
  useEffect(() => {
    if (assessmentSkillData.length === 0) {
      dispatch(fetchAssessmentDataOnce());
    }
  }, [assessmentSkillData, dispatch]);

  const viewStudentData = useSelector((state) => state.viewStudent.data) || [];

  useEffect(() => {
    if (viewStudentData.length === 0) {
      dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
    }
  }, [viewStudentData, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams({
          classId: formData.classId,
          franchiseeSchoolId: FranchiseeSchoolId,
        });
        if (formData.classId) {

          const url = `${API_PATH}/class/${formData.classId}/subjects`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          });

          if (response.ok) {
            const responseData = await response.json();
            setSubjects(responseData);
          }
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [formData.classId, token]);

  const handleSearchClick = (e) => {
    e.preventDefault();

    const newFormErrors = {
      studentName: !dropDowns.studentName,
      assessment: !dropDowns.assessment,
    };

    setFormErrors(newFormErrors);

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);

    if (hasErrors) {
      setValidated(true);
      return;
    }

    const filteredSkills = assessmentSkillData.filter(
      (skill) => skill.classId === formData.classId
    );

    const dynamicColumns1 = filteredSkills.map((skill) => ({
      skillId: skill.id,
      label: skill.name,
      value: skill.name,
    }));
    setSkillData(dynamicColumns1);
    const updatedData = subjects.map((row) => ({
      ...row,
      subject: row?.name || 0,
      oralScoreMarks: row?.oralScoreMarks || 0,
      totalMaxMarks: calculateTotalMaxMarks(row),
      totalScored: calculateTotalScoredMarks(row),
      grade: calculateGrade(formData?.marks || 0),
    }));
    setData(updatedData);
    setShowTable(true);
  };

  const renderGradeDropdown = (row) => {
    const handleGradeChange = (selectedGrade, row) => {
      setSkillData((prevData) => {
        const updatedData = prevData.map((data) =>
          data.skillId === row.skillId ? { ...data, grade: selectedGrade.value } : data
        );
        return updatedData;
      });
    };

    return (
      <TableCell key={`grade-${row.skillId}`}>
        <SelectDropdown
          value={row.grade?.value}
          options={[
            { value: "NA Not applicable", label: "NA Not applicable", id: 1 },
            { value: "IR Improvement required", label: "IR Improvement required", id: 2 },
            { value: "E-Excellent", label: "E-Excellent", id: 3 },
            { value: "G Good", label: "G-Good", id: 4 },
          ]}
          onChange={(selectedGrade) => handleGradeChange(selectedGrade, row)}
        />
      </TableCell>
    );
  };

  const calculateGrade = (totalScoreMarks, totalMaxMarks) => {
    const percentage = Math.ceil((totalScoreMarks / totalMaxMarks) * 100);
    if (percentage >= 90 && percentage <= 100) {
      return "A+";
    } else if (percentage >= 75 && percentage <= 89) {
      return "A";
    } else if (percentage >= 56 && percentage <= 74) {
      return "B";
    } else if (percentage >= 35 && percentage <= 55) {
      return "C";
    } else if (percentage >= 0 && percentage < 35) {
      return "D";
    } else {
      return "Grade";
    }
  };

  const handleMarksChange = (e, row, field) => {
    let inputMarks;
    let updatedRow;
    const values = e.target.value
    if (field === "remarks") {
      updatedRow = { ...row, "remarks": e.target.value };
    } else if (values !== '') {
      inputMarks = Number(e.target.value);
      updatedRow = { ...row, [field]: inputMarks };
    } else {
      updatedRow = { ...row, [field]: '' };
    }

    const writtenMaxMarks = parseFloat(updatedRow.writtenMaxMarks) || 0;
    const writtenScoreMarks = parseFloat(updatedRow.writtenScoreMarks) || 0;
    const oralsMaxMarks = parseFloat(updatedRow.oralsMaxMarks) || 0;
    const oralScoreMarks = parseFloat(updatedRow.oralScoreMarks) || 0;

    if (writtenScoreMarks > writtenMaxMarks) {
      return;
    }

    if (oralScoreMarks > oralsMaxMarks) {
      return;
    }

    const totalMaxMarks = writtenMaxMarks + oralsMaxMarks;
    const totalScoreMarks = writtenScoreMarks + oralScoreMarks;
    const updatedRowWithCalculations = {
      ...updatedRow,
      totalMaxMarks,
      totalScoreMarks,
      grade: calculateGrade(totalScoreMarks, totalMaxMarks)
    };

    // Update the state with the updated row
    setData((prevData) =>
      prevData.map((rowData) => (rowData === row ? updatedRowWithCalculations : rowData))
    );
  };

  const calculateTotalScoredMarks = (row) => {
    // Assuming written and oral scored marks are fields in your row data
    const writtenScored = parseFloat(row?.writtenMaxMarks) || 0;
    const oralScored = parseFloat(row?.oralsMaxMarks) || 0;
    return writtenScored + oralScored;
  };
  const calculateTotalMaxMarks = (row) => {
    // Assuming written and oral max marks are fields in your row data
    const writtenMax = parseFloat(row?.writtenMaxMarks) || 0;
    const oralMax = parseFloat(row?.oralsMaxMarks) || 0;
    return writtenMax + oralMax;
  };


  const calculateTotals = () => {
    const initialTotals = {
      writtenMaxMarks: 0,
      writtenMaxMarks: 0,
      writtenScoreMarks: 0,
      oralsMaxMarks: 0,
      oralScoreMarks: 0,
      totalMaxMarks: 0,
      totalScoreMarks: 0,
    };

    const totals = data.reduce((totals, row) => {
      totals.writtenMaxMarks += parseFloat(row.writtenMaxMarks) || 0;
      totals.writtenScoreMarks += parseFloat(row.writtenScoreMarks) || 0;
      totals.oralsMaxMarks += parseFloat(row.oralsMaxMarks) || 0;
      totals.oralScoreMarks += parseFloat(row.oralScoreMarks) || 0;
      totals.totalMaxMarks += parseFloat(row.totalMaxMarks) || 0;
      totals.totalScoreMarks += parseFloat(row.totalScoreMarks) || 0;
      return totals;
    }, initialTotals);

    totals.grade = calculateGrade(totals.totalScoreMarks, totals.totalMaxMarks);
    return totals;

  };

  const totals = calculateTotals();

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = skillData.map(({ skillId, grade }) => ({ skillId, grade }));

    const sendData = {

      activitiesAssessments: [
        {
          activitiesGiven: formData.activitiesGiven,
          activitiesSubmitted: formData.noOfSubmited,
          remarks: formData.remarks,
        },
      ],
      studentId: formData.studentNameId,
      assessment: formData.assessment,
      subjectsAssessments: data.map((obj) => ({
        writtenMaxMarks: obj?.writtenMaxMarks || 0,
        writtenScoreMarks: obj?.writtenScoreMarks || 0,
        oralMaxMarks: obj?.oralsMaxMarks || 0,
        oralScoreMarks: obj?.oralScoreMarks || 0,
        totalMaxMarks: obj?.totalMaxMarks || 0,
        totalScoreMarks: obj?.totalScoreMarks || 0,
        grade: obj?.grade || "",
        subjectId: obj?.id || "",
        remarks: obj?.remarks || "",
      })),

      skillsAssessments: updatedData,

    };
    dispatch(PostAssessmentReportRequest(sendData));

  };

  const handleClear = () => {
    setSkillData([]);
    setData([]);
    setFormData({
      studentNameId: null,
      assessmentId: null,
      class: "",
    });

    setDropDowns({});
    setShowTable(false)
    setValidated(false);
  };
  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };
  return (
    <>
      <div className="content-header">School Assessment</div>
      <Row>

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <SelectDropdown
            value={
              dropDowns.assessment
                ? { value: dropDowns.assessment, label: dropDowns.assessment }
                : null
            }
            className="input-style"
            options={assessmentOptions.map((option) => ({
              id: option.id,
              value: option.assessment,
              label: option.assessment,
            }))}
            onChange={(selectedOption) => {
              setFormData({
                ...formData,
                assessment: selectedOption.value,
              });
              setDropDowns({
                ...dropDowns,
                assessment: selectedOption.value,
              });
              setFormErrors({
                ...formErrors,
                assessment: false,
              });
            }}
            placeholder="Select a assessment"
          />
          {formErrors.assessment && (
            <div className="error-message">Please Select an assessment.</div>
          )}
        </Col>

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <SelectDropdown
            value={
              dropDowns.studentName
                ? {
                  value: dropDowns.studentName,
                  label: dropDowns.studentName,
                }
                : null
            }
            className="input-style"
            options={viewStudentData.map((option) => ({
              id: option.id,
              value: option.name,
              label: option.name,
              class:
                option.studentEnrollments[0]?.classesClassSection?.class
                  ?.name || "", // Access class information
              classId:
                option.studentEnrollments[0]?.classesClassSection?.class?.id ||
                "",
            }))}
            onChange={(selectedOption) => {
              setFormData({
                ...formData,
                studentNameId: selectedOption.id,
                class: selectedOption.class,
                classId: selectedOption.classId, // Update the class in the form data
              });
              setDropDowns({
                ...dropDowns,
                studentName: selectedOption.value,
              });
              setFormErrors({
                ...formErrors,
                studentName: false,
                class: false, // Update the class in the form errors
              });
            }}
            placeholder="Select a student name"
          />
          {formErrors.studentName && (
            <div className="error-message">Please Select a studentName.</div>
          )}
        </Col>

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <TextInputField
            name="class"
            placeholder="Class*"
            textType="Alphabet"
            required
            value={formData.class}
            feedbackMessage="Please enter a class."
            validated={validated}
            validationRegex={validationRegexOnlyAlpha1}
            validationErrorMessage="Please enter a valid class."
          />
        </Col>

        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
          <div style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
          }}>

            <button
              className="save-button-figma-div"
              onClick={handleSearchClick}
            >
              Search
            </button>
          </div>
        </Col>
      </Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {showTable && (
          <Row>
            <Col sm={12} xs={12} md={6} lg={12} className="rowCss">
              {data === null ? (
                <center>
                  <p>Loading...</p>
                </center>
              ) : data.length === 0 ? (
                <center style={{ color: "blue", margin: "1rem" }}>
                  <p>No records available</p>
                </center>
              ) : (
                <>
                  <Table
                    data={data}
                    columns={columns}
                    handleMarksChange={handleMarksChange}
                  />
                  <div className="totals-container">
                    <span className="total-item0">Total</span>
                    <div className="total-item1">{totals.writtenMaxMarks}</div>
                    <div className="total-item2">{totals.writtenScoreMarks}</div>
                    <div className="total-item3">{totals.oralsMaxMarks}</div>
                    <div className="total-item4">{totals.oralScoreMarks}</div>
                    <div className="total-item5">{totals.totalMaxMarks}</div>
                    <div className="total-item6">{totals.totalScoreMarks}</div>
                    <div className="total-item7"><b>{totals.grade}</b></div>
                  </div>

                </>
              )}
            </Col>



            <Col className="rowCss">
              <div className="content-header ">Skill Development</div>
              <SkillTable
                data={skillData}
                columns={columns1}
                hover={true}
                renderGradeDropdown={renderGradeDropdown}
              />
            </Col>
            <div className="content-header mt-4">Activities/Project Create</div>
            <Row className="mt-4" sm={12} xs={12} md={6} lg={12}>

              <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                <TextInputField
                  name="No Of Given"
                  placeholder="No of Given"
                  className="fcontrol"
                  text="Alphabet"
                  required
                  validated={validated}
                  value={formData.activitiesGiven || ""}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      activitiesGiven: event.target.value
                    });
                  }}
                  feedbackMessage="Please enter no of given."
                  validationRegex={validationRegexPinNo}
                  validationErrorMessage="Please enter valid no of given."
                />
              </Col>

              <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                <TextInputField
                  name="No of Submited"
                  placeholder="No of Submited"
                  className="fcontrol"
                  text="Alphabet"
                  required
                  validated={validated}
                  value={formData.noOfSubmited || ""}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      noOfSubmited: event.target.value,
                    });
                  }}
                  feedbackMessage="Please enter no of submited."
                  validationRegex={validationRegexPinNo}
                  validationErrorMessage="Please enter valid no of submited."
                />
              </Col>

              <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                <TextInputField
                  name="Remarks"
                  placeholder="Remarks"
                  className="fcontrol"
                  textType="Alphabet"
                  required
                  validated={validated}
                  value={formData.remarks || ""}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      remarks: event.target.value,
                    });
                  }}
                  feedbackMessage="Please enter Remarks."
                  validationRegex={validationRegexAddress}
                  validationErrorMessage="Please enter valid Remarks."
                />
              </Col>

            </Row>
          </Row>
        )}
        <SuccessMessagePopup
          show={showSuccessMsg}
          onClose={handleCloseSuccessPopup}
          successMessage={successMsg}
        />
        <ErrorMessagePopup
          show={showErrorMsg}
          onClose={handleCloseErrorPopup}
          errorMessage={errorMsg}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={
              handleClear
              //   () => {
              //   setFormData({
              //     studentNameId: null,
              //     assessmentId: null,
              //     class: "",
              //   });
              //   setValidated(false);
              //   setDropDowns({});
              //   setFormErrors({});
              // }
            }
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
    </>
  );
};

export default AssessmentReports;
