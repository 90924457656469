import { createSlice } from "@reduxjs/toolkit";
import { API_PATH } from "../../../Api/api";
import { token, franchiseeSchoolId } from "../../../Api/token";
import { fetchWithAuthPost } from "../../Auth/dependency_post";
import { responseFormat } from "../../Auth/errorResponse";


const slice = createSlice(
    {
        name: "eventStatus",
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchEventStatusData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchEventStatusSuccess: (state, action) => {
                state.isLoading = true;
                state.data = state.data ? [...state.data, ...action.payload] : action.payload;
            },
            fetchEventStatusFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
            setEventStatus: (state, action) => {
                state.data = action.payload
            }
        },
    },
);

export const { fetchEventStatusData, fetchEventStatusSuccess, fetchEventStatusFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage,
    setShowErrorMessage, setEventStatus } = slice.actions;

export const postEventStatusRequest = (postData) => async (dispatch) => {
    dispatch(fetchEventStatusData());
    const tokenId = token();

    // Retrieve franchiseeSchoolId from sessionStorage
    //  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");

    try {
        const response = await fetchWithAuthPost(API_PATH + `/franchisee-school/${franchiseeSchoolId()}/event-status`, 'POST', tokenId, postData);
        if (!response) {
            const errorData = await response;
            throw new Error(errorData.errors);
        } else {
            const responseData = await response;
            // responseData.data.map(item => );
            const sendData = {
                ...responseData.data,
                eventHoliday: { "name": postData.eventHolidayName }
            }
            dispatch(fetchEventStatusSuccess([sendData]))
            dispatch(setSuccessMessage("Successfully Created!"));
            dispatch(setShowSuccessMessage(true));

        }
    }
    catch (error) {

        console.error("....error", error)
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};
export default slice.reducer;