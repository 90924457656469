import React, { useEffect, useState, useMemo } from "react";
import TextInputField from '../reUsableForm/textInputField';
import { amountWithDecimalPattern } from '../reUsableForm/regex';
import { useDispatch, useSelector } from 'react-redux';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { Col, Form, Row } from 'react-bootstrap';
import { revenueSheet, setShowErrorMessage, setShowSuccessMessage } from './slice/revenueSheetSlicer';
import SearchableDropDownField from "../../ReusableFields/DropDown/SearchableDropDownField";
import { fetchFranchiseClasswiseSectionsDataOnce } from "../Assessment/slicer/viewfranchiseclasswisesectionsSlicer";
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import SelectDropdown from "../SelectDropdown";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import { fetchRevenueTypeView } from "./slice/revenueTypeViewSlicer";
import FormatDate from "../reUsableForm/dateFormat";

const RevenueSheet = () => {
    const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
    const FranchiseeSchoolId = Number(franchiseeSchoolId);
    const [selectedSection, setSelectedSection] = useState("");
    const [selectedClass, setSelectedClass] = useState("");
    const dispatch = useDispatch();

    const apiDataa = useSelector((state) => state.revenueTypeView.data) || [];
    const postData = useSelector((state) => state.revenueType.data) || [];

    const combinedData = [...(apiDataa || []), ...postData]

    const classesClassSectionData =
        useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];

    useEffect(() => {
        if (apiDataa.length === 0) {
            dispatch(fetchRevenueTypeView());
        }
    }, [apiDataa, dispatch]);

    useEffect(() => {
        if (classesClassSectionData.length === 0) {
            dispatch(fetchFranchiseClasswiseSectionsDataOnce());
        }
    }, [dispatch, classesClassSectionData]);

    const [formData, setFormData] = useState({
        date: "",
        revenueTypeId: "",
        feeAmount: '',
        studentId: '',
        modeOfPayment: '',
    });

    const [validated, setValidated] = useState(false);
    const [formErrors, setFormErrors] = useState({
        modeOfPayment: "",
        sections: false,
        class: false,
        date: "",
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const newFormErrors = {
            date: !formData.date,
            revenueTypeId: !formData.revenueTypeId,
            modeOfPayment: !formData.modeOfPayment,
            class: !formData.classId,
            sectionId: !formData.sectionId, // Add sectionId validation
            studentId: !formData.studentId,
        }
        const hasErrors = Object.values(newFormErrors).some((error) => error);
        setFormErrors(newFormErrors);
        if (hasErrors) {
            setValidated(true);
            return;
        }
        const sendData = {
            ...formData,
            date: FormatDate(formData.date),
            franchiseeSchoolId: franchiseeSchoolId,
            revenueTypeId: formData.revenueTypeId.id,
        }
        dispatch(revenueSheet(sendData));
        handleClear()
    }
    const handleClear = () => {
        setFormData({
            date: "",
            class: '',
            revenueType: '',
            modeOfPayment: '',
        });
        setValidated(false);
        setFormErrors({
            class: ""
        })
        setDropDowns({
            class: false,
            sections: false
        })
    }

    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        // handleCancel();
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));
    };

    const expensesAmount = [
        { value: "UPI", label: "UPI" },
        { value: "Cheque", label: "Cheque" },
        { value: "Cash", label: "Cash" },
    ];
    const [dropDowns, setDropDowns] = useState({
        class: false,
    });

    useEffect(() => {
        if (classesClassSectionData.length == 0) {
            dispatch(fetchFranchiseClasswiseSectionsDataOnce());
        }
    }, [dispatch, classesClassSectionData]);
    const apiData = useSelector((state) => state.viewStudent.data) || [];

    useEffect(() => {
        if (apiData.length == 0) {
            dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
        }
    }, [dispatch, apiData]);

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: false });
    };
    const [selectedClassData, setSelectedClassData] = useState([]);
    const [showTable, setShowTable] = useState(false);

    const handleClassChange = () => {
        setSelectedSection("");
        setSelectedClassData([]);
        setFormData({ ...formData, studentNameId: "" });
        setDropDowns({ ...dropDowns, studentName: "" });
        setFormErrors({ ...formErrors, studentName: false });
        setShowTable(false);
        setShowAllFields(false);

        const classSectionData = classesClassSectionData.find(
            (c) => c.id == selectedClass
        );
        setSelectedClassData(classSectionData);

        if (classSectionData && classSectionData.classSections.length === 0) {
            setSelectedSection(classSectionData.classesclassSection);
        }
    };
    useEffect(() => {
        handleClassChange(selectedClass);
    }, [selectedClass]);

    const [showAllFields, setShowAllFields] = useState(false);

    const transformedData2 = apiData
        .filter(
            (student) =>
                student.studentEnrollments &&
                student.studentEnrollments.length > 0 &&
                student.studentEnrollments.some(
                    (enrollment) => enrollment.classesClassSectionId === selectedSection
                )
        )
        .map((student) => ({
            value: student.id,
            label: student.name,
        }));

    const showSuccessMsg = useSelector((state) => state.revenueSheet.showSuccessMessage);
    const successMsg = useSelector((state) => state.revenueSheet.successMessage);

    const showErrorMsg = useSelector((state) => state.revenueSheet.showErrorMessage);
    const errorMsg = useSelector((state) => state.revenueSheet.errorMessage);

    return (
        <>
            <h4 className="content-header ">Revenue</h4>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col sm={12} xs={12} md={6} lg={3}>
                        <DatePickerPast
                            label="DOB*"
                            value={formData.date || ""}
                            onChange={(name, date, selectedDate) => {
                                setFormData({ ...formData, date: date });
                                setFormErrors({ ...formErrors, date: false });
                            }}
                            required
                            name="DOB"
                            placeholder="Select date"
                            dateFormat="dd-MM-yyyy"
                            isValidDate={(date) => true}
                            feedbackMessage="Please select selectDate."
                        />
                        {formErrors.date && (
                            <div className="error-message">Please select a select date.</div>
                        )}
                    </Col>

                    <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                        <SelectDropdown
                            label="Class"
                            value={
                                dropDowns.class
                                    ? { value: dropDowns.class, label: dropDowns.class }
                                    : null
                            }
                            placeholder="select class"
                            onChange={(selectedOption) => {
                                handleChange("classId", selectedOption);
                                setSelectedClass(selectedOption.value);
                                setDropDowns({ ...dropDowns, class: selectedOption.label });
                                setFormErrors({ ...formErrors, class: false });
                            }}
                            options={classesClassSectionData.map((classItem) => ({
                                value: classItem.id,
                                label: classItem.name,
                                classesClassSectionDataId: classItem.classSections
                            }))}
                            required
                            feedbackMessage="Please select a valid class"
                        />
                        {formErrors.class && (
                            <div className="error-message">Please select class.</div>
                        )}
                    </Col>

                    {formData.classId && selectedClassData && selectedClassData.classSections.length > 0 && (
                        <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                            <SelectDropdown
                                label="Section"
                                value={
                                    dropDowns.sections
                                        ? { value: dropDowns.sections, label: dropDowns.sections }
                                        : null
                                }
                                placeholder="Select section"
                                onChange={(selectedOption) => {
                                    handleChange("sectionId", selectedOption);
                                    setSelectedSection(selectedOption.value);
                                    setDropDowns({ ...dropDowns, sections: selectedOption.label });
                                    setFormErrors({ ...formErrors, sectionId: false });
                                }}
                                options={selectedClassData.classSections.map((section) => ({
                                    key: section.id,
                                    value: section.classesclassSection,
                                    label: section.name,
                                }))}
                                required
                                feedbackMessage="Please select a valid section"
                            />
                            {formErrors.sectionId && (
                                <div className="error-message">Please select section.</div>
                            )}
                        </Col>
                    )}

                    {formData.classId && selectedClassData && selectedClassData.classSections.length > 0 && (

                        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                            <SelectDropdown
                                value={
                                    dropDowns.studentName
                                        ? {
                                            value: dropDowns.studentName,
                                            label: dropDowns.studentName,
                                        }
                                        : null
                                }
                                className="input-style"
                                options={transformedData2}
                                onChange={(selectedOption) => {
                                    setFormData({
                                        ...formData,
                                        studentNameId: selectedOption.value,
                                        studentId: selectedOption.value,
                                        class: selectedOption.class,
                                    });
                                    setDropDowns({
                                        ...dropDowns,
                                        studentName: selectedOption.label,
                                    });
                                    setFormErrors({
                                        ...formErrors,
                                        studentId: false,
                                        class: false,
                                    });
                                }}
                                placeholder="Select a student name"
                            />
                            {formErrors.studentId && (
                                <div className="error-message">Please select student name.</div>
                            )}
                        </Col>
                    )}


                    <Col xs={12} md={6} lg={3} className="rowCss">
                        <SelectDropdown
                            isSearchable
                            value={formData.revenueTypeId ? { id: formData.revenueTypeId.id, label: formData.revenueTypeId.label } : null}
                            options={combinedData.map((option) => ({
                                id: option.id,
                                value: option.name,
                                label: option.name
                            }))}
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, revenueTypeId: selectedOption });
                                setFormErrors({ ...formErrors, revenueTypeId: !selectedOption });
                            }}
                            placeholder="Select revenue"
                        />
                        {formErrors.revenueTypeId && (
                            <div className="error-message">
                                Please select revenue.
                            </div>
                        )}
                    </Col>

                    <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
                        <TextInputField
                            name='revenueAmount'
                            placeholder='Enter revenue amount'
                            textType='Alphabet'
                            validated={validated}
                            required='true'
                            feedbackMessage='Revenue amount is required!'
                            validationErrorMessage='Give valid revenue amount!'
                            value={formData.feeAmount}
                            validationRegex={amountWithDecimalPattern}
                            onChange={(event) => { setFormData({ ...formData, feeAmount: event.target.value }) }}
                        />
                    </Col>

                    <Col xs={12} md={6} lg={3} className="rowCss">
                        <SearchableDropDownField
                            value={expensesAmount.find(option => option.value === formData.modeOfPayment) || ""}
                            placeholder="Select mode of payment"
                            onChange={(selectedOption) => {
                                setFormData({ ...formData, modeOfPayment: selectedOption?.value });
                                setFormErrors({ ...formErrors, modeOfPayment: !selectedOption });
                            }}
                            options={expensesAmount}
                            required
                        />
                        {formErrors.modeOfPayment && (
                            <div className="error-message">Please select class.</div>
                        )}
                    </Col>

                </Row>
                <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
                <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1%",
                        marginTop: "5%"
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={handleClear}
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit">
                        Submit
                    </button>
                </div>

            </Form>
        </>
    )
}

export default RevenueSheet