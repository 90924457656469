import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
} from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import AddIcon from "@mui/icons-material/Add";
import {

  validationRegexOnlyPincode,
  validationRegexMobileNo,
  validationRegexOnlyAlpha,
  emailRegex,
  validationRegexAddress,
} from "../reUsableForm/regex";
import EmailInputField from "../../ReusableFields/EmailFields/EmailField";
import SelectDropdown from "../SelectDropdown";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import {
  postParentRequest,
  setShowSuccessMessage,
  setShowErrorMessage,
} from "./addParentsSlicer";
import { useDispatch, useSelector } from "react-redux";
import { fetchAreaData } from "../EnquiryDetails/slice/areaSlicer";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import FileUpload from "../../ReusableFields/FileUpload/fileUpload";
import { API_PATH } from "../../Api/api";
import { token } from "../../Api/token";
import { useNavigate } from "react-router-dom";
import DatePickerPast from "../../ReusableFields/DateFields/DatePickerPast";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { deleteFile, deleteFiles, uploadFiles } from "../reUsableForm/uploadImage-docs";
import FormatDate from "../reUsableForm/dateFormat";

const AddParent = () => {
  const [validated, setValidated] = useState(false);
  const [permanentAddress, setPermanentAddress] = useState({
    type: "permanent",
  });
  const [currentAreaId, setCurrentAreaId] = useState({});
  const [permanentAreaId, setPermanentAreaId] = useState({});
  const [currentAddress, setCurrentAddress] = useState({ type: "current" });
  const [dropDowns, setDropDowns] = useState({});
  const [fatherData, setFatherData] = useState({});
  const [motherData, setMotherData] = useState({});
  const [guardianData, setGuardianData] = useState({});
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    area: false,
    selectedFatherDocuments: false,

    pincode: false,
    address: false,
  });
  const navigate = useNavigate();
  const [currentAddressErrors, setCurrentAddressErrors] = useState({
    area: false,
    state: false,
    city: false,
    pincode: false,
    address: false,
  });
  const navigateToAnotherScreen = () => {
    navigate("/AddArea");
  };
  const [parentShowErrorMsg, setParentShowErrorMsg] = useState(false);
  const [parentErrorMsg, setParentErrorMsg] = useState("");
  const [permanentArea, setPermanentArea] = useState({});
  const [currentArea, setCurrentArea] = useState({});
  const [fatherImage, setFatherImage] = useState(null);
  const [motherImage, setMotherImage] = useState(null);
  const [guardianImage, setGuardianImage] = useState(null);
  const [selectedMotherImage, setSelectedMotherImage] = useState([]);
  const [selectedGuardianImage, setSelectedGuardianImage] = useState([]);
  const [fatherDocuments, setFatherDocuments] = useState([]);
  const [selectedMotherDocuments, setSelectedMotherDocuments] = useState([]);
  const [motherDocuments, setMotherDocuments] = useState([]);
  const [selectedGuardianDocuments, setSelectedGuardianDocuments] = useState(
    []
  );
  const [saving, setSaving] = useState(false)
  const [guardianDocument, setGuardianDocument] = useState([]);

  const fileNamesArray = Array.from(
    { length: fatherDocuments.length },
    (_, index) => fatherDocuments[index].name
  );
  const fileNamesArray1 = Array.from(
    { length: motherDocuments.length },
    (_, index) => motherDocuments[index].name
  );
  const fileNamesArray2 = Array.from(
    { length: guardianDocument.length },
    (_, index) => guardianDocument[index].name
  );


  const handleUploadFatherImage = (event) => {
    const file = event.target.files;
    setFatherImage(file);
  };



  const handleUploadMotherImage = (event) => {
    const file = event.target.files;
    setMotherImage(file);
  };



  const handleUploadGuardianImage = (event) => {
    const file = event.target.files;
    setGuardianImage(file);
  };



  const handleFileUpload = async (file, fileError) => {
    try {
      if (!file) {
        console.error("No images to upload");
        return;
      }

      const data = new FormData();
      data.append("file", file[0]);

      const response = await fetch(`${API_PATH}/uploadFile`, {
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setSelectedGuardianImage(result.fileId);
        setFormErrors({ ...formErrors, [fileError]: false });
        return result.fileId.toString();
      } else {
        console.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleFatherDocumentChange = (event) => {
    const files = event.target.files;
    setFatherDocuments(files);
  };

  const handleFilesUpload = async (file, fileError) => {
    try {
      if (!file) {
        console.error("No images to upload");
        return;
      }

      const data = new FormData();

      for (let i = 0; i < file.length; i++) {
        data.append("files", file[i]);
      }

      const response = await fetch(`${API_PATH}/uploadFiles`, {
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${token()}`, // Assuming token() retrieves the token
        },
      });

      if (response.ok) {
        const result = await response.json();
        const fileIds = result.files.map((id) => String(id.fileId));


        return fileIds; // Return the updated fileIds for potential usage
      } else {
        console.error("File upload failed");
        return null; // Indicate failure (optional, but recommended for clarity)
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return null; // Indicate failure (optional, but recommended for clarity)
    }
  };

  const handleMotherDocumentChange = (event) => {
    const files = event.target.files;
    setMotherDocuments(files);
  };



  const handleGuardianDocumentChange = (event) => {
    const files = event.target.files;
    setGuardianDocument(files);
  };



  useEffect(() => {
    if (currentAreaId) {
      setCurrentAddress({
        ...currentAddress,
        cityId: currentAreaId.city,
        stateId: currentAreaId.state,
      });
    }
  }, [currentAreaId]);
  useEffect(() => {
    if (permanentAreaId) {
      setPermanentAddress({
        ...permanentAddress,
        cityId: permanentAreaId.city,
        stateId: permanentAreaId.state,
      });
    }
  }, [permanentAreaId]);

  const apiData = useSelector((state) => state.areaDetails.data) || [];

  const dispatch = useDispatch();

  const showSuccessMsg = useSelector(
    (state) => state.addParent.showSuccessMessage
  );
  const successMsg = useSelector((state) => state.addParent.successMessage);

  const showErrorMsg = useSelector((state) => state.addParent.showErrorMessage);
  const errorMsg = useSelector((state) => state.addParent.errorMessage);

  useEffect(() => {
    if (apiData.length === 0) {
      dispatch(fetchAreaData());
    }
  }, [apiData, dispatch]);

  const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
  const FranchiseeSchoolId = Number(franchiseeSchoolId);



  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSaving(true)
    const errorPopup = {};

    if (fatherImage) {
      const uploadResponses1 = await uploadFiles((fatherImage), "guardians/profile-images");
      var imageId = uploadResponses1.map((response) => response.Location) || null;
    }

    if (motherImage) {
      const uploadResponses2 = await uploadFiles((motherImage), "guardians/profile-images");
      var imageId1 = uploadResponses2.map((response) => response.Location) || null;
    }
    if (guardianImage) {
      const uploadResponses3 = await uploadFiles((guardianImage), "guardians/profile-images");
      var imageId2 = uploadResponses3.map((response) => response.Location) || null;
    }

    if (fatherDocuments.length > 0) {
      const uploadResponses1 = await uploadFiles((fatherDocuments), "guardians/documents");
      var fileId = uploadResponses1.map((response) => response.Location) || null;
    }

    var fatherUpdatedData;
    if (fatherData && Object.keys(fatherData).length > 0) {
      fatherUpdatedData = {
        ...fatherData,
        dateOfBirth: FormatDate(fatherData.dateOfBirth),
        primaryContactNumber: Number(fatherData.primaryContactNumber),
        secondaryContactNumber: Number(fatherData.secondaryContactNumber),
        profilePictureUrl: imageId ? imageId[0] : imageId,
        documentUrls: fileId,
      };
    } else {
      fatherUpdatedData = {};
    }



    if (motherDocuments.length > 0) {
      const uploadResponses1 = await uploadFiles((motherDocuments), "guardians/documents");
      var fileId1 = uploadResponses1.map((response) => response.Location) || null;
    }
    var motherUpdatedData;

    if (motherData && Object.keys(motherData).length > 0) {
      motherUpdatedData = {
        ...motherData,
        dateOfBirth: FormatDate(motherData.dateOfBirth),
        primaryContactNumber: Number(motherData.primaryContactNumber),
        secondaryContactNumber: Number(motherData.secondaryContactNumber),
        profilePictureUrl: imageId1 ? imageId1[0] : imageId1,
        documentUrls: fileId1,
      };
    } else {
      motherUpdatedData = {};
    }





    if (guardianDocument.length > 0) {
      const uploadResponses1 = await uploadFiles((guardianDocument), "guardians/documents");
      var fileId2 = uploadResponses1.map((response) => response.Location) || null;
    }
    var guardianUpdatedData;

    if (guardianData && Object.keys(guardianData).length > 0) {
      guardianUpdatedData = {
        ...guardianData,
        dateOfBirth: FormatDate(guardianData.dateOfBirth),
        primaryContactNumber: Number(guardianData.primaryContactNumber),
        secondaryContactNumber: Number(guardianData.secondaryContactNumber),
        profilePictureUrl: imageId2 ? imageId2[0] : imageId2,
        documentUrls: fileId2,
      };
    } else {
      guardianUpdatedData = {};
    }

    if (
      Object.keys(fatherUpdatedData).length === 0 &&
      Object.keys(motherUpdatedData).length === 0 &&
      Object.keys(guardianUpdatedData).length === 0
    ) {
      setParentShowErrorMsg(true);
      setParentErrorMsg("select at least one parent");
    } else {
      if (Object.keys(fatherUpdatedData).length > 0) {
        if (Object.keys(fatherUpdatedData).length < 7) {
          errorPopup.father = "father";
        }
      }
      if (Object.keys(motherUpdatedData).length > 0) {
        if (Object.keys(motherUpdatedData).length < 7) {
          errorPopup.mother = "mother";
        }
      }
      if (Object.keys(guardianUpdatedData).length > 0) {
        if (Object.keys(guardianUpdatedData).length < 7) {
          errorPopup.guardian = "guardian";
        }
      }
    }

    if (Object.keys(errorPopup).length > 0) {
      setParentShowErrorMsg(true);
      setParentErrorMsg(
        `please enter required fields in ${Object.values(errorPopup)}.`
      );
    } else {
      const newFormErrors = {
        area1: !dropDowns.area1,

      };

      setFormErrors(newFormErrors);

      const updateAddress = {
        address: !currentAddress.address,
        pincode: !currentAddress.pincode,
      };

      setCurrentAddressErrors(updateAddress);

      const formErrorsValues = Object.values(newFormErrors);
      const addressErrorsValues = Object.values(updateAddress);

      const hasErrors = formErrorsValues
        .concat(addressErrorsValues)
        .some((error) => error);

      setFormErrors(newFormErrors);
      setCurrentAddressErrors(updateAddress);

      if (hasErrors) {
        setValidated(true);
        setSaving(false)
        return;
      }


      const sendData = {
        guardian: [],
        currentAddress: currentAddress,
        permanentAddress: permanentAddress,
      };


      if (Object.keys(fatherUpdatedData).length > 0) {
        if (sendData.guardian && Array.isArray(sendData.guardian)) {
          sendData.guardian.push(fatherUpdatedData);
        }
      }
      if (Object.keys(motherUpdatedData).length > 0) {
        if (sendData.guardian && Array.isArray(sendData.guardian)) {
          sendData.guardian.push(motherUpdatedData);
        }
      }
      if (Object.keys(guardianUpdatedData).length > 0) {
        if (sendData.guardian && Array.isArray(sendData.guardian)) {
          sendData.guardian.push(guardianUpdatedData);
        }
      }
      dispatch(postParentRequest(sendData, FranchiseeSchoolId));
    }
    setSaving(false)
  };

  const handleCancel = (e) => {

    setSelectedMotherImage(null);
    setSelectedMotherDocuments([]);
    setSelectedGuardianImage(null);
    setSelectedGuardianDocuments([]);
    setCurrentAddress({});
    setPermanentAddress({});
    setCurrentArea({});
    setPermanentArea({});
    setValidated(false);
    setDropDowns({});
    setFatherImage(null);
    setMotherImage(null);
    setGuardianImage(null);
    setFatherDocuments([]);
    setMotherDocuments([]);
    setGuardianDocument([]);
    setFormErrors("");
    setCurrentAddressErrors("");
    setMotherData({});
    setFatherData({});
    setGuardianData({});
  };

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleCancel();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  //-------- clear data -------------
  const handleClearGuardian = () => {
    setGuardianData({})
    setGuardianImage(null);
    setGuardianDocument([]);
  }
  const handleClearFather = () => {
    setFatherData({})
    setFatherImage(null);
    setFatherDocuments([]);
  }
  const handleClearMother = () => {
    setMotherData({})
    setMotherImage(null)
    setMotherDocuments([]);
  }


  return (
    <div>


      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="content-header ">Add Parents</div>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Father Name"
              placeholder="Father Name*"
              textType="Alphabet"
              value={fatherData.name}
              onChange={(event) => {
                const value = event.target.value;
                setFatherData({ ...fatherData, name: value, relation: "father", gender: "male", });
              }}
              feedbackMessage="Please enter fatherName."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter valid fatherName."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Relation Ship"
              placeholder="Relation Ship"
              textType="Alphabet"
              value={"Father"}
              disabled
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <FileUpload
                title="Upload&nbsp;Image"
                onChangeHandler={handleUploadFatherImage}
              />
              {fatherImage?.[0] && (
                <div className="file-names">
                  {typeof fatherImage?.[0].name === "string" && (
                    <p>{fatherImage?.[0].name}</p>
                  )}
                </div>
              )}
              {formErrors.selectedFatherImage && (
                <div className="error-message">
                  Please Select Father Image .
                </div>
              )}
              <div style={{ fontSize: "0.7rem" }}>Please ensure file is less than 5MB in size.</div>

            </Form.Group>
          </Col>

          <Col xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <div
                className="uploadDoc"

              >
                <div className="layout1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                      fill="#636363"
                    />
                  </svg>
                  <div className="title1">Upload&nbsp;Docu</div>
                </div>
                <input
                  type="file"
                  accept=".pdf,.jpg"
                  required
                  multiple
                  onChange={handleFatherDocumentChange}
                  style={{
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
              <div>
                {fileNamesArray.map((name, index) => {

                  return <p key={index}>{name}</p>;
                })}
              </div>
            </Form.Group>
            {formErrors.selectedFatherDocuments && (
              <div className="error-message">
                Please Select selectedDocuments .
              </div>
            )}
            <div style={{ fontSize: "0.7rem" }}>Please ensure each file is less than 5MB in size.</div>
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <DatePickerPast
              name="Date Of Birth"
              placeholder="DOB"
              // label="DOB*"
              value={fatherData.dateOfBirth || ""}
              onChange={(name, date, selectedDate) =>
                setFatherData({
                  ...fatherData,
                  dateOfBirth: date,
                })
              }
              dateFormat="dd-MM-yyyy"
              feedbackMessage="Please select date of birth."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <EmailInputField
              name="Email"
              placeholder="Email"
              type="email"
              value={fatherData.email || ""}
              onChange={(event) => {
                const value = event.target.value.trim()
                if (value) {
                  setFatherData({ ...fatherData, email: value })
                } else {
                  const updatedData = { ...fatherData };
                  delete updatedData.email;
                  setFatherData(updatedData);
                }
              }
              }
              feedbackMessage="Please enter Email ."
              validationRegex={emailRegex}
              validationErrorMessage="Please enter valid Email ."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <Form.Label className="lableName">Gender</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Male"
                  type="radio"
                  name="gender1"
                  value="male"
                  className="malebutton"
                  defaultChecked
                  checked={fatherData.gender === "male"}
                  onChange={(event) =>
                    setFatherData({ ...fatherData, gender: event.target.value })
                  }
                />
                <Form.Check
                  inline
                  label="Female"
                  type="radio"
                  name="gender1"
                  value="female"
                  className="malebutton"
                  checked={fatherData.gender === "female"}
                  onChange={(event) =>
                    setFatherData({ ...fatherData, gender: event.target.value })
                  }
                />
              </div>
            </Form.Group>
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="primary Contact Number"
              placeholder="primary Contact Number*"
              textType="Alphabet"
              // required
              value={fatherData.primaryContactNumber}
              onChange={(event) => {
                const value = event.target.value.replace(/\D/g, '');
                if (value) {
                  setFatherData({ ...fatherData, primaryContactNumber: value })
                } else {
                  const updatedData = { ...fatherData };
                  delete updatedData.primaryContactNumber;
                  setFatherData(updatedData);
                }
              }}
              feedbackMessage="Please enter primary contact number."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid primary contact number."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="secondary Contact Number"
              placeholder="secondary Contact Number"
              textType="Alphabet"
              // required
              value={fatherData.secondaryContactNumber}
              onChange={(event) => {
                const value = event.target.value.replace(/\D/g, '');
                if (value) {
                  setFatherData({ ...fatherData, secondaryContactNumber: value })
                } else {
                  const updatedData = { ...fatherData };
                  delete updatedData.secondaryContactNumber;
                  setFatherData(updatedData);
                }
              }
              }
              feedbackMessage="Please enter secondary Contact Number."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid secondary Contact Number."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Occupation "
              placeholder="Occupation *"
              textType="Alphabet"
              value={fatherData.occupation}
              onChange={(event) => {
                const value = event.target.value.trim()
                if (value) {
                  setFatherData({ ...fatherData, occupation: value })
                } else {
                  const updatedData = { ...fatherData };
                  delete updatedData.occupation;
                  setFatherData(updatedData);
                }
              }}
              feedbackMessage="Please enter Occupation ."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter a valid Occupation ."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Remark"
              placeholder="Remark"
              textType="Alphabet"
              value={fatherData.remark}
              onChange={(event) => {
                const value = event.target.value.trim()
                if (value) {
                  setFatherData({ ...fatherData, remark: value })
                } else {
                  const updatedData = { ...fatherData };
                  delete updatedData.remark;
                  setFatherData(updatedData);
                }
              }}
              feedbackMessage="Please enter  Remark."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter a valid  Remark."
            />
          </Col>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <button
              className="clear-button"
              type="button"
              onClick={handleClearFather}
            >
              Clear
            </button>
          </Col>
        </Row>
        {/* -------------------------MotherData------------------------------ */}
        <hr style={{ margin: "30px" }}></hr>
        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Mother Name"
              placeholder="Mother Name*"
              textType="Alphabet"
              value={motherData.name}
              onChange={(event) => {
                const value = event.target.value;
                setMotherData({ ...guardianData, name: value, relation: "mother", gender: 'female' });

              }}
              feedbackMessage="Please enter motherName."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter valid motherName."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Relation Ship"
              placeholder="Relation Ship"
              textType="Alphabet"
              // required
              value="Mother"
              disabled
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <FileUpload
                title="Upload&nbsp;Image"
                onChangeHandler={handleUploadMotherImage}
              />
              {motherImage?.[0] && (
                <div className="file-names">
                  {typeof motherImage?.[0].name === "string" && (
                    <p>{motherImage?.[0].name}</p>
                  )}
                </div>
              )}
              {formErrors.selectedMotherImage && (
                <div className="error-message">
                  Please mother Select Image .
                </div>
              )}
            <div style={{ fontSize: "0.7rem" }}>Please ensure file is less than 5MB in size.</div>

            </Form.Group>
          </Col>

          <Col xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <div
                className="uploadDoc"

              >
                <div className="layout1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                      fill="#636363"
                    />
                  </svg>
                  <div className="title1">Upload&nbsp;Docu</div>
                </div>
                <input
                  type="file"
                  accept=".pdf,.jpg"
                  multiple
                  onChange={handleMotherDocumentChange}
                  style={{
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
              <div>
                {fileNamesArray1.map((name, index) => {

                  return <p key={index}>{name}</p>;
                })}
              </div>
              <div style={{ fontSize: "0.7rem" }}>Please ensure each file is less than 5MB in size.</div>

            </Form.Group>
            {formErrors.selectedMotherDocuments && (
              <div className="error-message">
                Please Select selectedDocuments .
              </div>
            )}
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <DatePickerPast
              name="Date Of Birth"
              placeholder="DOB"
              value={motherData.dateOfBirth || ""}
              onChange={(name, date, selectedDate) => {
                setMotherData({
                  ...motherData,
                  dateOfBirth: date,
                });
              }}
              dateFormat="dd-MM-yyyy"
              feedbackMessage="Please select date of birth."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <EmailInputField
              name="Email"
              placeholder="Email"
              type="email"
              // required
              value={motherData.email || ""}
              onChange={(event) => {
                const value = event.target.value.trim()
                if (value) {
                  setMotherData({ ...motherData, email: value })
                } else {
                  const updatedData = { ...motherData };
                  delete updatedData.email;
                  setMotherData(updatedData);
                }
              }
              }
              feedbackMessage="Please enter Email ."
              validationRegex={emailRegex}
              validationErrorMessage="Please enter valid Email ."

            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <Form.Label className="lableName">Gender</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Male"
                  type="radio"
                  name="gender2"
                  value="male"
                  className="malebutton"
                  onChange={(event) =>
                    setMotherData({ ...motherData, gender: event.target.value })
                  }
                />
                <Form.Check
                  inline
                  label="Female"
                  type="radio"
                  name="gender2"
                  value="female"
                  className="malebutton"
                  // defaultChecked
                  checked={motherData.gender === "female"}
                  onChange={(event) =>
                    setMotherData({ ...motherData, gender: event.target.value })
                  }
                />
              </div>
            </Form.Group>
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name=" primary Contact Number"
              placeholder="primary Contact Number*"
              textType="Alphabet"
              value={motherData.primaryContactNumber}
              onChange={(event) => {
                const value = event.target.value.replace(/\D/g, '');
                if (value) {
                  setMotherData({ ...motherData, primaryContactNumber: value })
                } else {
                  const updatedData = { ...motherData };
                  delete updatedData.primaryContactNumber;
                  setMotherData(updatedData);
                }
              }}
              feedbackMessage="Please enter primary Contact Number."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid primary Contact Number."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="secondary Contact Number"
              placeholder="secondary Contact Number"
              textType="Alphabet"
              value={motherData.secondaryContactNumber}
              onChange={(event) => {
                const value = event.target.value.replace(/\D/g, '');
                if (value) {
                  setMotherData({ ...motherData, secondaryContactNumber: value })
                } else {
                  const updatedData = { ...motherData };
                  delete updatedData.secondaryContactNumber;
                  setMotherData(updatedData);
                }
              }}
              feedbackMessage="Please enter secondary Contact Number."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid secondary Contact Number."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Occupation "
              placeholder="Occupation *"
              textType="Alphabet"
              value={motherData.occupation}
              onChange={(event) => {
                const value = event.target.value.trim()
                if (value) {
                  setMotherData({ ...motherData, occupation: value })
                } else {
                  const updatedData = { ...motherData };
                  delete updatedData.occupation;
                  setMotherData(updatedData);
                }
              }}
              feedbackMessage="Please enter Occupation ."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter a valid Occupation ."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Remark"
              placeholder="Remark"
              textType="Alphabet"
              value={motherData.remark}
              onChange={(event) => {
                const value = event.target.value.trim()
                if (value) {
                  setMotherData({ ...motherData, remark: value })
                } else {
                  const updatedData = { ...motherData };
                  delete updatedData.remark;
                  setMotherData(updatedData);
                }
              }}
              feedbackMessage="Please enter  Remark."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter a valid  Remark."
            />
          </Col>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <button
              className="clear-button"
              type="button"
              onClick={handleClearMother}
            >
              Clear
            </button>
          </Col>
        </Row>
        {/*---------- --------------------GuardianData---------------------------------- */}
        <hr style={{ margin: "30px" }}></hr>


        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Guardian Name"
              placeholder="Guardian Name"
              textType="Alphabet"
              value={guardianData.name}
              onChange={(event) => {
                const value = event.target.value;
                setGuardianData({ ...guardianData, name: value });
              }}
              feedbackMessage="Please enter guardian name."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter valid guardian name."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Select
              as="select"
              className="textInput_Field"
              value={guardianData.relation || ''}
              onChange={(event) => {
                const value = event.target.value.trim()
                if (value) {
                  setGuardianData({
                    ...guardianData,
                    relation: event.target.value.trim(),
                  })
                } else {
                  const updatedData = { ...guardianData };
                  delete updatedData.relation;
                  setGuardianData(updatedData);
                }
              }
              }

            >
              <option value="">RelationShip</option>
              <option value="grand mother">Grand Mother</option>
              <option value="grand father">Grand Father</option>
            </Form.Select>
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <FileUpload
                title="Upload&nbsp;Image"
                onChangeHandler={handleUploadGuardianImage}
              />
              {guardianImage?.[0] && (
                <div className="file-names">
                  {typeof guardianImage?.[0].name === "string" && (
                    <p>{guardianImage?.[0].name}</p>
                  )}
                </div>
              )}
              {formErrors.selectedGuardianImage && (
                <div className="error-message">
                  Please Select guardian Image .
                </div>
              )}
            <div style={{ fontSize: "0.7rem" }}>Please ensure file is less than 5MB in size.</div>

            </Form.Group>
          </Col>

          <Col xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <div
                className="uploadDoc"

              >
                <div className="layout1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z"
                      fill="#636363"
                    />
                  </svg>
                  <div className="title1">Upload&nbsp;Docu</div>
                </div>
                <input
                  type="file"
                  accept=".pdf,.jpg"
                  multiple
                  onChange={handleGuardianDocumentChange}
                  style={{
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
              <div>
                {fileNamesArray2.map((name, index) => {

                  return <p key={index}>{name}</p>;
                })}
              </div>
            </Form.Group>
            {formErrors.selectedGuardianDocuments && (
              <div className="error-message">
                Please Select selectedDocuments .
              </div>
            )}
            <div style={{ fontSize: "0.7rem" }}>Please ensure each file is less than 5MB in size.</div>
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <DatePickerPast
              name="Date Of Birth"
              placeholder="DOB"
              value={guardianData.dateOfBirth || ""}
              onChange={(name, date, selectedDate) => {
                setGuardianData({
                  ...guardianData,
                  dateOfBirth: date,
                });

              }
              }
              dateFormat="dd-MM-yyyy"
              feedbackMessage="Please select date of birth."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <EmailInputField
              name="Email"
              placeholder="Email*"
              type="email"
              // required
              value={guardianData.email || ""}
              onChange={(event) => {
                const value = event.target.value.trim()
                if (value) {
                  setGuardianData({ ...guardianData, email: value })
                } else {
                  const updatedData = { ...guardianData };
                  delete updatedData.email;
                  setGuardianData(updatedData);
                }
              }
              }
              feedbackMessage="Please enter Email ."
              validationRegex={emailRegex}
              validationErrorMessage="Please enter valid Email ."

            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <Form.Group>
              <Form.Label className="lableName">Gender</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Male"
                  type="radio"
                  name="gender"
                  value="male"
                  className="malebutton"
                  checked={guardianData.gender === "male"}
                  onChange={(event) =>
                    setGuardianData({
                      ...guardianData,
                      gender: event.target.value,
                    })
                  }
                />
                <Form.Check
                  inline
                  label="Female"
                  type="radio"
                  name="gender"
                  value="female"
                  className="malebutton"
                  checked={guardianData.gender === "female"}
                  onChange={(event) =>
                    setGuardianData({
                      ...guardianData,
                      gender: event.target.value,
                    })
                  }
                />
              </div>
            </Form.Group>
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="primary Contact Number"
              placeholder="primary Contact Number"
              textType="Alphabet"
              // required
              value={guardianData.primaryContactNumber}
              onChange={(event) => {
                const value = event.target.value.replace(/\D/g, '');
                if (value) {
                  setGuardianData({ ...guardianData, primaryContactNumber: value })
                } else {
                  const updatedData = { ...guardianData };
                  delete updatedData.primaryContactNumber;
                  setGuardianData(updatedData);
                }
              }

              }

              feedbackMessage="Please enter primary Contact Number."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid primary Contact Number."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="secondary Contact Number"
              placeholder="secondary Contact Number"
              textType="Alphabet"
              value={guardianData.secondaryContactNumber}
              onChange={(event) => {
                const value = event.target.value.replace(/\D/g, '');
                if (value) {
                  setGuardianData({ ...guardianData, secondaryContactNumber: value })
                } else {
                  const updatedData = { ...guardianData };
                  delete updatedData.secondaryContactNumber;
                  setGuardianData(updatedData);
                }
              }
              }

              feedbackMessage="Please enter secondary Contact Number."
              validationRegex={validationRegexMobileNo}
              validationErrorMessage="Please enter a valid secondary Contact Number."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Occupation "
              placeholder="Occupation *"
              textType="Alphabet"
              value={guardianData.occupation}
              onChange={(event) => {
                const value = event.target.value.trim()
                if (value) {
                  setGuardianData({ ...guardianData, occupation: value })
                } else {
                  const updatedData = { ...guardianData };
                  delete updatedData.occupation;
                  setGuardianData(updatedData);
                }
              }
              }

              feedbackMessage="Please enter Occupation ."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter a valid Occupation ."
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Remark"
              placeholder="Remark"
              textType="Alphabet"
              value={guardianData.remark}
              onChange={(event) => {
                const value = event.target.value.trim()
                if (value) {
                  setGuardianData({ ...guardianData, remark: value })
                } else {
                  const updatedData = { ...guardianData };
                  delete updatedData.remark;
                  setGuardianData(updatedData);
                }
              }
              }
              feedbackMessage="Please enter  Remark."
              validationRegex={validationRegexOnlyAlpha}
              validationErrorMessage="Please enter a valid  Remark."
            />
          </Col>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <button
              className="clear-button"
              type="button"
              onClick={handleClearGuardian}
            >
              Clear
            </button>
          </Col>
        </Row>

        <hr style={{ margin: "30px" }}></hr>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Permanent Address"
              placeholder="Permanent Address"
              className="fcontrol"
              textType="Alphabet"
              required
              // validated={validated}
              value={permanentAddress.address}
              onChange={(event) => {
                setPermanentAddress({
                  ...permanentAddress,
                  address: event.target.value,
                });
              }}
              feedbackMessage="Please enter permanent address."
              validationRegex={validationRegexAddress}
              validationErrorMessage="Please enter valid permanent address."
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.area
                  ? { value: dropDowns.area, label: dropDowns.area }
                  : null
              }
              options={apiData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
                city: { id: option.cityId, name: option.city.name },
                state: {
                  id: option.city.stateId,
                  name: option.city.state.name,
                },
              }))}
              onChange={(selectedOption) => {
                setPermanentAddress({
                  ...permanentAddress,
                  areaId: selectedOption.id,
                  type: "permanent",
                });
                setPermanentArea({
                  ...permanentArea,
                  city: selectedOption.city,
                  state: selectedOption.state,
                });
                setPermanentAreaId({
                  ...permanentAreaId,
                  city: selectedOption.city.id,
                  state: selectedOption.state.id,
                });

                setDropDowns({ ...dropDowns, area: selectedOption.value });
                setFormErrors({ ...formErrors, area: false });
              }}
              placeholder="Select Area"
            />
            {formErrors.area && (
              <div className="error-message">Please Select area .</div>
            )}
          </Col>
          <Col sm={12} xs={12} md={6} lg={1}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-add-icon">Add Area</Tooltip>
              }
            >
              <AddIcon onClick={navigateToAnotherScreen} style={{ "cursor": "pointer" }} />
            </OverlayTrigger>
          </Col>
          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="City"
              placeholder="City"
              className="fcontrol"
              textType="Alphabet"
              value={permanentArea?.city?.name}
              disabled

            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="State"
              placeholder="State"
              className="fcontrol"
              textType="Alphabet"
              value={permanentArea?.state?.name}
              disabled

            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="Pincode"
              placeholder="Pincode"
              className="fcontrol"
              textType="Alphabet"
              required

              value={permanentAddress.pincode || ""}
              onChange={(event) => {
                setPermanentAddress({
                  ...permanentAddress,
                  pincode: Number(event.target.value),
                });
              }}
              feedbackMessage="Please enter pincode."
              validationRegex={validationRegexOnlyPincode}
              validationErrorMessage="Please enter valid pincode."
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
            <TextInputField
              name="Current Address"
              placeholder="Current Address"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={currentAddress.address}
              onChange={(event) => {
                setCurrentAddress({
                  ...currentAddress,
                  address: event.target.value,
                  type: "current",
                });
              }}
              feedbackMessage="Please enter current address."
              validationRegex={validationRegexAddress}
              validationErrorMessage="Please enter valid current address."
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <SelectDropdown
              isSearchable
              value={
                dropDowns.area1
                  ? { value: dropDowns.area1, label: dropDowns.area1 }
                  : null
              }
              options={apiData.map((option, index) => ({
                id: option.id,
                value: option.name,
                label: option.name,
                city: { id: option.cityId, name: option.city.name },
                state: {
                  id: option.city.stateId,
                  name: option.city.state.name,
                },
              }))}
              onChange={(selectedOption) => {
                setCurrentAddress({
                  ...currentAddress,
                  areaId: selectedOption.id,
                });
                setCurrentArea({
                  ...currentArea,
                  city: selectedOption.city,
                  state: selectedOption.state,
                });
                setCurrentAreaId({
                  ...currentAreaId,
                  city: selectedOption.city.id,
                  state: selectedOption.state.id,
                });
                setDropDowns({ ...dropDowns, area1: selectedOption.value });
                setFormErrors({ ...formErrors, area1: false });
              }}
              placeholder="Select Area"
            />
            {formErrors.area1 && (
              <div className="error-message">Please Select area .</div>
            )}
          </Col>
          <Col sm={12} xs={12} md={6} lg={1}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-add-icon">Add Area</Tooltip>
              }
            >
              <AddIcon onClick={navigateToAnotherScreen} style={{ "cursor": "pointer" }} />
            </OverlayTrigger>
          </Col>
          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="City"
              placeholder="City"
              className="fcontrol"
              textType="Alphabet"
              value={currentArea?.city?.name}
              disabled
            />
          </Col>

          <Col xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="State"
              placeholder="State"
              className="fcontrol"
              textType="Alphabet"
              value={currentArea?.state?.name}
              disabled
            />
          </Col>

          <Col sm={12} xs={12} md={6} lg={2} className="rowCss">
            <TextInputField
              name="Pincode"
              placeholder="Pincode"
              className="fcontrol"
              textType="Alphabet"
              required
              validated={validated}
              value={currentAddress.pincode || ""}
              onChange={(event) => {
                setCurrentAddress({
                  ...currentAddress,
                  pincode: Number(event.target.value),
                });
              }}
              feedbackMessage="Please enter pincode."
              validationRegex={validationRegexOnlyPincode}
              validationErrorMessage="Please enter valid pincode."
            />
          </Col>
        </Row>

        <ErrorMessagePopup
          show={parentShowErrorMsg}
          onClose={() => {
            setParentShowErrorMsg(false);
          }}
          errorMessage={parentErrorMsg}
        />

        <SuccessMessagePopup
          show={showSuccessMsg}
          onClose={handleCloseSuccessPopup}
          successMessage={successMsg}
        />
        <ErrorMessagePopup
          show={showErrorMsg}
          onClose={handleCloseErrorPopup}
          errorMessage={errorMsg}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleCancel}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit" >
            {saving ? "Saving..." : "save"}
          </button>
        </div>
      </Form>
      {/*  */}
    </div>
  );
};

export default AddParent;
