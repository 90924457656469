import { configureStore } from "@reduxjs/toolkit";
import viewAllocationBusRouteReducer from "../components/Transportation/slice/viewAllocationSlicer";
import testPostAPIReducer from "../components/Transportation/slice/testSlice";
import EnquiryEntryReducer from "../components/EnquiryDetails/slice/enquiryEntrySlicer";
import assignTeacherReducer from "../components/AssignTeacherToClass/AssignTeacherToClassSlicer";
import enquiryViewReducer from "../components/EnquiryDetails/slice/enquiryViewSlicer";
import enquiryFollowUpReducer, {
  updateEnquiry,
} from "../components/EnquiryDetails/slice/enquiryFollowUpSlicer";
import enquiryFollowUpPutReducer from "../components/EnquiryDetails/slice/enquiryFollowUpSlicer";
import franchiseEnquirySlicerReducer from "../components/Franchise/franchiseSlice/franchiseEnquirySlicer";
import franchiseFollowUpReducer from "../components/Franchise/franchiseSlice/franchiseFollowUpSlicer";
import addStudentReducer from "../components/StudentDetails/addStudentSlicer";
import viewStudentReducer from "../components/StudentDetails/viewStudentSlicer";
import addParentReducer from "../components/parent/addParentsSlicer";
import viewParentReducer from "../components/parent/viewParentSlicer";
import eventAndHolidaysSlice from "../components/event_Holidays/slice/eventAndHolidaysSlice";
import employeeEnquirySlice from "../components/employee/slice/employeeEnquirySlice";
import viewEventsAndHolidaysReducer from "../components/event_Holidays/slice/viewEventsAndHolidaysSlice";
import ViewEmployeeEnquiryReducer from "../components/employee/slice/viewEmployeeEnquirySlice";
import employeeSlicer from "../components/Teachers/teacherSlicers/employeeViewSlicer";
import transportationFeesPostResData from "../components/Transportation/slice/transportationSlice";
import viewTransportationFeeCollectionReducer from "../components/Transportation/slice/viewTransportationSlice";
import allocationBusRoutePostData from "../components/Transportation/slice/allocationBusRouteSlice";
import currentPageNumber from "../components/Franchise/slice/currentPageSlice";
import royaltyAmountData from "../components/Franchise/slice/royaltySlice";
import addSchoolStoreData from "../components/Franchise/slice/schoolCreationslice";
import addAssessmentReducer from "../components/Assessment/slicer/addAssessmentSlicer";
import viewAssessmentReducer from "../components/Assessment/slicer/viewAssessmentSlicer";
import addAttendanceReducer from "../components/AttendanceDetails/addAttendanceSlicer";
import viewAttendanceReducer from "../components/AttendanceDetails/viewAttendanceSlicer";
import viewStudentAttendanceReducer from "../components/AttendanceDetails/viewStudentAttendanceSlicer";
import addSchoolDataReducer from "../components/Franchise/slice/addClass";
import stateSliceReducer from "../components/state/slice/stateSlice";
import stateViewSlice from "../components/state/slice/viewStateSlice";
import addCitySlice from "../components/city/slice/addCitySlice";
import viewCitySlice from "../components/city/slice/viewCitySlice";
import expenseEntrySlicer from "../components/pettyExpenses/slice/expenseEntrySlicer";
import classDataSlice from "../components/EnquiryDetails/slice/enquiryEntryAction";
import entryFollowUpListSlicer from "../components/EnquiryDetails/slice/entryFollowUpListSlicer";
import competetionAwardsReducer from "../components/CoCurriculumActivities/slicerCompetetion/competitionAwardsSlicer";
import areaSlicerReducer from "../components/EnquiryDetails/slice/areaSlicer";
import addClass from "../components/Classes/slice/addClassSlice";
import ViewClass from "../components/Classes/slice/viewClassSlice";
import ViewFeeDetails from "../components/fee/slice/viewfeeDetailsSlice";
import AddFees from "../components/fee/slice/feeDetailsSlice";
import viewSubjectReducer from "../components/subject/slice/viewSubjectSlice";
import rolesDetailsReducer from "../components/Administration/RolesAssign/slice/rolesSlice";
import RolesAssignPostSliceReducer from "../components/Administration/RolesAssign/slice/RolesAssignPostSlice";
import viewEmployeeEnquiryFollowupSliceReducer from "../components/employee/slice/viewEmployeeEnquiryFollowupSlice";
import EmployeeEnquiryFollowupSliceReducer from "../components/employee/slice/employeeEnquiryFollowupSlice";
import addProgramSlice from "../components/programs/slice/addProgramSlice";
import viewProgramReducer from "../components/programs/slice/viewProgramSlice";
import addSubjectSlice from "../components/subject/slice/addSubjectSlice";
import playSchoolReducer from "../components/EnquiryDetails/slice/playSchoolSlicer";
import classesReducer from "../components/EnquiryDetails/slice/classesSlicer";
import parentLoginReducer from "../components/parent/parentLoginSlicer/parentLoginSlicer";
import ComplaintsSuggestionsReducer from "../components/parent/slice/ComplaintsSuggestionsPostSlice";
import ViewComplaintsSuggestionsReducer from "../components/parent/slice/viewComplaintsSuggestionsSlice";
import addTimingReducer from "../components/TimeTableDetails/slicer/addTimingSlicer";
import viewTimingReducer from "../components/TimeTableDetails/slicer/viewTimingSlicer";
import addSectionReducer from "../components/section/slice/addSectionSlice";
import viewSectionReducer from "../components/section/slice/viewSectionSlice";
import addSectionSlice from "../components/section/slice/addSectionSlice";
import addAreaSlice from "../components/Areas/slice/addAreaSlice";
import viewAreaReducer from "../components/Areas/slice/viewAreaSlice";
import PostFeeHeadReducer from "../components/Administration/FeeStructureSlice/feeHeadPost";
import fetchFeeHeadDetails from "../components/Administration/FeeStructureSlice/feeHeadGet";
import postFeeDueDateReducer from "../components/Administration/FeeStructureSlice/feeDueDatePost";
import feeDueDateGetReducer from "../components/Administration/FeeStructureSlice/feeDueDateGet";
import PostFranchiseOwnerReducer from "../components/Franchise/slice/franchiseOwnerSlice";
import FetchFranchiseOwnersReducer from "../components/Franchise/slice/franchiseOwnersGet";
import PostSchoolCreationReducer from "../components/Franchise/slice/creationSchool";
import FetchFranchiseSchoolsReducer from "../components/Franchise/slice/schoolDataGet";
import frequencySliceReducer from "../components/Frequency/slice/frequencySlice";
import viewFrequencySlicer from "../components/Frequency/slice/viewFrequencySlice";
import frequenciesReducer from "../components/EnquiryDetails/slice/enquiryFrequencySlicer";
import timingsReducer from "../components/EnquiryDetails/slice/enquiryTimingSlicer";
import viewFranchiseclasssectionsReducer from "../components/Franchise/slice/viewfranchiseclasssections";
import addTimeTableReducer from "../components/TimeTableDetails/slicer/addTimeTableslicer";
import viewTimeTableReducer from "../components/TimeTableDetails/slicer/viewTimeTableslicer";
import franchiseclasssectionsReducer from "../components/TimeTableDetails/slicer/franchiseClassSectionSlicer";
import ShoolFeeCreationReducer from "../components/Administration/FeeStructureSlice/feeMasterPost";
import ShoolFeeGetCreationReducer from "../components/Administration/FeeStructureSlice/feeStructureGetSlice";
import franchiseRequestAndSuggestionsSlice from "../components/Franchise/franchiseSlice/requestAndSuggestionsSlicer";
import franchiseFollowUpUpdateSlice from "../components/Franchise/franchiseSlice/franchiseFollowUpUpdate";
import postLaneSlicer from "../components/Transportation/slice/postLaneSlicer";
import addVehicleSlicer from "../components/Administration/Transportation/addVehicleSlicer";
import FoodItemsPost from "../components/FoodUpdates/slice/FoodItemPostSlice";
import RoyaltyAmountSlice from "../components/Royalty/slice/royaltyAmountSlice";
import ViewRoyaltyAmountDetails from "../components/Royalty/slice/viewRoyaltyAmountSlice";
import editSlice from "../components/Royalty/slice/editSlice";
import addVehicleViewSlicer from "../components/Administration/Transportation/addVehicleViewSlicer";
import addVehicleEditSlicer from "../components/Administration/Transportation/addVehicleEditSlicer";
import franchiseFollowUpListSlicer from "../components/Franchise/franchiseSlice/franchiseFollowUpListSlicer";
import CreateLaneView from "../components/Transportation/createLineViewSlicer";
import createLineViewEditSlicer from "../components/Transportation/createLineViewEditSlicer";
import viewFranchiseClasswiseSectionsReducer from "../components/Assessment/slicer/viewfranchiseclasswisesectionsSlicer";
import transportationFeeSlice from "../components/Administration/Transportation/transportationFeeSlice";
import transportationReducer from "../components/Administration/Transportation/getTransportationFees";
import viewFranchiseWiseSubjectsReducer from "../components/TimeTableDetails/slicer/franchiseWIseSubjectsSlicer";
import viewAssessmentParameterReducer from "../components/Assessment/slicer/viewAssessmentParametSlicer";
import addMonthlyAssessmentReducer from "../components/Assessment/slicer/addMonthlyAssessmentSlicer";
import addAssessmentReportReducer from "../components/Assessment/slicer/addAssessmentReportSlicer";
import editSubjectSlice from "../components/subject/slice/subjectEditSlice";
import picturesUploadSlice from "../components/Co-Curriculem_Actions/slice/picturesUploadSlice";
import addAssessmentParameterReducer from "../components/Assessment/slicer/addAssessmentParameterSlicer";
import fetchFoodItemsReducer from "../components/FoodUpdates/slice/getFoodItems";
import FoodUpdatesSlice from "../components/FoodUpdates/slice/foodUpdateSlice";
import FoodUpdatesDataSlice from "../components/FoodUpdates/slice/getFoodUpdatesData";
import FetchDailyUpdatesSlice from "../components/Co-Curriculem_Actions/slice/getPcturesUploads";
import postQualificationSlicer from "../components/Administration/Qualifications/slicer/addQualSlicer";
import viewAssessmentReportReducer from "../components/Assessment/slicer/viewAssessmentReportSlicer";
import CommentsAndSuggestionsReducer from "../components/parent/slice/commentPut";
import addCompetitionReducer from "../components/compitations/slice/addCompetitionSlice";
import viewCompetitionSlice from "../components/compitations/slice/viewCompetitionSlice";
import addRequestSuggestionsSlicer from "../components/Request-Suggestions/slicer/addRequestSlicer";
import viewRequestSuggestionsSlicer from "../components/Request-Suggestions/slicer/viewRequestSlicer";
import viewMonthlyAssessmentReducer from "../components/Assessment/slicer/viewMonthlyAssessmentSlicer";
import profileSlicer from "../components/authentication/profileSlicer";
import viewSectionsSlicer from "../components/Franchise/slice/viewScetions";
import franchiseViewReducer from "../components/Franchise/franchiseSlice/franchiseViewSlicer";
import franchiseFollowUpListReducer from "../components/Franchise/franchiseSlice/franchiseFollowUpListSlicer";
import viewSchoolReducer from "../components/Franchise/slice/viewSchoolSlice";
import updateStudentReducer from "../components/StudentDetails/updateStudentSlice";
import getProgramBySchoolReducer from "../components/programs/slice/getProgramBySchoolSlice";
import ViewStudentFeeCollection from "../components/StudentDetails/slice/studentFeeGet";
import studentFeePostSlicer from "../components/StudentDetails/slice/studentFeePost";
import ViewStudentFeeReducer from "../components/StudentDetails/slice/viewStudentFeeSlice";
import getProgramBySchoolIdReducer from "../components/programs/slice/getProgramBySchoolId";
import getClassesByProgramIdReducer from "../components/programs/slice/getClassByProgramId";
import viewParentRelationReducer from "../components/parent/slice/viewParentRelationSlice";
import updateParentReducer from "../components/parent/slice/editParentSlice";
import dueFeeSlice from "../components/StudentDetails/slice/dueFeeSlice";
import RepaymentFeeSlice from "../components/StudentDetails/slice/repaymentFeeSlice";
import studentAttendanceReducer from "../components/AttendanceDetails/studentAttendanceSclier";
import viewClassTimeTableReducer from "../components/TimeTableDetails/slicer/viewclassTimeTableSlicer";
import ViewClassTimetableByStudentIdReducer from "../components/parent/slice/viewTimeTableSlicer"
import viewSchoolEventsAndHolidaysReducer from "../components/event_Holidays/slice/viewSchoolEventsAndHolidaysSlice";
import fitnessParameterSliceReducer from "../components/FitnessParameter/slice/fitnessparameterSlice";
import fitnessParameterViewSliceReducer from "../components/FitnessParameter/slice/fitnessParameterViewSlice";
import fitnessAnalysisSliceReducer from "../components/FitnessParameter/slice/fitnessAnalysisSlice";
// import studentAttendanceReducer from "../components/AttendanceDetails/studentAttendanceSclier";
import viewDropdownEmployeeEnquiryReducer from "../components/employee/slice/viewDropdownEmployeeSlice"
// import studentAttendanceReducer from "../components/AttendanceDetails/studentAttendanceSclie
import addClassActivityUpdatesReducer from "../components/ClassActivityUpdates/slicers/AddClassActivitySlicer"
import parentTeacherMeetingPostReducer from "../components/Parent Teacher Meeting/slice/parentTeacherMeeting"
import viewParentTeacherMeetingSlice from "../components/Parent Teacher Meeting/slice/viewParentTeacherMeetingSlice";
import viewClassSectionDataReducer from "../components/TimeTableDetails/slicer/getClassSectionsSlicer";
import classDataSlicer from "../components/ClassActivityUpdates/slicers/addSectionsActivitySlicer";
import postPtmDatesReducer from "../components/Parent Teacher Meeting/slice/ptmDatesSlicer";
import revenueSheetSlicerReducer from "../components/Revenue/slice/revenueSheetSlicer";
import revenueSheetViewSlicerReducer from "../components/Revenue/slice/revenueSheetViewSlicer";
import revenueTypeViewSlicerReducer from "../components/Revenue/slice/revenueTypeViewSlicer";
import revenueTypeReducer from "../components/Revenue/slice/revenueTypeSlicer";
import expensesTypeSlicerReducer from "../components/pettyExpenses/slice/expensesTypeSlicer";
import expensesTypeViewSlicerReducer from "../components/pettyExpenses/slice/expensesTypeViewSlicer";
import expensesTypePutSlicerReducer from "../components/pettyExpenses/slice/expensesTypePutSlicer";
import pettyExpensesSlicerReducer from "../components/pettyExpenses/slice/pettyExpensesSlicer";
import pettyExpensesViewSlicerReducer from "../components/pettyExpenses/slice/pettyExpensesViewSlicer";
import revenueTypeViewPutSlicerReducer from "../components/Revenue/slice/revenueTypeViewPutSlicer";
import revenueTypeViewDeleteSlicerReducer from "../components/Revenue/slice/revenueTypeViewDeleteSlicer";
import pettyExpensesDeleteSlicerReducer from "../components/pettyExpenses/slice/pettyExpensesDeleteSlicer";
import expensesEntryViewSlicerReducer from "../components/pettyExpenses/slice/expensesEntryViewSlicer";
import updateAssessmentReportSlicerReducer from "../components/Assessment/slicer/updateAssessmentReportSlicer";
import updatedMeetingSliceReducer from "../components/Parent Teacher Meeting/slice/put_parentmeetingslice"
import deleteMeetingSliceReducer from "../components/Parent Teacher Meeting/slice/delete_parentmeetingslice";
import revenueSheetViewPutSlicerReducer from "../components/Revenue/slice/revenueSheetViewPutSlicer";
import eventStatusSlicer from '../components/event_Holidays/slice/eventStatusSlice';
import viewEventStatus from '../components/event_Holidays/slice/viewEventStatusSlice'
import eventStatusPutSlice from '../components/event_Holidays/slice/eventStatusPutSlice'
import termAssessmentSlice from '../components/Assessment/slicer/addTermAssessmentSlice'

const store = configureStore({
  reducer: {
    viewAllocationBusRoute: viewAllocationBusRouteReducer,
    testPostAPI: testPostAPIReducer,
    EnquiryEntry: EnquiryEntryReducer,
    assignTeacher: assignTeacherReducer,
    transportationCollectFee: transportationFeesPostResData,
    viewTransportationFeeCollection: viewTransportationFeeCollectionReducer,
    allocationBusRoute: allocationBusRoutePostData,
    addSchoolData: addSchoolStoreData,
    addSchoolData: addSchoolStoreData,
    addCurrentPage: currentPageNumber,
    royaltyAmount: royaltyAmountData,
    FranchiseAddClass: addSchoolDataReducer,
    ViewFeeDetails: ViewFeeDetails,
    addClass: addClass,
    viewClass: ViewClass,
    city: addCitySlice,
    ViewCity: viewCitySlice,
    state: stateSliceReducer,
    viewState: stateViewSlice,
    enquiryView: enquiryViewReducer,
    enquiryFollowUp: enquiryFollowUpReducer,
    enquiryFollowUpPut: enquiryFollowUpPutReducer,
    franchiseEnquirySlicer: franchiseEnquirySlicerReducer,
    franchiseFollowUpSlicerGet: franchiseFollowUpReducer,
    addStudent: addStudentReducer,
    viewStudent: viewStudentReducer,
    addParent: addParentReducer,
    viewParent: viewParentReducer,
    eventAndHolidays: eventAndHolidaysSlice,
    employeeEnquiry: employeeEnquirySlice,
    viewEmployeeEnquiry: ViewEmployeeEnquiryReducer,
    viewEventsAndHolidays: viewEventsAndHolidaysReducer,
    employeeSlicer,
    competetionAwardsReducer,
    expenseEntrySlicer,
    parentLoginReducer,
    postLaneSlicer,
    profileSlicer,
    postQualificationSlicer,
    addVehicle: addVehicleSlicer,
    viewTransportationFeeCollection: viewTransportationFeeCollectionReducer,
    addAssessment: addAssessmentReducer,
    viewAssessment: viewAssessmentReducer,
    addAttendance: addAttendanceReducer,
    viewAttendance: viewAttendanceReducer,
    viewStudentAttendance: viewStudentAttendanceReducer,
    classData: classDataSlice,
    enquiryViewList: entryFollowUpListSlicer,
    areaDetails: areaSlicerReducer,
    viewSubject: viewSubjectReducer,
    rolesDetails: rolesDetailsReducer,
    RolesAssignPostSlice: RolesAssignPostSliceReducer,
    ViewEmployee: viewEmployeeEnquiryFollowupSliceReducer,
    employeeEnquiryFollowUp: EmployeeEnquiryFollowupSliceReducer,
    addProgram: addProgramSlice,
    viewProgram: viewProgramReducer,
    addSubject: addSubjectSlice,
    playSchool: playSchoolReducer,
    classes: classesReducer,
    addTimings: addTimingReducer,
    viewTimings: viewTimingReducer,
    addSection: addSectionSlice,
    viewSection: viewSectionReducer,
    addArea: addAreaSlice,
    viewArea: viewAreaReducer,
    feeHead: PostFeeHeadReducer,
    feeHeadDetails: fetchFeeHeadDetails,
    feeDueDate: postFeeDueDateReducer,
    feeDueDates: feeDueDateGetReducer,
    franchiseOwner: PostFranchiseOwnerReducer,
    franchiseOwnerDetails: FetchFranchiseOwnersReducer,
    schoolCreation: PostSchoolCreationReducer,
    franchiseSchools: FetchFranchiseSchoolsReducer,
    frequencySlice: frequencySliceReducer,
    dayFrequency: viewFrequencySlicer,
    frequencies: frequenciesReducer,
    timings: timingsReducer,
    viewFranchiseClassSections: franchiseclasssectionsReducer,
    viewFranchiseClassSection: viewFranchiseclasssectionsReducer,
    addTimeTable: addTimeTableReducer,
    viewTimeTable: viewTimeTableReducer,
    // viewFranchiseClassSection:viewFranchiseclasssectionsReducer,
    schoolFee: ShoolFeeCreationReducer,
    franchiseRequestAndSuggestions: franchiseRequestAndSuggestionsSlice,
    franchiseFollowUp: franchiseFollowUpUpdateSlice,
    ComplaintsSuggestions: ComplaintsSuggestionsReducer,
    ViewComplaintsSuggestions: ViewComplaintsSuggestionsReducer,
    FoodItemPost: FoodItemsPost,
    royaltyAmount: RoyaltyAmountSlice,
    viewRoyaltyAmount: ViewRoyaltyAmountDetails,
    editRoyalAmount: editSlice,
    addVehicleView: addVehicleViewSlicer,
    addVehicleEdit: addVehicleEditSlicer,
    franchiseFollowUpListView: franchiseFollowUpListSlicer,
    createLane: CreateLaneView,
    addCreateLaneEdit: createLineViewEditSlicer,
    viewFranchiseClasswiseSections: viewFranchiseClasswiseSectionsReducer,
    transportationFeeSlice: transportationFeeSlice,
    transportationFees: transportationReducer,
    viewFranchiseWiseSubjects: viewFranchiseWiseSubjectsReducer,
    viewAssessmentParameter: viewAssessmentParameterReducer,
    addMonthlyAssessment: addMonthlyAssessmentReducer,
    addAssessmentReport: addAssessmentReportReducer,
    addTermAssessment: termAssessmentSlice,
    editSubject: editSubjectSlice,
    pictureUpload: picturesUploadSlice,
    addAssessmentParameter: addAssessmentParameterReducer,
    foodItems: fetchFoodItemsReducer,
    FoodUpdates: FoodUpdatesSlice,
    FoodUpdatedData: FoodUpdatesDataSlice,
    dailyUpdates: FetchDailyUpdatesSlice,
    viewAssessmentReport: viewAssessmentReportReducer,
    CommentsAndSuggestions: CommentsAndSuggestionsReducer,
    addCompetition: addCompetitionReducer,
    viewCompetition: viewCompetitionSlice,
    addRequestSuggestions: addRequestSuggestionsSlicer,
    viewRequestSuggestions: viewRequestSuggestionsSlicer,
    viewMonthlyAssessment: viewMonthlyAssessmentReducer,
    viewSections: viewSectionsSlicer,
    franchiseView: franchiseViewReducer,
    franchiseFollowUPList: franchiseFollowUpListReducer,
    viewSchoolData: viewSchoolReducer,
    schoolFees: ShoolFeeGetCreationReducer,
    updateStudent: updateStudentReducer,
    getProgramBySchool: getProgramBySchoolReducer,
    studentsFeeCollections: ViewStudentFeeCollection,
    studentFeePost: studentFeePostSlicer,
    ViewStudentFees: ViewStudentFeeReducer,
    getProgramBySchoolId: getProgramBySchoolIdReducer,
    getClassesByProgramId: getClassesByProgramIdReducer,
    viewParentRelation: viewParentRelationReducer,
    updateParent: updateParentReducer,
    dueFee: dueFeeSlice,
    repaymentFee: RepaymentFeeSlice,
    viewSchoolEventsAndHolidays: viewSchoolEventsAndHolidaysReducer,
    fitnessParameter: fitnessParameterSliceReducer,
    fitnessParameterView: fitnessParameterViewSliceReducer,
    fitnessAnalysisView: fitnessAnalysisSliceReducer,
    studentAttendance: studentAttendanceReducer,
    viewClassTimeTable: viewClassTimeTableReducer,
    ViewClassTimetableByStudentId: ViewClassTimetableByStudentIdReducer,
    viewDropdownEmployeeEnquiry: viewDropdownEmployeeEnquiryReducer,
    addClassActivityUpdates: addClassActivityUpdatesReducer,
    parentTeacherMeetingPost: parentTeacherMeetingPostReducer,
    viewParentTeacherMeeting: viewParentTeacherMeetingSlice,
    viewClassSectionData: viewClassSectionDataReducer,
    classData: classDataSlicer,
    ptmDates: postPtmDatesReducer,
    revenueSheet: revenueSheetSlicerReducer,
    revenueSheetView: revenueSheetViewSlicerReducer,
    revenueType: revenueTypeReducer,
    expensesTypeSlicer: expensesTypeSlicerReducer,
    expensesTypeViewSlicer: expensesTypeViewSlicerReducer,
    expensesTypeEdit: expensesTypePutSlicerReducer,
    pettyExpensesSlicer: pettyExpensesSlicerReducer,
    pettyExpensesView: pettyExpensesViewSlicerReducer,
    revenueTypeView: revenueTypeViewSlicerReducer,
    revenueTypeEdit: revenueTypeViewPutSlicerReducer,
    revenueTypeDelete: revenueTypeViewDeleteSlicerReducer,
    expensesTypeDelete: pettyExpensesDeleteSlicerReducer,
    expensesEntryView: expensesEntryViewSlicerReducer,
    UpdateAssessmentReport: updateAssessmentReportSlicerReducer,
    updatedMeeting: updatedMeetingSliceReducer,
    deleteMeeting: deleteMeetingSliceReducer,
    revenueViewEdit: revenueSheetViewPutSlicerReducer,
    eventStatus: eventStatusSlicer,
    viewEventStatus: viewEventStatus,
    eventStatusViewEdit: eventStatusPutSlice
  },
});

export default store;
